import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { colors } from "common/Theme";
import moment from "moment";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const OwnMessageCard = ({ item }) => {
  const { profile } = useSelector((store) => store.auth);
  const staticusers = useSelector((state) => state.usersdata.staticusers);

  const getAdminName = (id) => {
    if (staticusers) {
      const adminDetail = staticusers.find((item) => item.id === id);

      if (adminDetail) {
        return adminDetail?.firstName + " " + adminDetail?.lastName;
      }
    }
  };

  return (
    <Paper
      sx={{
        display: "flex",
        alignSelf: "flex-end",
        maxWidth: "60%",
        textAlign: "start",
        padding: "  0 1",
        flexDirection: "column",
        borderRadius: ".625rem",
        position: "relative",
        minWidth: "fit-content",
        "&::after": {
          content: '" "',
          border: "20px solid transparent",
          position: "absolute",
          top: 0,
          right: "-1.25rem",
          borderTopRightRadius: ".5rem",
          borderTopColor: item?.uid !== profile.uid ? "#1976d2" : "#fff",
        },
      }}
    >
      {item?.uid !== profile.uid && (
        <>
          <Box
            sx={{ background: "#1976d2", borderTopLeftRadius: ".625rem" }}
            height={25}
            position="absolute"
            width={"100%"}
          ></Box>
          <Typography
            color="white"
            variant="body1"
            zIndex={1}
            height={20}
            fontWeight={700}
            noWrap
            paddingLeft={1}
            paddingRight={2}
          >
            {getAdminName(item.uid)}
          </Typography>
        </>
      )}

      <Typography color="black" marginTop={1} marginX={2}>
        {item.text}
      </Typography>
      <Typography
        color={colors.grey1}
        sx={{
          fontSize: ".85rem",
          display: "flex",
          alignSelf: "flex-end",
          padding: "2",
          marginX: 2,
        }}
      >
        {moment(item.createdAt).format("h:mm A")}
      </Typography>
    </Paper>
  );
};

export default OwnMessageCard;
