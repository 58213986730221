export const FirebaseConfig = {
	apiKey: "AIzaSyArskyNjoQsJMmVaAQmy2Dmr0MhzUwuVtc",
	authDomain: "linkoping-013taxi.firebaseapp.com",
	databaseURL: "https://linkoping-013taxi-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "linkoping-013taxi",
	storageBucket: "linkoping-013taxi.firebasestorage.app",
	messagingSenderId: "317497146291",
	appId: "1:317497146291:web:21cfd8ae85cafeae62a9c6",
	measurementId: "G-M85XRN0NPZ"
};
