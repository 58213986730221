import {
  FETCH_TARIFFSRULE,
  FETCH_TARIFFSRULE_SUCCESS,
  FETCH_TARIFFSRULE_FAILED,
  EDIT_TARIFFSRULE,
  EDIT_TARIFFSRULE_SUCCESS,
  EDIT_TARIFFSRULE_FAILED,
  ADD_TARIFFSRULE,
  ADD_TARIFFSRULE_SUCCESS,
  ADD_TARIFFSRULE_FAILED,
  DELETE_TARIFFSRULE,
  DELETE_TARIFFSRULE_SUCCESS,
  DELETE_TARIFFSRULE_FAILED,
} from "../store/types";

const INITIAL_STATE = {
  tariffruleslist: [],
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const tariffrulereducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case FETCH_TARIFFSRULE:
          return {
            ...state,
            loading: true,
            error: { flag: false, msg: null },
          };
        case FETCH_TARIFFSRULE_SUCCESS:
          return {
            ...state,
            tariffruleslist: action.payload || [],
            loading: false,
            error: { flag: false, msg: null },
          };
        case FETCH_TARIFFSRULE_FAILED:
          return {
            ...state,
            loading: false,
            error: { flag: true, msg: action.payload },
          };
        case EDIT_TARIFFSRULE:
          return {
            ...state,
            loading: true,
          };
        case EDIT_TARIFFSRULE_SUCCESS:
          return {
            ...state,
            tariffruleslist: [...action.payload],
            loading: false,
          };
        case EDIT_TARIFFSRULE_FAILED:
          return {
            ...state,
            loading: false,
            error: { flag: true, msg: action.payload },
          };
        case ADD_TARIFFSRULE:
          return {
            ...state,
            loading: true,
          };
        case ADD_TARIFFSRULE_SUCCESS:
          return {
            ...state,
            tariffruleslist: [...state.tariffruleslist],
            loading: false,
          };
        case ADD_TARIFFSRULE_FAILED:
          return {
            ...state,
            loading: false,
            error: { flag: true, msg: action.payload },
          };
        case DELETE_TARIFFSRULE:
          return { ...state, loading: true };
    
        case DELETE_TARIFFSRULE_SUCCESS:
          return {
            ...state,
            tariffruleslist: action.payload,
            loading: false,
          };
    
        case DELETE_TARIFFSRULE_FAILED:
          return {
            ...state,
            error: action.payload,
            loading: false,
          };
        default:
          return state;
      }
}
