

export const FareCalculator = (distance,time,rateDetails,instructionData, decimal)=>{  
    let baseCalculated =  (parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance)) + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600));
    if(rateDetails.base_fare>0){
        baseCalculated = baseCalculated + rateDetails.base_fare;
    }
    if(instructionData && instructionData.parcelTypeSelected){
        baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if(instructionData && instructionData.optionSelected){
        baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }
    let total = baseCalculated > parseFloat(rateDetails.min_fare) ? baseCalculated : parseFloat(rateDetails.min_fare);
    let convenienceFee = 0;
    if(rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat'){
        convenienceFee = rateDetails.convenience_fees;
    }else{
        convenienceFee = (total*parseFloat(rateDetails.convenience_fees)/100);
    }
    let grand = total + convenienceFee;

    return {
        totalCost:parseFloat(total.toFixed(decimal)),
        grandTotal:parseFloat(grand.toFixed(decimal)),
        convenience_fees:parseFloat(convenienceFee.toFixed(decimal))
    }
     
}

export const taxiMetercalculateFare = (routeDetails, meterData) => {
   
    const distanceInMeters = routeDetails.distance_in_km * 1000;
 
    const firstDistanceFare =distanceInMeters <= parseFloat(meterData.forFirst) ? parseFloat(meterData.startPrice): 0;


    const remainingDistanceFare =distanceInMeters > parseFloat(meterData.forFirst) ? (distanceInMeters - parseFloat(meterData.forFirst)) * (parseFloat(meterData.distanceRate) / 1000) : 0;
  

    const timeFare =(routeDetails.time_in_secs / parseFloat(meterData.timePerS)) * parseFloat(meterData.timeRate);
  
   
    const totalFare = Math.max(parseFloat(meterData.minimumPrice), firstDistanceFare + remainingDistanceFare + timeFare);
  
    return {
      totalFare: parseFloat(totalFare).toFixed(2),
      distanceFare: parseFloat(firstDistanceFare + remainingDistanceFare).toFixed( 2),
      timeFare: parseFloat(timeFare).toFixed(2),
    };
  };


  

export const basePriceCalculate = (basePriceData) => {
    const { basepricevalue, routeDetails, basepricedata } = basePriceData;
  
    if (!basepricevalue || !routeDetails) {
      throw new Error("Base price or route details are missing");
    }
  
    const distance = routeDetails.distance;
    const zoneCalculations = basepricedata.map((zone) => {
      const { percentageSelect, zonePercentage, zoneName } = zone;
      const percentage = parseFloat(zonePercentage.replace('%', '')) / 100;
      const isIncrease = percentageSelect.includes("Increase");
  
      // Calculate adjusted base price for this zone
      let adjustedBasePrice = basepricevalue;
      if (isIncrease) {
        adjustedBasePrice += basepricevalue * percentage; // Add percentage increase
      } else {
        adjustedBasePrice -= basepricevalue * percentage; // Subtract percentage decrease
      }
  
      // Calculate total fare for this zone
      const distanceFare = adjustedBasePrice * distance;
      const totalFare = distanceFare; // Add other components if necessary
  
      return {
        zoneName,   
        totalFare,       
        distanceFare,     
        adjustedBasePrice, 
      };
    });
  
    return zoneCalculations;
  };
  
  
