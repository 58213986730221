import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import {MAIN_COLOR,SECONDORY_COLOR} from "../common/sharedFunctions"
import { makeStyles} from '@mui/styles';
import CompanyInfo from "./CompanyInfo";

    const useStyles = makeStyles({
  tabs: {

    "& .MuiTabs-indicator": {
      backgroundColor: SECONDORY_COLOR,
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: MAIN_COLOR
    }
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CompanyDetails() {
  
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Customer tabs"
          className={classes.tabs}
        >
          <Tab
            style={{ marginRight: "20px" }}
            label={t("info")}
            {...a11yProps(0)}
          />
          {/* <Tab
            style={{ marginRight: "20px" }}
            label={t("rides")}
            {...a11yProps(1)}
          />
          <Tab
            style={{ marginRight: "20px" }}
            label={t("wallet")}
            {...a11yProps(2)}
          /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CompanyInfo />
      </TabPanel>
    
    </Box>
  );
}
