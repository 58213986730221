import {
  FETCH_TAXIMETTER,
  FETCH_TAXIMETTER_SUCCESS,
  FETCH_TAXIMETTER_FAILED,
  EDIT_TAXIMETTER,
  EDIT_TAXIMETTER_SUCCESS,
  EDIT_TAXIMETTER_FAILED,
  ADD_TAXIMETTER,
  ADD_TAXIMETTER_SUCCESS,
  ADD_TAXIMETTER_FAILED,
  DELETE_TAXIMETTER,
  DELETE_TAXIMETTER_SUCCESS,
  DELETE_TAXIMETTER_FAILED,
} from "../store/types";
import { onValue, set, update, remove } from "firebase/database";
import { firebase } from "../config/configureFirebase";

export const fetchTaxiMetter = () => (dispatch) => {
  const { teaxiMetterRef } = firebase;
  dispatch({ type: FETCH_TAXIMETTER });
  try {
    onValue(teaxiMetterRef, (snapshot) => {
      const data = snapshot.val() || {};
      const dataList = Object.values(data);
      dispatch({ type: FETCH_TAXIMETTER_SUCCESS, payload: dataList });
    });
  } catch (error) {
    dispatch({ type: FETCH_TAXIMETTER_FAILED, payload: error.message });
  }
};

export const addTaxiMetter = (newData) => (dispatch, getState) => {
  const { teaxiMetterRef } = firebase;
  dispatch({ type: ADD_TAXIMETTER });
  set(teaxiMetterRef, [
    ...getState().taximetterdata.taximetterdatalist,
    newData,
  ])
    .then(() => {
      dispatch({
        type: ADD_TAXIMETTER_SUCCESS,
        payload: [newData],
      });
    })
    .catch((error) => {
      dispatch({ type: ADD_TAXIMETTER_FAILED, payload: error.message });
    });
};

export const updateTaxiMetter = (id, updatedData) => (dispatch, getState) => {
  const { teaxiMetterRef } = firebase;
  dispatch({ type: EDIT_TAXIMETTER });
  const currentList = getState().taximetterdata.taximetterdatalist;
  const dataIndex = currentList.findIndex((data) => data.id === id);
  if (dataIndex !== -1) {
    const updatedDataList = [
      ...currentList.slice(0, dataIndex),
      updatedData,
      ...currentList.slice(dataIndex + 1),
    ];
    set(teaxiMetterRef, updatedDataList)
      .then(() => {
        dispatch({
          type: EDIT_TAXIMETTER_SUCCESS,
          payload: updatedData,
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_TAXIMETTER_FAILED,
          payload: error.message,
        });
      });
  } else {
    dispatch({
      type: EDIT_TAXIMETTER_FAILED,
      payload: `${error.message}`,
    });
  }
};

export const deleteTaxiMetter = (id) => (dispatch, getState) => {
  const { teaxiMetterRef } = firebase;
  dispatch({ type: DELETE_TAXIMETTER });
  const currentData = getState();
  const currentDataList = currentData.taximetterdata.taximetterdatalist || [];
  const dataIndex = currentDataList.findIndex((data) => data.id === id);
  if (dataIndex !== -1) {
    const updatedData = [...currentDataList];
    updatedData.splice(dataIndex, 1);
    set(teaxiMetterRef, updatedData)
      .then(() => {
        dispatch({
          type: DELETE_TAXIMETTER_SUCCESS,
          payload: updatedData,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_TAXIMETTER_FAILED,
          payload: error.message,
        });
      });
  } else {
    dispatch({
      type: DELETE_TAXIMETTER_FAILED,
      payload: `deleting failed`,
    });
  }
};
