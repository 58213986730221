export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const USER_DELETED = "USER_DELETED";
export const REQUEST_OTP = "REQUEST_OTP";
export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS";
export const REQUEST_OTP_FAILED = "REQUEST_OTP_FAILED";
export const REQUEST_EMAIL_TOKEN = "REQUEST_EMAIL_TOKEN";
export const REQUEST_EMAIL_TOKEN_SUCCESS = "REQUEST_EMAIL_TOKEN_SUCCESS";
export const REQUEST_EMAIL_TOKEN_FAILED = "REQUEST_EMAIL_TOKEN_FAILED";
export const UPDATE_USER_WALLET_HISTORY = "UPDATE_USER_WALLET_HISTORY";

export const FETCH_CAR_TYPES = "FETCH_CAR_TYPES";
export const FETCH_CAR_TYPES_SUCCESS = "FETCH_CAR_TYPES_SUCCESS";
export const FETCH_CAR_TYPES_FAILED = "FETCH_CAR_TYPES_FAILED";
export const EDIT_CAR_TYPE = "EDIT_CAR_TYPE";

export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILED = "FETCH_BOOKINGS_FAILED";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const CANCEL_BOOKING = "CANCEL_BOOKING";

export const FETCH_ADDRESSES = "FETCH_ADDRESSES";
export const FETCH_ADDRESSES_SUCCESS = "FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_FAILED = "FETCH_ADDRESSES_FAILED";
export const EDIT_ADDRESS = "EDIT_ADDRESS";

export const FETCH_ESTIMATE = "FETCH_ESTIMATE";
export const FETCH_ESTIMATE_SUCCESS = "FETCH_ESTIMATE_SUCCESS";
export const FETCH_ESTIMATE_FAILED = "FETCH_ESTIMATE_FAILED";
export const CLEAR_ESTIMATE = "CLEAR_ESTIMATE";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_FAILED = "CONFIRM_BOOKING_FAILED";
export const CLEAR_BOOKING = "CLEAR_BOOKING";

export const FETCH_CANCEL_REASONS = "FETCH_CANCEL_REASONS";
export const FETCH_CANCEL_REASONS_SUCCESS = "FETCH_CANCEL_REASONS_SUCCESS";
export const FETCH_CANCEL_REASONS_FAILED = "FETCH_CANCEL_REASONS_FAILED";
export const EDIT_CANCELLATION_REASON = "EDIT_CANCELLATION_REASON";

export const FETCH_PROMOS = "FETCH_PROMOS";
export const FETCH_PROMOS_SUCCESS = "FETCH_PROMOS_SUCCESS";
export const FETCH_PROMOS_FAILED = "FETCH_PROMOS_FAILED";
export const EDIT_PROMOS = "EDIT_PROMOS";

export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";
export const FETCH_ALL_USERS_STATIC = "FETCH_ALL_USERS_STATIC";
export const FETCH_ALL_USERS_STATIC_SUCCESS = "FETCH_ALL_USERS_STATIC_SUCCESS";
export const FETCH_ALL_USERS_STATIC_FAILED = "FETCH_ALL_USERS_STATIC_FAILED";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const FETCH_ALL_DRIVERS = "FETCH_ALL_DRIVERS";
export const FETCH_ALL_DRIVERS_SUCCESS = "FETCH_ALL_DRIVERS_SUCCESS";
export const FETCH_ALL_DRIVERS_FAILED = "FETCH_ALL_DRIVERS_FAILED";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const EDIT_NOTIFICATIONS = "EDIT_NOTIFICATIONS";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const FETCH_DRIVERS_EARNING = "FETCH_DRIVERS_EARNING";
export const FETCH_DRIVERS__EARNING_SUCCESS = "FETCH_DRIVERS__EARNING_SUCCESS";
export const FETCH_DRIVERS__EARNING_FAILED = "FETCH_DRIVERS__EARNING_FAILED";

export const FETCH_BOOKING_DISCOUNT = "FETCH_BOOKING_DISCOUNT";
export const FETCH_BOOKING__DISCOUNT_SUCCESS =
  "FETCH_BOOKING__DISCOUNT_SUCCESS";
export const FETCH_BOOKING__DISCOUNT_FAILED = "FETCH_BOOKING__DISCOUNT_FAILED";
export const EDIT_BOOKING__DISCOUNT = "EDIT_BOOKING__DISCOUNT";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILED = "FETCH_SETTINGS_FAILED";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const CLEAR_SETTINGS_ERROR = "CLEAR_SETTINGS_ERROR";

export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILED = "FETCH_PAYMENT_METHODS_FAILED";
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const UPDATE_WALLET_BALANCE_SUCCESS = "UPDATE_WALLET_BALANCE_SUCCESS";
export const UPDATE_WALLET_BALANCE_FAILED = "UPDATE_WALLET_BALANCE_FAILED";
export const CLEAR_PAYMENT_MESSAGES = "CLEAR_PAYMENT_MESSAGES";

export const UPDATE_TRIP_PICKUP = "UPDATE_TRIP_PICKUP";
export const UPDATE_TRIP_DROP = "UPDATE_TRIP_DROP";
export const UPDATE_TRIP_CAR = "UPDATE_TRIP_CAR";
export const UPDATE_SELECTED_POINT_TYPE = "UPDATE_SELECTED_POINT_TYPE";
export const CLEAR_TRIP_POINTS = "CLEAR_TRIP_POINTS";

export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILED = "FETCH_TASKS_FAILED";
export const ACCEPT_TASK = "ACCEPT_TASK";
export const CANCEL_TASK = "CANCEL_TASK";

export const FETCH_BOOKING_LOCATION = "FETCH_BOOKING_LOCATION";
export const FETCH_BOOKING_LOCATION_SUCCESS = "FETCH_BOOKING_LOCATION_SUCCESS";
export const FETCH_BOOKING_LOCATION_FAILED = "FETCH_BOOKING_LOCATION_FAILED";
export const STOP_LOCATION_FETCH = "STOP_LOCATION_FETCH";

export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILED = "FETCH_MESSAGES_FAILED";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const STOP_FETCH_MESSAGES = "STOP_FETCH_MESSAGES";

export const FETCH_WITHDRAWS = "FETCH_WITHDRAWS";
export const FETCH_WITHDRAWS_SUCCESS = "FETCH_WITHDRAWS_SUCCESS";
export const FETCH_WITHDRAWS_FAILED = "FETCH_WITHDRAWS_FAILED";
export const EDIT_WITHDRAWS = "EDIT_WITHDRAWS";

export const UPDATE_GPS_LOCATION = "UPDATE_GPS_LOCATION";

export const FETCH_LANGUAGE = "FETCH_LANGUAGE";
export const FETCH_LANGUAGE_SUCCESS = "FETCH_LANGUAGE_SUCCESS";
export const FETCH_LANGUAGE_FAILED = "FETCH_LANGUAGE_FAILED";
export const EDIT_LANGUAGE = "EDIT_LANGUAGE";

export const FETCH_CARS = "FETCH_CARS";
export const FETCH_CARS_SUCCESS = "FETCH_CARS_SUCCESS";
export const FETCH_CARS_FAILED = "FETCH_CARS_FAILED";
export const EDIT_CAR = "EDIT_CAR";

export const SEND_RESET_EMAIL = "SEND_RESET_EMAIL";
export const SEND_RESET_EMAIL_SUCCESS = "SEND_RESET_EMAIL_SUCCESS";
export const SEND_RESET_EMAIL_FAILED = "SEND_RESET_EMAIL_FAILED";

export const FETCH_SMTP = "FETCH_SMTP";
export const FETCH_SMTP_SUCCESS = "FETCH_SMTP_SUCCESS";
export const FETCH_SMTP_FAILED = "FETCH_SMTP_FAILED";

export const FETCH_SMSCONFIG = "FETCH_SMSCONFIG";
export const FETCH_SMSCONFIG_SUCCESS = "FETCH_SMSCONFIG_SUCCESS";
export const FETCH_SMSCONFIG_FAILED = "FETCH_SMSCONFIG_FAILED";
export const EDIT_SMSCONFIG = "EDIT_SMSCONFIG";

export const FETCH_SOS = "FETCH_SOS";
export const FETCH_SOS_SUCCESS = "FETCH_SOS_SUCCESS";
export const FETCH_SOS_FAILED = "FETCH_SOS_FAILED";
export const EDIT_SOS = "EDIT_SOS";

export const FETCH_COMPLAIN = "FETCH_COMPLAIN";
export const FETCH_COMPLAIN_SUCCESS = "FETCH_COMPLAIN_SUCCESS";
export const FETCH_COMPLAIN_FAILED = "FETCH_COMPLAIN_FAILED";
export const EDIT_COMPLAIN = "EDIT_COMPLAIN";

export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILED = "FETCH_COMPANIES_FAILED";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const EDIT_COMPANY_SUCCESS = "EDIT_COMPANY_SUCCESS";
export const EDIT_COMPANY_FAILED = "EDIT_COMPANY_FAILED";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILED = "DELETE_COMPANY_FAILED";

export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILED = "FETCH_INVOICES_FAILED";
export const EDIT_INVOICE = "EDIT_INVOICE";
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS";
export const EDIT_INVOICE_FAILED = "EDIT_INVOICE_FAILED";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILED = "DELETE_INVOICE_FAILED";

export const FETCH_ZONE = "FETCH_ZONE";
export const FETCH_ZONE_SUCCESS = "FETCH_ZONE_SUCCESS";
export const FETCH_ZONE_FAILED = "FETCH_ZONE_FAILED";
export const EDIT_ZONE = "EDIT_ZONE";
export const EDIT_ZONE_SUCCESS = "EDIT_ZONE_SUCCESS";
export const EDIT_ZONE_FAILED = "EDIT_ZONE_FAILED";
export const DELETE_ZONE = "DELETE_ZONE";
export const DELETE_ZONE_SUCCESS = "DELETE_ZONE_SUCCESS";
export const DELETE_ZONE_FAILED = "DELETE_ZONE_FAILED";

export const FETCH_TARIFF_ZONE = "TARIFF_ZONE";
export const FETCH_TARIFF_ZONE_SUCCESS = "FETCH_TARIFF_ZONE_SUCCESS";
export const FETCH_TARIFF_ZONE_FAILED = "FETCH_TARIFF_ZONE_FAILED";
export const EDIT_TARIFF_ZONE = "EDIT_TARIFF_ZONE";
export const EDIT_TARIFF_ZONE_SUCCESS = "EDIT_TARIFF_ZONE_SUCCESS";
export const EDIT_TARIFF_ZONE_FAILED = "EDIT_TARIFF_ZONE_FAILED";
export const DELETE_TARIFF_ZONE = "DELETE_TARIFF_ZONE";
export const DELETE_TARIFF_ZONE_SUCCESS = "DELETE_TARIFF_ZONE_SUCCESS";
export const DELETE_TARIFF_ZONE_FAILED = "DELETE_TARIFF_ZONE_FAILED";

export const FETCH_BASE_PRICE = "FETCH_BASE_PRICE";
export const FETCH_BASE_PRICE_SUCCESS = "FETCH_BASE_PRICE_SUCCESS";
export const FETCH_BASE_PRICE_FAILED = "FETCH_BASE_PRICE_FAILED";
export const EDIT_BASE_PRICE = "EDIT_BASE_PRICE";
export const EDIT_BASE_PRICE_SUCCESS = "EDIT_BASE_PRICE_SUCCESS";
export const EDIT_BASE_PRICE_FAILED = "EDIT_BASE_PRICE_FAILED";
export const ADD_BASE_PRICE = "ADD_BASE_PRICE";
export const ADD_BASE_PRICE_SUCCESS = "ADD_BASE_PRICE_SUCCESS";
export const ADD_BASE_PRICE_FAILED = "ADD_BASE_PRICE_FAILED";
export const ADD_BASE_PRICE_VALUE = " ADD_BASE_PRICE_VALUE";
export const ADD_BASE_PRICE_VALUE_SUCCESS = " ADD_BASE_PRICE_VALUE_SUCCESS";
export const ADD_BASE_PRICE_VALUE_FAILED = " ADD_BASE_PRICE_VALUE_FAILED";

export const FETCH_SCHEDULE = "FETCH_SCHEDULE";
export const FETCH_SCHEDULE_SUCCESS = "FETCH_SCHEDULE_SUCCESS";
export const FETCH_SCHEDULE_FAILED = "FETCH_SCHEDULE_FAILED";
export const EDIT_SCHEDULE = "EDIT_SCHEDULE";
export const EDIT_SCHEDULE_SUCCESS = "EDIT_SCHEDULE_SUCCESS";
export const EDIT_SCHEDULE_FAILED = "EDIT_SCHEDULE_FAILED";
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_FAILED = "ADD_SCHEDULE_FAILED";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_FAILED = "DELETE_SCHEDULE_SUCCESS";

export const FETCH_TAXIMETTER = "FETCH_TAXIMETTER";
export const FETCH_TAXIMETTER_SUCCESS = "FETCH_TAXIMETTER_SUCCESS";
export const FETCH_TAXIMETTER_FAILED = "FETCH_TAXIMETTER_FAILED";
export const EDIT_TAXIMETTER = "EDIT_TAXIMETTER";
export const EDIT_TAXIMETTER_SUCCESS = "EDIT_TAXIMETTER_SUCCESS";
export const EDIT_TAXIMETTER_FAILED = "EDIT_TAXIMETTER_FAILED";
export const ADD_TAXIMETTER = "ADD_TAXIMETTER";
export const ADD_TAXIMETTER_SUCCESS = "ADD_TAXIMETTER_SUCCESS";
export const ADD_TAXIMETTER_FAILED = "ADD_TAXIMETTER_FAILED";
export const DELETE_TAXIMETTER = "DELETE_TAXIMETTER";
export const DELETE_TAXIMETTER_SUCCESS = "DELETE_TAXIMETTER_SUCCESS";
export const DELETE_TAXIMETTER_FAILED = "DELETE_TAXIMETTER_FAILED";

export const FETCH_WAITRATE = "FETCH_WAITRATE";
export const FETCH_WAITRATE_SUCCESS = "FETCH_WAITRATE_SUCCESS";
export const FETCH_WAITRATE_FAILED = "FETCH_WAITRATE_FAILED";
export const EDIT_WAITRATE = "EDIT_WAITRATE";
export const EDIT_WAITRATE_SUCCESS = "EDIT_WAITRATE_SUCCESS";
export const EDIT_WAITRATE_FAILED = "EDIT_WAITRATE_FAILED";
export const ADD_WAITRATE = "ADD_WAITRATE";
export const ADD_WAITRATE_SUCCESS = "ADD_WAITRATE_SUCCESS";
export const ADD_WAITRATE_FAILED = "ADD_WAITRATE_FAILED";
export const DELETE_WAITRATE = "DELETE_WAITRATE";
export const DELETE_WAITRATE_SUCCESS = "DELETE_WAITRATE_SUCCESS";
export const DELETE_WAITRATE_FAILED = "DELETE_WAITRATE_FAILED";

export const FETCH_FLATTARIFFS = "FETCH_FLATTARIFFS";
export const FETCH_FLATTARIFFS_SUCCESS = "FETCH_FLATTARIFFS_SUCCESS";
export const FETCH_FLATTARIFFS_FAILED = "FETCH_FLATTARIFFS_FAILED";
export const EDIT_FLATTARIFFS = "EDIT_FLATTARIFFS";
export const EDIT_FLATTARIFFS_SUCCESS = "EDIT_FLATTARIFFS_SUCCESS";
export const EDIT_FLATTARIFFS_FAILED = "EDIT_FLATTARIFFS_FAILED";
export const ADD_FLATTARIFFS = "ADD_TAFLATTARIFFS";
export const ADD_FLATTARIFFS_SUCCESS = "ADD_FLATTARIFFS_SUCCESS";
export const ADD_FLATTARIFFS_FAILED = "ADD_FLATTARIFFS_FAILED";
export const DELETE_FLATTARIFFS = "DELETE_TAFLATTARIFFS";
export const DELETE_FLATTARIFFS_SUCCESS = "DELETE_FLATTARIFFS_SUCCESS";
export const DELETE_FLATTARIFFS_FAILED = "DELETE_FLATTARIFFS_FAILED";

export const FETCH_TARIFFSRULE = "FETCH_TARIFFSRULE";
export const FETCH_TARIFFSRULE_SUCCESS = "FETCH_TARIFFSRULE_SUCCESS";
export const FETCH_TARIFFSRULE_FAILED = "FETCH_TARIFFSRULE_FAILED";
export const EDIT_TARIFFSRULE = "EDIT_TARIFFSRULE";
export const EDIT_TARIFFSRULE_SUCCESS = "EDIT_TARIFFSRULE_SUCCESS";
export const EDIT_TARIFFSRULE_FAILED = "EDIT_TARIFFSRULE_FAILED";
export const ADD_TARIFFSRULE = "ADD_TATARIFFSRULE";
export const ADD_TARIFFSRULE_SUCCESS = "ADD_TARIFFSRULE_SUCCESS";
export const ADD_TARIFFSRULE_FAILED = "ADD_TARIFFSRULE_FAILED";
export const DELETE_TARIFFSRULE = "DELETE_TATARIFFSRULE";
export const DELETE_TARIFFSRULE_SUCCESS = "DELETE_TARIFFSRULE_SUCCESS";
export const DELETE_TARIFFSRULE_FAILED = "DELETE_TARIFFSRULE_FAILED";

export const FETCH_TAXIMETERESTIMATE = "FETCH_TAXIMETERESTIMATE";
export const FETCH_TAXIMETERESTIMATE_SUCCESS = "FETCH_TAXIMETERESTIMATE_SUCCESS";
export const FETCH_TAXIMETERESTIMATE_FAILED = "FETCH_TAXIMETERESTIMATE_FAILED";
export const CLEAR_TAXIMETERESTIMATE = "CLEAR_TAXIMETERESTIMATE";

export const FETCH_ESTIMATEBASEPRICE = "FETCH_ESTIMATEBASEPRICE";
export const FETCH_ESTIMATEBASEPRICE_SUCCESS = "FETCH_ESTIMATEBASEPRICE_SUCCESS";
export const FETCH_ESTIMATEBASEPRICE_FAILED = "FETCH_ESTIMATEBASEPRICE_FAILED";
export const CLEAR_ESTIMATEBASEPRICE = "CLEAR_ESTIMATEBASEPRICE";