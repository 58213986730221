
import React, { useState } from "react";
import { api } from "common";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Button,
  Tab,
  Tabs,
  Paper,
  Modal,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { MAIN_COLOR,} from "../common/sharedFunctions";
import BelowDetailedData from "./TariffDetailedBelowTabs";
import TariffRules from "./tariffrules/TariffRules";

// const useStyles = makeStyles((theme) => ({
//   textField: {
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//   },
//   selectField: {
//     color: "black",
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: MAIN_COLOR,
//     },
//   },
//   rootRtl: {
//     "& label": {
//       right: 25,
//       left: "auto",
//       paddingRight: 12,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 15,
//     },
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//   },
//   rootRtl_1: {
//     "& label": {
//       right: 25,
//       left: "auto",
//       paddingRight: 12,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 18,
//     },
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//   },
//   selectField_rtl: {
//     color: "black",
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: MAIN_COLOR,
//     },
//     "& label": {
//       right: 50,
//       left: "auto",
//       paddingRight: 12,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 25,
//     },
//   },
//   selectField_rtl_1: {
//     color: "black",
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: MAIN_COLOR,
//     },
//     "& label": {
//       right: 50,
//       left: "auto",
//       paddingRight: 12,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 27,
//     },
//   },

//   right: {
//     textAlign: "right",
//     right: 0,
//     left: "auto",
//     paddingRight: 40,
//   },
// }));

function TabContent({ data }) {
  return <div>{data}</div>;
}

TabContent.propTypes = {
  data: PropTypes.string.isRequired,
};

// Component for each Tab Panel
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TariffDetailed = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const tabData = ["Standard", "Content for Item Two"];

  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField label="Name" id="name" fullWidth required />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="parent_type_select">Currency</InputLabel>
            <Select
              labelId="parent_type_select"
              id="parent_type_select"
              label="test"
            ></Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={6}>
          <TextField label="Fare incremenent" id="fareInc" fullWidth required />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="parent_type_select">Unit</InputLabel>
            <Select
              labelId="parent_type_select"
              id="parent_type_select"
              label="Unit"
            >
              <MenuItem value="metric">Metric</MenuItem>
              <MenuItem value="imperial">Imperial</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid> */}
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Grid item>
          <Button variant="outlined" onClick={handleOpen}>Rules</Button>
        </Grid>
        {/* <Grid item>
          <Button variant="outlined">Group</Button>
        </Grid>
        <Grid item>
          <Button variant="outlined">Extras</Button>
        </Grid> */}
      </Grid>

      <Box sx={boxStyled}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Standard" {...a11yProps(0)} />
            <Tab label="Add New" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <CustomTabPanel>
            <BelowDetailedData />
          </CustomTabPanel>
        </Box>
      </Box>

      <Modal
        sx={modelWrapper}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={3} sx={modelCard}>
          <IconButton onClick={handleClose} sx={crossIcon}>
            <CloseIcon />
          </IconButton>
          <Box>
            <TariffRules
              handleClose={handleClose}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default TariffDetailed;

const boxStyled = {
  width: "100%",
  minHeight: "100%",
  display: "flex",
  flexDirection: "column",
};

const btnStyled = {
  borderRadius: "100px",
  backgroundColor: MAIN_COLOR,
  minHeight: 30,
  marginBottom: "10px",
  textAlign: "center",
  width: {
    xs: "30%",
    sm: "28%",
    md: "20%",
    lg: "13%",
  },
};
const modelWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const modelCard = {
  px: 4,
  py: 3,
  maxWidth: "750px",
  maxHeight: "550px",
  height: "100%",
  width: "100%",
  borderRadius: "20px",
  position: "relative",
  overflow: "auto",
};
const crossIcon = {
  position: "absolute",
  top: 8,
  right: 8,
  color: "black",
  zIndex: 1300,
};
