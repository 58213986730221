import {
    FETCH_TARIFFSRULE,
    FETCH_TARIFFSRULE_SUCCESS,
    FETCH_TARIFFSRULE_FAILED,
    EDIT_TARIFFSRULE,
    EDIT_TARIFFSRULE_SUCCESS,
    EDIT_TARIFFSRULE_FAILED,
    ADD_TARIFFSRULE,
    ADD_TARIFFSRULE_SUCCESS,
    ADD_TARIFFSRULE_FAILED,
    DELETE_TARIFFSRULE,
    DELETE_TARIFFSRULE_SUCCESS,
    DELETE_TARIFFSRULE_FAILED,
  } from "../store/types";
  import { onValue, set } from "firebase/database";
  import { firebase } from "../config/configureFirebase";
  
  export const fetchTariffRules = () => (dispatch) => {
    const { tarifRulesRef } = firebase;
    dispatch({ type: FETCH_TARIFFSRULE });
    try {
      onValue(tarifRulesRef, (snapshot) => {
        const data = snapshot.val() || {};
        const dataList = Object.values(data);
        dispatch({ type: FETCH_TARIFFSRULE_SUCCESS, payload: dataList });
      });
    } catch (error) {
      dispatch({ type: FETCH_TARIFFSRULE_FAILED, payload: error.message });
    }
  };
  
  export const addTariffRules = (newData) => (dispatch, getState) => {
    const { tarifRulesRef } = firebase;
    dispatch({ type: ADD_TARIFFSRULE });
    set(tarifRulesRef, [...getState().tariffruledata.tariffruleslist, newData])
      .then(() => {
        dispatch({
          type: ADD_TARIFFSRULE_SUCCESS,
          payload: [newData],
        });
      })
      .catch((error) => {
        dispatch({ type: ADD_TARIFFSRULE_FAILED, payload: error.message });
      });
  };
  
  export const updateTariffRules = (id, updatedData) => (dispatch, getState) => {
    const { tarifRulesRef } = firebase;
    dispatch({ type: EDIT_TARIFFSRULE });
    const currentList = getState().tariffruledata.tariffruleslist;
    const dataIndex = currentList.findIndex((data) => data.id === id);
    if (dataIndex !== -1) {
      const updatedDataList = [
        ...currentList.slice(0, dataIndex),
        updatedData,
        ...currentList.slice(dataIndex + 1),
      ];
      set(tarifRulesRef, updatedDataList)
        .then(() => {
          dispatch({
            type: EDIT_TARIFFSRULE_SUCCESS,
            payload: updatedData,
          });
        })
        .catch((error) => {
          dispatch({
            type: EDIT_TARIFFSRULE_FAILED,
            payload: error.message,
          });
        });
    } else {
      dispatch({
        type: EDIT_TARIFFSRULE_FAILED,
        payload: `Update failed, item not found`,
      });
    }
  };
  
  export const deleteTariffRules = (id) => (dispatch, getState) => {
    const { tarifRulesRef } = firebase;
    dispatch({ type: DELETE_TARIFFSRULE });
    const currentData = getState();
    const currentDataList = currentData.tariffruledata.tariffruleslist || [];
    const dataIndex = currentDataList.findIndex((data) => data.id === id);
    if (dataIndex !== -1) {
      const updatedData = [...currentDataList];
      updatedData.splice(dataIndex, 1);
      set(tarifRulesRef, updatedData)
        .then(() => {
          dispatch({
            type: DELETE_TARIFFSRULE_SUCCESS,
            payload: updatedData,
          });
        })
        .catch((error) => {
          dispatch({
            type: DELETE_TARIFFSRULE_FAILED,
            payload: error.message,
          });
        });
    } else {
      dispatch({
        type: DELETE_TARIFFSRULE_FAILED,
        payload: `deleting failed`,
      });
    }
  };
  