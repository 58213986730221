import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { MAIN_COLOR } from "common/sharedFunctions";
import {
  addTariffRules,
  updateTariffRules,
} from "common/src/actions/tariffruleactions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const TariffRulesAdd = ({ editData, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const zoneData = useSelector((state) => state.tariffzone);
  const tariffrule = useSelector(
    (state) => state.tariffruledata.tariffruleslist
  );
  const state = useSelector((state) => state);
  console.log("state:__________ ", state);
  const initialState = {
    id: tariffrule.length + 1,
    bookingSource: "",
    passengerMin: "",
    passengerMax: "",
    zoneFrom: "",
    zoneTo: "",
  };
  const [formValues, setFormValues] = useState(initialState);
  useEffect(() => {
    if (isEdit && editData) {
      setFormValues({
        id: editData.id || tariffrule.length + 1,
        bookingSource: editData.bookingSource || "",
        passengerMin: editData.passengerMin || "",
        passengerMax: editData.passengerMax || "",
        zoneFrom: editData.zoneFrom || "",
        zoneTo: editData.zoneTo || "",
      });
    } else {
      setFormValues(initialState);
    }
  }, [isEdit, editData]);
  const availableZones = zoneData?.tariffzones?.features?.filter(
    ({ properties: { name } }) =>
      name &&
      !tariffrule.some((item) => item.name && item.name.trim() === name.trim())
  );
  const editDataItem = { id: editData?.id, name: editData?.name || "" };
  const fromDropdownData = isEdit
    ? [...availableZones, editDataItem]
    : availableZones;

  const toDropdownData = isEdit
    ? [...availableZones, editDataItem]
    : availableZones?.filter(
        ({ properties: { name } }) =>
          name !== editData?.from && name !== formValues.zoneFrom
      );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { bookingSource, passengerMin, passengerMax, zoneFrom, zoneTo } =
      formValues;

    if (
      !bookingSource ||
      !passengerMin ||
      !passengerMax ||
      !zoneFrom ||
      !zoneTo
    ) {
      toast.error(t("please_fill_all_fields"));
      return;
    }

    if (isEdit && editData?.id) {
      const getId = tariffrule.findIndex((data) => data.id === editData.id);
      if (getId !== -1) {
        dispatch(updateTariffRules(editData.id, formValues));
        toast.success(t("updated_successfully"));
        setIsEdit(false);
        setFormValues(initialState);
      } else {
        toast.error(t("update_failed"));
      }
    } else {
      if (tariffrule.length >= 6) {
        toast.error(t("You can only add up to 6 tariff rules."));
        return;
      }
      dispatch(addTariffRules(formValues));
      toast.success(t("add_successfully"));
      setFormValues(initialState);
    }
  };

  return (
    <>
      <Typography style={{ color: MAIN_COLOR, fontSize: 25 }}>
        {t("Add_Tariff_Rules")}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: "100%", marginTop: "15px" }}
        justifyContent="start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>{t("Booking_Source")}</InputLabel>
            <Select
              label={t("Booking_Source")}
              name="bookingSource"
              value={formValues.bookingSource}
              onChange={handleChange}
            >
              <MenuItem value="Mobile">{t("Mobile")}</MenuItem>
              <MenuItem value="IFrame">{t("IFrame")}</MenuItem>
              <MenuItem value="Web">{t("Web")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <InputLabel>{t("Passenger_mim")}</InputLabel>
            <Select
              label={t("Passenger_mim")}
              name="passengerMin"
              value={formValues.passengerMin}
              onChange={handleChange}
            >
              {Array.from({ length: 10 }, (_, i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <InputLabel>{t("Passenger_max")}</InputLabel>
            <Select
              label={t("Passenger_max")}
              name="passengerMax"
              value={formValues.passengerMax}
              onChange={handleChange}
            >
              {Array.from({ length: 10 }, (_, i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <InputLabel>{t("Zone_From")}</InputLabel>
            <Select
              label={t("Zone_From")}
              name="zoneFrom"
              value={formValues.zoneFrom}
              onChange={handleChange}
            >
              {fromDropdownData?.map((data, index) => (
                <MenuItem key={index} value={data?.properties?.name}>
                  {data?.properties?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <InputLabel>{t("Zone_To")}</InputLabel>
            <Select
              label={t("Zone_To")}
              name="zoneTo"
              value={formValues.zoneTo}
              onChange={handleChange}
            >
              {toDropdownData?.map((data, i) => (
                <MenuItem key={i} value={data?.properties?.name}>
                  {data?.properties?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Button
          sx={btnStyled}
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={isEdit ? false : tariffrule.length >= 6}
        >
          <Typography
            style={{ color: "white", textAlign: "center", fontSize: 16 }}
          >
            {isEdit ? t("update_button") : t("save")}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default TariffRulesAdd;

const btnStyled = {
  borderRadius: "100px",
  backgroundColor: MAIN_COLOR,
  minHeight: 25,
  marginBottom: "10px",
  textAlign: "center",
  width: {
    xs: "30%",
    md: "25%",
    lg: "22%",
  },
};
