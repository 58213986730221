import { 
    FETCH_COMPANIES,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILED,
    EDIT_COMPANY
  } from "../store/types";
  
  export const INITIAL_STATE = {
    companies:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const companiesreducer =  (state = INITIAL_STATE, action) => {

    switch (action.type) {
      case  FETCH_COMPANIES:
        return {
          ...state,
        };
      case  FETCH_COMPANIES_SUCCESS:
        return {
          ...state,
          companies:action.payload,
        };
      case FETCH_COMPANIES_FAILED:
        return {
          ...state,
          companies:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_COMPANY:
        return state;
      default:
        return state;
    }
  };