
import {
  FETCH_BASE_PRICE,
  FETCH_BASE_PRICE_SUCCESS,
  FETCH_BASE_PRICE_FAILED,
  EDIT_BASE_PRICE,
  EDIT_BASE_PRICE_SUCCESS,
  EDIT_BASE_PRICE_FAILED,
  ADD_BASE_PRICE,
  ADD_BASE_PRICE_SUCCESS,
  ADD_BASE_PRICE_FAILED,
  ADD_BASE_PRICE_VALUE,
  ADD_BASE_PRICE_VALUE_SUCCESS,
  ADD_BASE_PRICE_VALUE_FAILED,
} from "../store/types";

const INITIAL_STATE = {
  basepricedata: {},
  basepricevalue: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const basepricereducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_BASE_PRICE:
      return {
        ...state,
        loading: true,
        error: { flag: false, msg: null },
      };
    case FETCH_BASE_PRICE_SUCCESS:
      return {
        ...state,
        basepricedata: action.payload || {}, // Ensures basepricedata is set as an object
        loading: false,
        error: { flag: false, msg: null },
      };
    case FETCH_BASE_PRICE_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case EDIT_BASE_PRICE:
      return {
        ...state,
        loading: true,
      };
    case EDIT_BASE_PRICE_SUCCESS:
      return {
        ...state,
        basepricedata: { ...state.basepricedata, ...action.payload },
        loading: false,
      };
    case EDIT_BASE_PRICE_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case ADD_BASE_PRICE:
      return {
        ...state,
        loading: true,
      };
    case ADD_BASE_PRICE_SUCCESS:
      return {
        ...state,
        basepricedata: { ...state.basepricedata, ...action.payload },
        loading: false,
      };
    case ADD_BASE_PRICE_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case ADD_BASE_PRICE_VALUE:
      return {
        ...state,
        loading: true,
      };
    case ADD_BASE_PRICE_VALUE_SUCCESS:
      return {
        ...state,
        basepricevalue: action.payload.basepricevalue || state.basepricevalue,
        loading: false,
      };
    case ADD_BASE_PRICE_VALUE_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    default:
      return state;
  }
};
