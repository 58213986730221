import { 
    FETCH_TARIFF_ZONE,
    FETCH_TARIFF_ZONE_SUCCESS,
    FETCH_TARIFF_ZONE_FAILED,
    EDIT_TARIFF_ZONE
  } from "../store/types";
  
  export const INITIAL_STATE = {
    tariffzones:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const tariffreducer =  (state = INITIAL_STATE, action) => {

    switch (action.type) {
      case  FETCH_TARIFF_ZONE:
        return {
          ...state,
        };
      case  FETCH_TARIFF_ZONE_SUCCESS:
        return {
          ...state,
          tariffzones:action.payload,
        };
      case FETCH_TARIFF_ZONE_FAILED:
        return {
          ...state,
          tariffzones:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_TARIFF_ZONE:
        return state;
      default:
        return state;
    }
  };