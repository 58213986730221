import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MAIN_COLOR } from "common/sharedFunctions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TariffRulesAdd from "./TariffRulesAdd";
import { useDispatch, useSelector } from "react-redux";
import { deleteTariffRules } from "common/src/actions/tariffruleactions";
import { toast } from "react-toastify";

const TariffRules = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editData, setEditData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const tariffData = useSelector(
    (state) => state.tariffruledata.tariffruleslist
  );
  const limitedData = tariffData.slice(0, 6);
  const handleDelete = (id) => {
    dispatch(deleteTariffRules(id));
    toast.success(t("Deleted"));
  };

  const handleEdit = (id) => {
    console.log("edit Id", id);
    setIsEdit(true);
    const selectedData = tariffData.find((row) => row.id === id);
    if (selectedData) {
      setEditData(selectedData);
    }
  };
  return (
    <>
      <Box sx={{ margin: "25px 0" }}>
        <TariffRulesAdd
          editData={editData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      </Box>
      <Box>
        <Typography style={{ color: MAIN_COLOR, fontSize: 25 }}>
          {t("Tariff_Rules")}
        </Typography>

        <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={tabelStyled}>{t("SR_No")}</TableCell>
                <TableCell sx={tabelStyled}>{t("Booking_Source")}</TableCell>
                <TableCell sx={tabelStyled}>{t("Passenger_mim")}</TableCell>
                <TableCell sx={tabelStyled}>{t("Passenger_max")}</TableCell>
                <TableCell sx={tabelStyled}>{t("Zone_From")}</TableCell>
                <TableCell sx={tabelStyled}>{t("Zone_To")}</TableCell>
                <TableCell sx={tabelStyled} align="right">
                  {t("actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {limitedData && limitedData.length > 0 ? (
                limitedData.map((data, index) => (
                  <TableRow key={index} style={{ whiteSpace: "nowrap" }}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{data.bookingSource}</TableCell>
                    <TableCell>{data.passengerMin}</TableCell>
                    <TableCell>{data.passengerMax}</TableCell>
                    <TableCell>{data.zoneFrom}</TableCell>
                    <TableCell>{data.zoneTo}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleEdit(data.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(data.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Box style={{ whiteSpace: "nowrap" }}>
                  {t("No_data_available")}
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default TariffRules;

const tabelStyled = {
  background: "#DDDEEE",
  fontWeight: "bold",
  whiteSpace: "nowrap",
};
