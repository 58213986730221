import Box from "@mui/material/Box";
import React, { useRef, useEffect } from "react";
import OwnMessageCard from "../foundation/ChatCard/OwnMessageCard";
import ReplyMessageCard from "../foundation/ChatCard/ReplyCard";
import { useSelector } from "react-redux";

const ChatContainer = ({ messages }) => {
  const { profile } = useSelector((store) => store.auth);
  
  // Create a ref to the container that holds the messages
  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the messages whenever a new message is added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);  // Dependency on `messages` so it scrolls when the messages array changes

  return (
    <Box
      sx={{
        height: "98%",
        position: "absolute",
        top: 0,
        display: "flex",
        flexDirection: "column",
        padding: "1% 6%",
        gap: ".5rem",
        overflow: "auto",
        width: "100%",
      }}
    >
      {messages.map((item, index) => {
        return item.sentBy === "admin" ? (
          <OwnMessageCard key={item.createdAt + index} item={item} />
        ) : (
          <ReplyMessageCard key={item.createdAt + index} item={item} />
        );
      })}

      {/* Add a div at the end of the message list to scroll into */}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default ChatContainer;
