import {
  FETCH_FLATTARIFFS,
  FETCH_FLATTARIFFS_SUCCESS,
  FETCH_FLATTARIFFS_FAILED,
  EDIT_FLATTARIFFS,
  EDIT_FLATTARIFFS_SUCCESS,
  EDIT_FLATTARIFFS_FAILED,
  ADD_FLATTARIFFS,
  ADD_FLATTARIFFS_SUCCESS,
  ADD_FLATTARIFFS_FAILED,
  DELETE_FLATTARIFFS,
  DELETE_FLATTARIFFS_SUCCESS,
  DELETE_FLATTARIFFS_FAILED,
} from "../store/types";
import { onValue, set } from "firebase/database";
import { firebase } from "../config/configureFirebase";

export const fetchFlatTariffs = () => (dispatch) => {
  const { flatTariffsRef } = firebase;
  dispatch({ type: FETCH_FLATTARIFFS });
  try {
    onValue(flatTariffsRef, (snapshot) => {
      const data = snapshot.val() || {};
      const dataList = Object.values(data);
      dispatch({ type: FETCH_FLATTARIFFS_SUCCESS, payload: dataList });
    });
  } catch (error) {
    dispatch({ type: FETCH_FLATTARIFFS_FAILED, payload: error.message });
  }
};

export const addFlatTariffs = (newData) => (dispatch, getState) => {
  const { flatTariffsRef } = firebase;
  dispatch({ type: ADD_FLATTARIFFS });
  set(flatTariffsRef, [...getState().flattariffsdata.flattariffslist, newData])
    .then(() => {
      dispatch({
        type: ADD_FLATTARIFFS_SUCCESS,
        payload: [newData],
      });
    })
    .catch((error) => {
      dispatch({ type: ADD_FLATTARIFFS_FAILED, payload: error.message });
    });
};

export const updateFlatTariffs = (id, updatedData) => (dispatch, getState) => {
  const { flatTariffsRef } = firebase;
  dispatch({ type: EDIT_FLATTARIFFS });
  const currentList = getState().flattariffsdata.flattariffslist;
  const dataIndex = currentList.findIndex((data) => data.id === id);
  if (dataIndex !== -1) {
    const updatedDataList = [
      ...currentList.slice(0, dataIndex),
      updatedData,
      ...currentList.slice(dataIndex + 1),
    ];
    set(flatTariffsRef, updatedDataList)
      .then(() => {
        dispatch({
          type: EDIT_FLATTARIFFS_SUCCESS,
          payload: updatedData,
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_FLATTARIFFS_FAILED,
          payload: error.message,
        });
      });
  } else {
    dispatch({
      type: EDIT_FLATTARIFFS_FAILED,
      payload: `Update failed, item not found`,
    });
  }
};

export const deleteFlatTariffs = (id) => (dispatch, getState) => {
  const { flatTariffsRef } = firebase;
  dispatch({ type: DELETE_FLATTARIFFS });
  const currentData = getState();
  const currentDataList = currentData.flattariffsdata.flattariffslist || [];
  const dataIndex = currentDataList.findIndex((data) => data.id === id);
  if (dataIndex !== -1) {
    const updatedData = [...currentDataList];
    updatedData.splice(dataIndex, 1);
    set(flatTariffsRef, updatedData)
      .then(() => {
        dispatch({
          type: DELETE_FLATTARIFFS_SUCCESS,
          payload: updatedData,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_FLATTARIFFS_FAILED,
          payload: error.message,
        });
      });
  } else {
    dispatch({
      type: DELETE_FLATTARIFFS_FAILED,
      payload: `deleting failed`,
    });
  }
};
