import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { api } from "common";
import { MAIN_COLOR } from "common/sharedFunctions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const WaitRateAdd = ({ handleClose, editData, isEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addWaiteRate, updateWaitRate } = api;
  const rateData = useSelector((state) => state.waitratedata.waitratelist);
  const scheduleData = useSelector((state) => state.scheduledata);
  const initialState = {
    id: rateData.length + 1,
    name: "",
    free: "",
    forFirst: "",
    then: "",
    perS: "",
  };
  const [waitRateData, setWaitRateData] = useState(initialState);
  useEffect(() => {
    if (isEdit) {
      setWaitRateData({
        id: editData.id || rateData.length + 1,
        name: editData.name || "",
        free: editData.free || "",
        forFirst: editData.forFirst || "",
        then: editData.then || "",
        perS: editData.perS || "",
      });
    } else {
      setWaitRateData(initialState);
    }
  }, [rateData, isEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWaitRateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const availableSchedules = scheduleData?.scheduledatalist?.filter(
    (scheduleItem) => !rateData.some((Item) => Item.name === scheduleItem.name)
  );
  const dropdownSchedules = isEdit
    ? [...availableSchedules, { id: editData?.id, name: editData?.name }]
    : availableSchedules;
  const onSubmit = () => {
    if (!availableSchedules || availableSchedules.length === 0) {
      toast.error(t("please_create_first_schedule"));
      return;
    }
    if (!waitRateData.name) {
      toast.error(t("please_select_a_name"));
      return;
    }

    const existingIndex = rateData.findIndex(
      (data) => data.id === waitRateData.id
    );
    if (existingIndex !== -1 && isEdit) {
      dispatch(updateWaitRate(waitRateData.id, waitRateData));
      toast.success(t("updated_successfully"));
    } else {
      dispatch(addWaiteRate(waitRateData));
      toast.success(t("add_successfully"));
    }
    setWaitRateData(initialState);
    handleClose();
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: "100%", marginTop: "15px" }}
        justifyContent="start"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="zone_select">{t("name")}</InputLabel>
            <Select
              labelid={t("zone_select")}
              name="name"
              value={waitRateData.name}
              onChange={handleChange}
            >
              {dropdownSchedules?.map((data) => (
                <MenuItem key={data.id} value={data.name}>
                  {data.name.trim()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("free")}
            name="free"
            value={waitRateData.free}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("forFirst")}
            name="forFirst"
            value={waitRateData.forFirst}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("then")}
            name="then"
            value={waitRateData.then}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("per_s")}
            name="perS"
            value={waitRateData.perS}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Button
          sx={btnStyled}
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          <Typography
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 16,
            }}
          >
            {isEdit ? t("update_button") : t("save")}
          </Typography>
        </Button>
      </Box>
    </div>
  );
};

export default WaitRateAdd;

const btnStyled = {
  borderRadius: "100px",
  backgroundColor: MAIN_COLOR,
  minHeight: 25,
  marginBottom: "10px",
  textAlign: "center",
  width: {
    xs: "30%",
    md: "25%",
    lg: "22%",
  },
};
