import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { api } from "common";
import { MAIN_COLOR } from "common/sharedFunctions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const TaxiMetterAdd = ({ handleClose, editData, isEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addTaxiMetter, updateTaxiMetter } = api;
  const taxiData = useSelector(
    (state) => state.taximetterdata.taximetterdatalist
  );
  const scheduleData = useSelector((state) => state.scheduledata);
  const initialState = {
    name: "",
    id: taxiData.length + 1,
    startPrice: "",
    forFirst: "",
    distanceRate: "",
    distancePerM: "",
    timeRate: "",
    timePerS: "",
    tarrifRate: "",
    tarrifRateS: "",
    freeTrafficRate: "",
    minimumPrice: "",
  };
  const [metterData, setMetterData] = useState(initialState);
  useEffect(() => {
    if (isEdit) {
      setMetterData({
        name: editData.name || "",
        id: editData.id || taxiData.length + 1,
        startPrice: editData.startPrice || "",
        forFirst: editData.forFirst || "",
        distanceRate: editData.distanceRate || "",
        distancePerM: editData.distancePerM || "",
        timeRate: editData.timeRate || "",
        timePerS: editData.timePerS || "",
        tarrifRate: editData.tarrifRate || "",
        tarrifRateS: editData.tarrifRateS || "",
        freeTrafficRate: editData.freeTrafficRate || "",
        minimumPrice: editData.minimumPrice || "",
      });
    } else {
      setMetterData(initialState);
    }
  }, [editData, taxiData, isEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMetterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const availableSchedules = scheduleData?.scheduledatalist?.filter(
    (scheduleItem) =>
      !taxiData.some((taxiItem) => taxiItem.name === scheduleItem.name)
  );
  const dropdownSchedules = isEdit
    ? [...availableSchedules, { id: editData?.id, name: editData?.name }]
    : availableSchedules;
  const onSubmit = () => {
    if (!availableSchedules || availableSchedules.length === 0) {
      toast.error(t("please_create_first_schedule"));
      return;
    }
    if (!metterData.name) {
      toast.error(t("please_select_a_name"));
      return;
    }

    const existingIndex = taxiData.findIndex(
      (data) => data.id === metterData.id
    );
    if (existingIndex !== -1 && isEdit) {
      dispatch(updateTaxiMetter(metterData.id, metterData));
      toast.success(t("updated_successfully"));
    } else {
      dispatch(addTaxiMetter(metterData));
      toast.success(t("add_successfully"));
    }
    setMetterData(initialState);
    handleClose();
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: "100%", marginTop: "15px" }}
        justifyContent="start"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="zone_select">{t("name")}</InputLabel>
            <Select name="name" value={metterData.name} onChange={handleChange}>
              {dropdownSchedules?.map((data) => (
                <MenuItem key={data.id} value={data.name}>
                  {data.name.trim()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("startPrice")}
            name="startPrice"
            value={metterData.startPrice}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("forFirst")}
            name="forFirst"
            value={metterData.forFirst}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("distanceRate")}
            name="distanceRate"
            value={metterData.distanceRate}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("distancePer_m")}
            name="distancePerM"
            value={metterData.distancePerM}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("timeRate")}
            name="timeRate"
            value={metterData.timeRate}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("timePer_s")}
            name="timePerS"
            value={metterData.timePerS}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("tarrifRate")}
            name="tarrifRate"
            value={metterData.tarrifRate}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("tarrifRate_s")}
            name="tarrifRateS"
            value={metterData.tarrifRateS}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("freeTrafficRate")}
            name="freeTrafficRate"
            value={metterData.freeTrafficRate}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("minimumPrice")}
            name="minimumPrice"
            value={metterData.minimumPrice}
            placeholder="0"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Button
          sx={btnStyled}
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          <Typography
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 16,
            }}
          >
            {isEdit ? t("update_button") : t("save")}
          </Typography>
        </Button>
      </Box>
    </div>
  );
};

export default TaxiMetterAdd;

const btnStyled = {
  borderRadius: "100px",
  backgroundColor: MAIN_COLOR,
  minHeight: 25,
  marginBottom: "10px",
  textAlign: "center",
  width: {
    xs: "30%",
    md: "25%",
    lg: "22%",
  },
};
