import React, { useState, useEffect, useRef } from "react";
import { downloadCsv } from "../common/sharedFunctions";

import {
  Button,
  Modal,
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { colors } from "../components/Theme/WebTheme";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import MinimumTimeSlider from "./TariffSlider";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import {
  addSchedule,
  deleteSchedule,
  updateSchedule,
  userIdCheck,
} from "common/src/actions/scheduleactions";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: SECONDORY_COLOR,
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: MAIN_COLOR,
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "#F0F0F0",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

export default function ScheduleTariff() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const navigate = useNavigate();
  const { editUser, deleteUser, fetchUsersOnce } = api;
  const [data, setData] = useState([]);
  const [sortedData, SetSortedData] = useState([]);

  const staticusers = useSelector((state) => state.usersdata.staticusers);
  
  const scheduleData = useSelector(
    (state) => state.scheduledata.scheduledatalist
  );

  const dispatch = useDispatch();
  const loaded = useRef(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (staticusers) {
      setData(staticusers.filter((user) => user.usertype === "fleetadmin"));
    } else {
      setData([]);
    }
    loaded.current = true;
  }, [staticusers]);

  useEffect(() => {
    if (data) {
      SetSortedData(
        data.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
      );
    }
  }, [data]);

  // const formatTime = (hour) => {
  //   const period = hour < 12 ? "AM" : "PM";
  //   const formattedHour = hour % 12 || 12;
  //   return `${formattedHour} ${period}`;
  // };

  const [timeSchedule, setTimeSchedule] = useState({
    id: "",
    name: "",
    days: {
      monday: [{ startTime: 8, endTime: 18 }],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    },
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setTimeSchedule((prev) => ({ ...prev, [id]: value }));
  };

  const handleTimeChange = (day, index, newStartTime, newEndTime) => {
    const formattedStartTime = newStartTime;
    const formattedEndTime = newEndTime;

    setTimeSchedule((prev) => {
      const newDays = { ...prev.days };
      newDays[day][index] = {
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      };
      return { ...prev, days: newDays };
    });
  };
  const handleAddSlider = (day) => {
    setTimeSchedule((prev) => {
      const newDays = { ...prev.days };
      newDays[day] = [...newDays[day], { startTime: 8, endTime: 18 }];
      return { ...prev, days: newDays };
    });
  };

  const handleRemoveSlider = (day) => {
    setTimeSchedule((prev) => {
      const newDays = { ...prev.days };
      if (newDays[day].length > 0) newDays[day].pop();
      return { ...prev, days: newDays };
    });
  };

  const [isEdit, setIsEdit] = useState(false);
  const handleSave = () => {
    if (!timeSchedule.name || !timeSchedule.id) {
      toast.error(t("Please_ID_Name_schedule"));
      return;
    }
    const existingScheduleIndex = scheduleData.findIndex(
      (schedule) => schedule.id === timeSchedule.id
    );
    if (existingScheduleIndex !== -1 && isEdit) {
      dispatch(updateSchedule(timeSchedule.id, timeSchedule));
      toast.success(t("Schedule_updated_successfully"));
    } else {
      const existingId = scheduleData.findIndex(
        (schedule) => schedule.id === timeSchedule.id
      );
      if (existingId !== -1) {
        toast.error("Id_Already_exit");
      } else {
        dispatch(addSchedule(timeSchedule));
        toast.success(t("Schedule_add_successfully"));
      }
    }
    setTimeSchedule({
      id: "",
      name: "",
      days: {
        monday: [{ startTime: 8, endTime: 18 }],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      },
    });
    setOpen(false);
  };

  const handleEdit = (id) => {
    setIsEdit(true);
    const scheduleToEdit = scheduleData.find((schedule) => schedule.id === id);
    if (scheduleToEdit) {
      const allDays = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const updatedDays = allDays.reduce((acc, day) => {
        acc[day] = scheduleToEdit.days[day] || [];
        return acc;
      }, {});
      setTimeSchedule({
        ...scheduleToEdit,
        days: updatedDays,
      });
      setOpen(true);
    }
  };

  const handleDeleteClick = (id) => {
    dispatch(deleteSchedule(id));
    toast.success(t("deleted"));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {scheduleData && scheduleData.length > 0 ? (
        scheduleData.map((schedule, index) => (
          <Box key={index} sx={containerStyled}>
            <Box sx={scheduleStyled}>
              <Box style={addStyling}>
                <Box>
                  <span>id:</span>
                  <span>{schedule.id}</span>
                </Box>
                <Box>
                  <span>Name:</span>
                  <span>{schedule.name}</span>
                </Box>
              </Box>
              <Box>
                {Object.keys(schedule.days || {})
                  .filter((day) => (schedule.days?.[day]?.length || 0) > 0)
                  .join(", ")}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                width: "7%",
                cursor: "pointer",
              }}
            >
              <EditIcon onClick={() => handleEdit(schedule.id)} />
              <DeleteIcon onClick={() => handleDeleteClick(schedule.id)} />
            </Box>
          </Box>
        ))
      ) : (
        <Box>{t("No_data_available")}</Box>
      )}

      <Button
        style={{
          borderRadius: "100px",
          backgroundColor: MAIN_COLOR,
          minHeight: 30,
          marginBottom: 20,
          textAlign: "center",
          width: "20%",
        }}
        type="submit"
        variant="contained"
        onClick={handleOpen}
      >
        <Typography
          style={{
            color: "white",
            textAlign: "center",
            fontSize: 16,
          }}
        >
          {t("addnew")}
        </Typography>
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ maxWidth: "80%" }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label={t("Id")}
                  id="id"
                  fullWidth
                  required
                  value={timeSchedule.id}
                  onChange={handleInputChange}
                  disabled={isEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label={t("name")}
                  id="name"
                  fullWidth
                  required
                  value={timeSchedule.name}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Box>

          <Paper elevation={3} sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              {Object.keys(timeSchedule.days || {}).map((day) => (
                <Grid item xs={12} key={day}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 2,
                      border: "1px solid #ccc",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {day}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {timeSchedule.days[day]?.map((slot, index) => (
                        <MinimumTimeSlider
                          key={`${day}-${index}`}
                          startTime={slot.startTime}
                          endTime={slot.endTime}
                          onTimeChange={(newStartTime, newEndTime) =>
                            handleTimeChange(
                              day,
                              index,
                              newStartTime,
                              newEndTime
                            )
                          }
                        />
                      ))}
                    </Box>
                    <Box>
                      <Button
                        sx={addBtnStyling}
                        variant="outlined"
                        onClick={() => handleAddSlider(day)}
                      >
                        +
                      </Button>
                      <Button
                        sx={addBtnStyling}
                        variant="outlined"
                        onClick={() => handleRemoveSlider(day)}
                      >
                        -
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
            }}
            onClick={handleSave}
          >
            <Button
              style={{
                borderRadius: "19px",
                backgroundColor: MAIN_COLOR,
                minHeight: 30,
                marginBottom: 20,
                textAlign: "center",
                width: "15%",
              }}
              type="submit"
              variant="contained"
            >
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 16,
                }}
              >
                {isEdit ? t("update_button") : t("save")}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

const containerStyled = {
  border: "1px solid grey",
  borderRadius: "8px",
  padding: "20px 5px",
  display: "flex",
  alignItems: "center",
  marginBottom: "15px",
  width: "100%",
};
const scheduleStyled = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "93%",
};
const addStyling = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};
const addBtnStyling = {
  borderRadius: "50%",
  minWidth: "30px",
  minHeight: "30px",
  margin: "0 4px",
  color: "#829baf",
  borderColor: "#829baf",
};
