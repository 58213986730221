import {
  FETCH_SCHEDULE,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILED,
  EDIT_SCHEDULE,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAILED,
  ADD_SCHEDULE,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_FAILED,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILED,
} from "../store/types";
import { onValue, set, update, remove } from "firebase/database";
import { firebase } from "../config/configureFirebase";

export const fetchSchedule = () => (dispatch) => {
  const { scheduleRef } = firebase;
  dispatch({ type: FETCH_SCHEDULE });
  try {
    onValue(scheduleRef, (snapshot) => {
      const data = snapshot.val() || {};
      // Convert object to array if necessary
      const scheduleList = Object.values(data);
      dispatch({ type: FETCH_SCHEDULE_SUCCESS, payload: scheduleList });
    });
  } catch (error) {
    dispatch({ type: FETCH_SCHEDULE_FAILED, payload: error.message });
  }
};

export const addSchedule = (newScheduleData) => (dispatch, getState) => {
  const { scheduleRef } = firebase;
  
  dispatch({ type: ADD_SCHEDULE });
  set(scheduleRef, [
    ...getState().scheduledata.scheduledatalist,
    newScheduleData,
  ])
    .then(() => {
      dispatch({
        type: ADD_SCHEDULE_SUCCESS,
        payload: [newScheduleData],
      });
    })
    .catch((error) => {
      dispatch({ type: ADD_SCHEDULE_FAILED, payload: error.message });
    });
};

export const updateSchedule =
  (id, updatedScheduleData) => (dispatch, getState) => {
    const { scheduleRef } = firebase;
    dispatch({ type: EDIT_SCHEDULE });
    const currentScheduleList = getState().scheduledata.scheduledatalist;
    const scheduleIndex = currentScheduleList.findIndex(
      (schedule) => schedule.id === id
    );
    if (scheduleIndex !== -1) {
      const updatedScheduleList = [
        ...currentScheduleList.slice(0, scheduleIndex),
        updatedScheduleData,
        ...currentScheduleList.slice(scheduleIndex + 1),
      ];
      set(scheduleRef, updatedScheduleList)
        .then(() => {
          dispatch({
            type: EDIT_SCHEDULE_SUCCESS,
            payload: updatedScheduleData,
          });
        })
        .catch((error) => {
          dispatch({
            type: EDIT_SCHEDULE_FAILED,
            payload: error.message,
          });
        });
    } else {
      dispatch({
        type: EDIT_SCHEDULE_FAILED,
        payload: "Schedule not found for the given ID.",
      });
    }
  };

export const deleteSchedule = (id) => (dispatch, getState) => {
  const { scheduleRef } = firebase;
  dispatch({ type: DELETE_SCHEDULE });
  const currentScheduleData = getState();
  const scheduladatalist =
    currentScheduleData.scheduledata?.scheduledatalist || [];
  const scheduleIndex = scheduladatalist.findIndex(
    (schedule) => schedule.id === id
  );
  if (scheduleIndex !== -1) {
    const updatedData = [...scheduladatalist];
    updatedData.splice(scheduleIndex, 1);
    set(scheduleRef, updatedData)
      .then(() => {
        dispatch({
          type: DELETE_SCHEDULE_SUCCESS,
          payload: updatedData,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SCHEDULE_FAILED,
          payload: error.message,
        });
      });
  } else {
    dispatch({
      type: DELETE_SCHEDULE_FAILED,
      payload: "Schedule ID not found.",
    });
  }
};

export const userIdCheck = (newScheduleData) => (dispatch, getState) => {
  const { scheduleRef } = firebase; // Assuming you need this to add data to Firebase
  const { id } = newScheduleData;

  // Check if the ID already exists in the state
  const existingSchedule = getState().scheduledata.scheduledatalist.find(
    (schedule) => schedule.id === id
  );

  if (existingSchedule) {
    // If the ID exists, dispatch a failure action
    dispatch({
      type: "ADD_SCHEDULE_FAILED",
      payload: "Schedule with this User ID already exists.",
    });
  } else {
    scheduleRef
      .add(newScheduleData)
      .then(() => {
        dispatch({
          type: "ADD_SCHEDULE_SUCCESS",
          payload: "added successfully.",
        });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_SCHEDULE_FAILED",
          payload: `Error adding schedule: ${error.message}`,
        });
      });
  }
};
