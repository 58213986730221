// import React, { useState, useEffect } from 'react';

// import { useTranslation } from "react-i18next";
// import MaterialTable from 'material-table';
// import { useDispatch, useSelector } from "react-redux";
// import CircularLoading from 'components/CircularLoading';
// import {colors} from '../components/Theme/WebTheme';
// import {SECONDORY_COLOR } from "../common/sharedFunctions";
// import { useNavigate } from 'react-router-dom';
// import { deleteZone } from 'common/src/actions/zoneactions';

// export default function Zones(props) {
//     const navigate = useNavigate();
//   const { t, i18n } = useTranslation();
//   const dispatch = useDispatch();

//   const isRTL = i18n.dir();
 
  
//   const [data, setData] = useState();
//   const zonedata = useSelector(state => state.zonedata);
//   const settings = useSelector(state => state.settingsdata.settings);

//   const columns =  [
//     // {
//     //   title:t("sr"),
//     //   field:"tableData.id",
//     // },
//       {
//         title: t('id'),
//         field: 'zoneId',
//       },
//       {
//         title: t('code'),
//         field: 'zoneCode',
//       },
//       { title: t('name'),
//         field: 'zoneName', 
//     }
//   ];

//   useEffect(() => {
//     if (zonedata.zone) {
//       setData(zonedata.zone);
//     } else {
//       setData([]);
//     }
//   }, [zonedata.zone]);



//   const [selectedRow, setSelectedRow] = useState(null);
//   return (
//     zonedata.loading?
//       <CircularLoading /> :
//       <div>
//         <MaterialTable
//           title={t('zonelabel')}
//           columns={columns}
//           style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`}}
//           data={data}
//           onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
//           options={{
//             pageSize: 10,
//             pageSizeOptions: [10, 15, 20],
         
//             maxColumnSort: "all_columns",
//             rowStyle: rowData => ({
//               backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
//                  border: "1px solid rgba(224, 224, 224, 1)",
//             }),
//             editable:{
//               backgroundColor: colors.Header_Text,
//               fontSize: "0.8em",
//               fontWeight: 'bold ',
//               fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//             },
//             headerStyle: {
//               position: "sticky",
//               top: "0px",
//               fontSize: "0.8em",
//               fontWeight: 'bold ',
//               fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//               color: colors.BLACK,
//               backgroundColor: SECONDORY_COLOR,
//               textAlign: "center",
//               border: "1px solid rgba(224, 224, 224, 1)",
//             },
//             cellStyle: {
//               border: "1px solid rgba(224, 224, 224, 1)",
//               textAlign: "center",
//             },
//             actionsColumnIndex: -1,
            
//           }}
//           editable={{
//             onRowDelete: (oldData) =>
//               settings.AllowCriticalEditsAdmin
//                 ? new Promise((resolve) => {
//                     setTimeout(() => {
//                       resolve();
//                       dispatch(deleteZone(oldData.id))
                     
//                     }, 600);
//                   })
//                 : new Promise((resolve) => {
//                     setTimeout(() => {
//                       resolve();
//                       alert(t("demo_mode"));
//                     }, 600);
//                   }),
//           }}
//           actions={[
//             {
//               icon: "edit",
//               tooltip: t("edit"),
//               onClick: (event, rowData) => {
//                 console.log(event,rowData)
//                 navigate(`/zones/editzones/${rowData.id}`);
//               },
//             },
//             {
//               icon: 'add',
//               tooltip: t("add"),
//               isFreeAction: true,
//               onClick: (event) => navigate("/zones/addzones")
//             },
            
          
//           ]}
       
//         />
      
//       </div>
//   );
// }
import React from 'react'
import AddMaps from "./AddMaps";
import Card from 'components/Card/Card';
import { CardContent, Typography } from '@mui/material';
import { t } from 'i18next';


function Zones() {
  return (
    <Card>
    <CardContent>
      <Typography> <strong>! Note:</strong>{t('add_zone_note')}</Typography>
      <AddMaps />
    </CardContent>
  </Card>
    
  )
}

export default Zones
