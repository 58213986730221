import { Box, IconButton, Input } from "@mui/material";
import ChatCard from "../foundation/ChatCard/ChatCard";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import CustomAppBar from "views/foundation/CustomAppBar/CustomAppBar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDrivers } from "common/src/actions/usersactions";
import { t } from "i18next";

export default function LeftPanel({ selectedDriver, setSelectedDriver }) {
  const dispatch = useDispatch();
  const { users } = useSelector((store) => store.usersdata);
  const [search, setSearch] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);

  useEffect(() => {
    dispatch(fetchDrivers());

    if (users) {
      const driverList = users.filter((user) => user.usertype === "driver");
      setDrivers(driverList);
      setFilteredDrivers(driverList); // Initialize the filtered list
    }
  }, [dispatch, users]);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);

    if (searchValue === "") {
      setDrivers(users.filter((user) => user.usertype === "driver"));
    } else {
      const filteredDrivers = users
        .filter((user) => user.usertype === "driver")
        .filter((driver) => {
          driver.fullName = driver.firstName + " " + driver.lastName;
          return driver.fullName.toLowerCase().includes(searchValue.trim());
        });
      setDrivers(filteredDrivers);
    }
  };

  return (
    <Box width="40%" overflow="hidden">
      <CustomAppBar>
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {t("chats_title")}
        </Box>
      </CustomAppBar>

      <Box
        sx={{
          background: "white",
          padding: "12px",
        }}
        display="flex"
      >
        <Box
          display="flex"
          sx={{
            background: "#DDDEEE",
            borderRadius: "8px",
            padding: "0px 8px",
          }}
          flex={1}
          alignItems="center"
        >
          <IconButton onClick={() => {}}>
            <SearchIcon
              sx={{
                color: "#8696a1",
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
          <Input
            fullWidth
            disableUnderline
            placeholder="Search Driver"
            sx={{
              height: "35px",
              color: "black",
              padding: "0px 13px",
              fontSize: "14px",
            }}
            value={search}
            onChange={(e) => handleSearch(e)}
          />
        </Box>

      </Box>
      <Box
        overflow="auto"
        height="90%"
        sx={{
          background: "white",
        }}
      >
        {drivers?.map((item, index) => {
          return (
            <ChatCard
              key={item.id}
              item={item}
              selectedDriver={selectedDriver}
              setSelectedDriver={setSelectedDriver}
              index={index}
            />
          );
        })}
        <Box pt="50px" />
      </Box>
    </Box>
  );
}
