import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { MAIN_COLOR } from "common/sharedFunctions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import FlatTariffsAdd from "./FlatTariffsAdd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { api } from "common";

const FlatTariffs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deleteFlatTariffs, updateFlatTariffs } = api;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setEditData(false);
  };
  const scheduleData = useSelector(
    (state) => state.scheduledata.scheduledatalist
  );
  const tariffsData = useSelector(
    (state) => state.flattariffsdata.flattariffslist
  );
  const [inputValues, setInputValues] = useState(() => {
    return tariffsData.reduce((acc, row) => {
      acc[row.id] = scheduleData.reduce((colAcc, schedule) => {
        colAcc[schedule.id] = {
          scheduleId: schedule.id,
          scheduleName: schedule.name,
          price: "",
        };
        return colAcc;
      }, {});
      return acc;
    }, {});
  });
  const [saveBtn, setSaveBtn] = useState({});
  const [editData, setEditData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleDelete = (id) => {
    dispatch(deleteFlatTariffs(id));
    toast.success(t("Deleted"));
  };
  const handleEdit = (id) => {
    setIsEdit(true);
    const selectedData = tariffsData.find((row) => row.id === id);
    if (selectedData) {
      setEditData(selectedData);
      handleOpen();
    }
  };
  const handleChange = (rowId, columnId, value) => {
    setInputValues((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [columnId]: {
          ...prev[rowId][columnId],
          price: value,
        },
      },
    }));

    setSaveBtn((prev) => ({
      ...prev,
      [rowId]: true,
    }));
  };

  const handleSave = (rowId) => {
    const savedValues = inputValues[rowId];
    const updatedTariff = tariffsData.find((row) => row.id === rowId);
    if (updatedTariff) {
      const updatedData = {
        ...updatedTariff,
        scheduleData: Object.values(savedValues).map((schedule) => ({
          scheduleId: schedule.scheduleId,
          scheduleName: schedule.scheduleName,
          price: schedule.price || "",
        })),
      };
      dispatch(updateFlatTariffs(rowId, updatedData));
      toast.success(t("Values_saved"));
      setSaveBtn((prev) => ({
        ...prev,
        [rowId]: false,
      }));
    } else {
      toast.error(t("Values_not_saved"));
    }
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography style={{ color: MAIN_COLOR, fontSize: 25 }}>
          {t("Flat_tariffs")}
        </Typography>
        <Button sx={btnStyled} onClick={handleOpen} variant="contained">
          <Typography
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 16,
            }}
          >
            {t("create")}
          </Typography>
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={tabelStyled}> {t("SR_No")} </TableCell>
              <TableCell sx={tabelStyled}> {t("name")} </TableCell>
              <TableCell sx={tabelStyled}> {t("from")} </TableCell>
              <TableCell sx={tabelStyled}>{t("to")}</TableCell>
              <TableCell sx={tabelStyled}>{t("Return")}</TableCell>
              {scheduleData && scheduleData.length > 0
                ? scheduleData.map((data, index) => (
                    <TableCell key={index} sx={tabelStyled}>
                      {data.name}
                    </TableCell>
                  ))
                : null}
              <TableCell sx={tabelStyled} align="right">
                {t("actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tariffsData && tariffsData.length > 0 ? (
              tariffsData.map((row, index) => (
                <TableRow style={{ whiteSpace: "nowrap" }} key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.from}</TableCell>
                  <TableCell>{row.to}</TableCell>
                  <TableCell>{row.return}</TableCell>
                  {scheduleData?.map((data) => (
                    <TableCell key={data.id}>
                      <Grid item xs={12}>
                        <TextField
                          type="number"
                          placeholder="00.00"
                          value={
                            inputValues[row.id]?.[data.id]?.price ||
                            tariffsData
                              .find((tariff) => tariff.id === row.id)
                              ?.scheduleData?.find(
                                (schedule) => schedule.scheduleId === data.id
                              )?.price ||
                            ""
                          }
                          onChange={(e) =>
                            handleChange(row.id, data.id, e.target.value)
                          }
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-input": {
                              padding: "8px",
                            },
                          }}
                        />
                      </Grid>
                    </TableCell>
                  ))}

                  <TableCell align="right">
                    {saveBtn[row.id] && (
                      <Button
                        variant="contained"
                        onClick={() => handleSave(row.id)}
                        sx={{ marginLeft: 1 }}
                      >
                        Save
                      </Button>
                    )}
                    <IconButton>
                      <EditIcon onClick={() => handleEdit(row.id)} />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon onClick={() => handleDelete(row.id)} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Box style={{ whiteSpace: "nowrap" }}>
                {t("No_data_available")}
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        sx={modelWrapper}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={3} sx={modelCard}>
          <IconButton onClick={handleClose} sx={crossIcon}>
            <CloseIcon />
          </IconButton>
          <Box>
            <FlatTariffsAdd
              handleClose={handleClose}
              editData={editData}
              isEdit={isEdit}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default FlatTariffs;
const btnStyled = {
  borderRadius: "100px",
  backgroundColor: MAIN_COLOR,
  minHeight: 30,
  marginBottom: "10px",
  textAlign: "center",
  width: {
    xs: "30%",
    sm: "28%",
    md: "20%",
    lg: "13%",
  },
};
const modelWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const modelCard = {
  px: 4,
  py: 3,
  maxWidth: "750px",
  maxHeight: "550px",
  height: "100%",
  width: "100%",
  borderRadius: "20px",
  position: "relative",
  overflow: "auto",
};
const crossIcon = {
  position: "absolute",
  top: 8,
  right: 8,
  color: "black",
  zIndex: 1300,
};
const tabelStyled = {
  background: "#DDDEEE",
  fontWeight: "bold",
  whiteSpace: "nowrap",
};
