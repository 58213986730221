import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Riders from "./Riders";
import Drivers from "./Drivers";
import FleetAdmins from "./FleetAdmins";
import CreateAdmin from "./CreateAdmin";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import TransportCard from "components/InfoCard";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: SECONDORY_COLOR,
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: MAIN_COLOR,
    },
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function JobSummery() {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const [role, setRole] = React.useState(null);
  const bookinglistdata = useSelector(
    (state) => state.bookinglistdata?.bookings
  );

  const unassignedBookings = bookinglistdata.filter(
    // eslint-disable-next-line no-mixed-operators
    (item) => (!(item.status === "CANCELLED" || item.status === "COMPLETE") && !item.driver));
  const assignedBookings = bookinglistdata.filter(
    // eslint-disable-next-line no-mixed-operators
    (item) => (!(item.status === "CANCELLED" || item.status === "COMPLETE") && item.driver));
  const activeBookings = bookinglistdata.filter(
    // eslint-disable-next-line no-mixed-operators
    (item) => !(item.status === "CANCELLED" || item.status === "COMPLETE"));

  const classes = useStyles();

  React.useEffect(() => {
    if (auth.profile && auth.profile.usertype) {
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.tabs}
        >
          <Tab label={t("unassigned")} {...a11yProps(0)} />
          <Tab label={t("assigned")} {...a11yProps(1)} />
          <Tab label={t("active_booking")} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <>
          {unassignedBookings &&
            unassignedBookings.map((booking) => (
              <TransportCard
                bookingDate={booking?.bookingDate}
                status={booking?.status}
                customer_name={booking?.customer_name}
                customer_mobile={booking?.customer_contact}
                pickUpAddress={booking?.pickupAddress}
                dropAddress={booking.dropAddress}
                passangerCount={booking?.passCount}
                bagCount={booking?.bagCount}
                wheelChairCount={booking?.wheelChairCount} 
                routeDistance={booking?.estimateDistance}
                routeTime={booking?.estimateDistance}
                payment_mode={booking?.payment_mode}
                vehicleMake={booking?.vehicleMake}
                vehicleModel={booking?.vehicleModel}
                driver_name={booking?.driver_name}
              />
            ))}
        </>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <>
          {assignedBookings &&
            assignedBookings.map((booking) => (
              <TransportCard
                bookingDate={booking?.bookingDate}
                status={booking?.status}
                customer_name={booking?.customer_name}
                customer_mobile={booking?.customer_contact}
                pickUpAddress={booking?.pickupAddress}
                dropAddress={booking.dropAddress}
                passangerCount={booking?.passCount}
                bagCount={booking?.bagCount}
                wheelChairCount={booking?.wheelChairCount}
                routeDistance={booking?.estimateDistance}
                routeTime={booking?.estimateDistance}
                payment_mode={booking?.payment_mode}
                vehicleMake={booking?.vehicleMake}
                vehicleModel={booking?.vehicleModel}
                driver_name={booking?.driver_name}
              />
            ))}
        </>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <>
          {activeBookings &&
            activeBookings.map((booking) => (
              <TransportCard
                bookingDate={booking?.bookingDate}
                status={booking?.status}
                customer_name={booking?.customer_name}
                customer_mobile={booking?.customer_contact}
                pickUpAddress={booking?.pickupAddress}
                dropAddress={booking.dropAddress}
                passangerCount={booking?.passCount}
                bagCount={booking?.bagCount}
                wheelChairCount={booking?.wheelChairCount}
                routeDistance={booking?.estimateDistance}
                routeTime={booking?.estimateDistance}
                payment_mode={booking?.payment_mode}
                vehicleMake={booking?.vehicleMake}
                vehicleModel={booking?.vehicleModel}
                driver_name={booking?.driver_name}
              />
            ))}
        </>
      </TabPanel>
    </Box>
  );
}
