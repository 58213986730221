import { 
    FETCH_INVOICES,
    FETCH_INVOICES_SUCCESS,
    FETCH_INVOICES_FAILED,
    EDIT_INVOICE
  } from "../store/types";
  
  export const INITIAL_STATE = {
    invoices:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const invoicesreducer =  (state = INITIAL_STATE, action) => {

    switch (action.type) {
      case  FETCH_INVOICES:
        return {
          ...state,
        };
      case  FETCH_INVOICES_SUCCESS:
        return {
          ...state,
          invoices:action.payload,
        };
      case FETCH_INVOICES_FAILED:
        return {
          ...state,
          invoices:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_INVOICE:
        return state;
      default:
        return state;
    }
  };