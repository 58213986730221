import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CircularLoading from "components/CircularLoading";
import { Box } from "@mui/material";
import TaxiMetter from "./taximetter/TaxiMetter";
import WaitRate from "./waitrate/WaitRate";
import FlatTariffs from "./flattariffs/FlatTariffs";
import ZoneTable from "./zonetable/ZoneTable";

export default function BelowDetailedData(props) {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState();
  const companiesdata = useSelector((state) => state.companiesdata);
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  useEffect(() => {
    if (companiesdata.companies) {
      setData(companiesdata.companies);
    } else {
      setData([]);
    }
  }, [companiesdata.companies]);

  return companiesdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      {/* <MaterialTable
          title='Zone tariff'
          columns={columns}
          data={data}
          onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          options={{
            pageSize: 10,
            search:false,
            pageSizeOptions: [10, 15, 20],

            maxColumnSort: "all_columns",
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
                 border: "1px solid rgba(224, 224, 224, 1)",
            }),
            editable:{
              backgroundColor: colors.Header_Text,
              fontSize: "0.8em",
              fontWeight: 'bold ',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            },
            headerStyle: {
              position: "sticky",
              top: "0px",
              fontSize: "0.8em",
              fontWeight: 'bold ',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              color: colors.BLACK,
              backgroundColor: SECONDORY_COLOR,
              textAlign: "center",
              border: "1px solid rgba(224, 224, 224, 1)",
            },
            cellStyle: {
              border: "1px solid rgba(224, 224, 224, 1)",
              textAlign: "center",
            },
            actionsColumnIndex: -1,
            
          }}
          editable={{
            onRowDelete: (oldData) =>
              settings.AllowCriticalEditsAdmin
                ? new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      dispatch(deleteCompany(oldData.id))
                     
                    }, 600);
                  })
                : new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      alert(t("demo_mode"));
                    }, 600);
                  }),
          }}
          actions={[
         
            {
              icon: "edit",
              tooltip: t("profile_page_subtitle"),
              onClick: (event, rowData) => {
                navigate(`/companies/editcompany/${rowData.id}`);
                
              },
            },
            {
              icon: "info",
              tooltip: t("profile_page_subtitle"),
              onClick: (event, rowData) => {
                navigate(`/companies/companydetails/${rowData.id}`);
                
              },
            },
            {
              icon: 'add',
              tooltip: t("add"),
              isFreeAction: true,
              onClick: (event) => navigate("/zones/addzones")
            },
            
          
          ]}
       
        />
      */}

      <Box sx={boxStyled}>
        {/* <ZoneTable /> */}
        <FlatTariffs />
        <TaxiMetter />
        <WaitRate />
      </Box>
    </div>
  );
}

const boxStyled = {
  backgroundColor: "white",
  padding: " 25px 15px",
  borderRadius: 3,
};
