import React, { useEffect, useState } from "react";
import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CircularLoading from "components/CircularLoading";
import { useParams } from "react-router-dom";
import { colors } from "components/Theme/WebTheme";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
  rootRtl_3: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl: {
    "& label": {
      right: 20,
      left: "auto",
      paddingRight: 20,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_1: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 30,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_2: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_4: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },

  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 0,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 35,
    },
  },

  right: {
    textAlign: "right",
    right: 0,
    left: "auto",
    paddingRight: 40,
  },
}));

const UpdateCarType = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const cartypes = useSelector((state) => state.cartypes);
  const carlistdata = useSelector((state) => state.carlistdata);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loding, setLoding] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [imageUrl,setImageUrl]= useState("");

  const { editCarType, updateUserCar, editCar } = api;
  const [oldData, setOldData] = useState(null);
  const [data, setData] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (cartypes.cars) {
      const carData = cartypes.cars.filter(
        (item) => item.id === id.toString()
      )[0];
      if (!carData) {
        navigate("/404");
      }
      setData(carData);
      setOldData(carData);
    } else {
      setData([]);
    }
  }, [cartypes.cars, id, navigate]);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const handleInputToNumberChange = (e) => {
    setData({ ...data, [e.target.id]: Number(e.target.value) });
  };

  const handleExtraInfoChange = (e) => {
    setData({ ...data, extra_info: e.target.value });
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  const handleSubmit = () => {
    settings.AllowCriticalEditsAdmin
      ? new Promise((resolve) => {
        setLoding(true);
        setTimeout(() => {
          resolve();
          if (data !== oldData) {
            delete data.tableData;
            if (data.name !== oldData.name) {
              let users = staticusers?.filter(
                (user) =>
                  user.usertype === "driver" && user.carType === oldData.name
              );
              for (let i = 0; i < users?.length; i++) {
                dispatch(
                  updateUserCar(users[i].id, {
                    carType: data.name,
                  })
                );
              }
              let cars = carlistdata.cars.filter(
                (car) => car.carType === oldData.name
              );
              for (let i = 0; i < cars.length; i++) {
                dispatch(
                  editCar({ ...cars[i], carType: data.name }, "Update")
                );
              }
            }
            dispatch(editCarType(data, "Update"));
            navigate("/cartypes");
            setLoding(false);
          }
        }, 600);
      })
      : new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          alert(t("demo_mode"));
        }, 600);
      });
  };
  
  const options = [
    { value: "Station wagon", label: "Station wagon" },
    { value: "Sedan", label: "Sedan" },
    { value: "Minibus", label: "Minibus" },
    { value: "Compact", label: "Compact" },
    { value: "Bus", label: "Bus" },
    { value: "Moped", label: "Moped" },
    { value: "Truck", label: "Truck" },
    { value: "Tow truck", label: "Tow truck" },
    { value: "Limousine", label: "Limousine" },
    { value: "Van", label: "Van" },
    { value: "Tuck tuck", label: "Tuck tuck " },
    { value: "SUV", label: "SUV" },

  ];
  const coloroptions = [
"Yellow",
"Pink",
"Green",
"Red",
"Orange",
"Blue",
"Silver",
"Black",
"White"
];

useEffect(()=>{
  setData({...data, image:imageUrl})
},[imageUrl])
 
  

  const handleNameAndColorSelectChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...data, [name]: value };

    setData(updatedData);
    switch (updatedData.name) {
      case "Station wagon":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20Black.png?alt=media&token=e97aed7f-38f5-43d6-bfac-1c8d78d24fd0");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20White.png?alt=media&token=1929884e-1ee9-429a-8ad8-bd8ded769189");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20Yellow.png?alt=media&token=bb5d1e19-bd21-47aa-9436-ef7a39948135");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20Pink.png?alt=media&token=ae16407c-8869-4cac-9cf3-6ce5f235f744");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20Green.png?alt=media&token=457f3510-07cf-47aa-9278-586f408fc44a");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20Red.png?alt=media&token=2d090cb4-d76d-4b78-b7e9-743446b7fe59");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20Orange.png?alt=media&token=40cb754d-b5ad-4450-9ff0-bc07856c8344");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20Blue.png?alt=media&token=0f891113-71e1-4fb1-b58a-275f9bc21369");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FStation%20Wagon%20Sliver.png?alt=media&token=b30e9f38-88c1-4dc7-9514-ff8f150786d0");
            break;
          default:break;

        }
        break;
    
      case "Sedan":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSedan%20Black.png?alt=media&token=c3e60327-f50a-4a20-8e17-d0469dd7a1af");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSedan%20White.png?alt=media&token=e89e19dc-8615-4d5a-9565-a4bc59a867d4");
            break;
          case "Yellow":
            setImageUrl("path/to/sedan-yellow.png");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSedan%20Pink.png?alt=media&token=62af6935-7fbb-4e0b-8023-9d448d42e8fb");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSedan%20Green.png?alt=media&token=1fba3542-f38f-48d4-82ab-e785c94a4327");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSedan%20Red.png?alt=media&token=277b4743-db15-4616-9bb9-20f85863afe1");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSedan%20Orange.png?alt=media&token=3c5745a9-da67-452c-9277-7e0669529e31");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSedan%20Blue.png?alt=media&token=edea7fe0-f390-47ee-9b4e-a87119a4b4d9");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSedan%20Sliver.png?alt=media&token=a910ae0e-dfbb-409f-b15e-0147f6ff6d1a");
            break;
            default:break;
        }
        break;
    
      case "Minibus":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMini%20Bus%20Black.png?alt=media&token=46adb72c-39c0-47f6-aba1-5562cc3c5378");
            break;
          case "White":
            setImageUrl("path/to/minibus-white.png");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMini%20Bus%20Yellow.png?alt=media&token=d57b689c-0796-4692-be60-2d96008f467e");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMini%20Bus%20Pink.png?alt=media&token=a1bc3b28-f335-4246-899a-52818d925c3e");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMini%20Bus%20Green.png?alt=media&token=cb6a8d7e-6f8a-4553-821c-524243eac7c1");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMini%20Bus%20Red.png?alt=media&token=8c0e5464-f107-4bfb-84e7-615a268fe0b5");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMini%20Bus%20Orange.png?alt=media&token=74fcb2e5-6ab8-482c-9160-e81606f6b2e2");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMini%20Bus%20Blue.png?alt=media&token=f4efaf4d-468d-4044-b21c-623813dbafa2");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMini%20Bus%20Sliver.png?alt=media&token=46d2a342-0264-4dbb-839a-10685dd7c829");
            break;
            default:break;
        }
        break;
    
      case "Compact":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20Black.png?alt=media&token=923a024e-23a4-42a0-82a5-53644a32f484");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20White.png?alt=media&token=5925c744-926d-4c40-849c-6c56f60a8e2e");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20Yellow.png?alt=media&token=c03977ad-685d-4b5d-8636-741698a840ca");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20Pink.png?alt=media&token=d3848846-f3ca-4604-9bc5-729ef76c0b7f");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20Green.png?alt=media&token=e8136292-1078-46af-8a1d-0a216064c8fa");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20Red.png?alt=media&token=37393cb2-5aba-4a5e-8dc0-72aae96d4540");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20Orange.png?alt=media&token=cfbf41c4-feff-4bec-b117-b4e108206a0b");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20Blue.png?alt=media&token=ee168dfc-94ad-404c-9794-021765d4e116");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FCompact%20Sliver.png?alt=media&token=13ce3a79-ebb3-4d11-920f-e2a10dc20289");
            break;
            default:break;
        }
        break;
    
      case "Bus":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20Black.png?alt=media&token=331df8b5-bbac-43b2-89e6-ddab22b33ebb");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20White.png?alt=media&token=89b716fa-3104-4e97-910c-45ed113ae4e9");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20Yellow.png?alt=media&token=ff8e724f-e834-4454-ad2d-7a1cd093db17");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20Pink.png?alt=media&token=9fb49459-e9bc-4386-b269-c2f5f6495791");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20Green.png?alt=media&token=68c9b501-f556-4a82-ae89-2a48b71509d4");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20Red.png?alt=media&token=468e985e-8920-4094-a861-d12b3e49d241");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20Orange.png?alt=media&token=b11ce0e4-5f66-4d60-96d7-37f04722d7a3");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20Blue.png?alt=media&token=c03a5c2f-bdb1-4f38-8ba7-879a70641e06");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FBus%20Sliver.png?alt=media&token=55cdfdad-f0d2-46ab-b42d-ec2ad8c8ea43");
            break;
          default:
            break;
        }
        break;
    
      case "Moped":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20Black.png?alt=media&token=70e7c4f7-3158-419d-8a8a-45449002eefc");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20White.png?alt=media&token=075d26b8-4e75-44ef-824a-144b3aff0795");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20Yellow.png?alt=media&token=2f69ab08-be52-43bc-9552-e63e864ff09e");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20Pink.png?alt=media&token=8021b4a9-d3bc-4875-a6ad-0a46d3fed483");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20Green.png?alt=media&token=22c03834-ad06-46ab-aaaf-254d4de41fd1");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20Red.png?alt=media&token=4083a4e3-203c-4f39-bf90-f1bdce5a653f");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20Orange.png?alt=media&token=cb673fbc-4c41-412e-b85a-5e82e8f70fce");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20Blue.png?alt=media&token=c27e1453-807b-41c5-917a-11cc3344c697");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FMoped%20Sliver.png?alt=media&token=3c1b1188-db43-4129-81a9-594d88ffdf33");
            break;
          default:
            break;
        }
        break;
    
      case "Truck":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20Black.png?alt=media&token=30ba3752-b19f-4501-81e6-8d704ecc9f08");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20White.png?alt=media&token=590b8d39-ec84-4c06-87a7-a0827c084a5f");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20Yellow.png?alt=media&token=6c7d99a8-b664-4af9-aea4-a2d554bb8818");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20Pink.png?alt=media&token=7048a5bc-46f3-4d93-9500-f9b9ec4c168e");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20Green.png?alt=media&token=f09ece5c-914f-4eeb-917a-759fb866a4f0");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20Red.png?alt=media&token=e30983e4-cf78-4baa-bda7-66145c24c170");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20Orange.png?alt=media&token=4406f930-df12-408e-8261-94329a77350b");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20Blue.png?alt=media&token=bd03b259-40c3-419a-a7b4-f91eff35824c");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTruck%20Sliver.png?alt=media&token=a5e812c7-1f18-4304-83a8-6cc881a6765b");
            break;
          default:
            break;
        }
        break;
    
      case "Tow truck":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20Black.png?alt=media&token=7c0d8a61-3a4d-418b-8329-16bae767825e");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20White.png?alt=media&token=db88b8e5-bca4-403e-985b-c0774877c304");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20Yellow.png?alt=media&token=75ede712-35fe-47a1-aff6-851e529459bf");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20Pink.png?alt=media&token=12878a9d-93e0-46c0-8608-b4a894703c45");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20Green.png?alt=media&token=7906b42a-beb0-411a-b736-1a05b7bd6f6a");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20Red.png?alt=media&token=7cec1188-ebda-4733-ac75-82a32a420725");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20Orange.png?alt=media&token=d355ae0f-20ad-4555-b089-8864d6a17417");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20Blue.png?alt=media&token=b9cce73a-0896-4459-a725-e7e209cc5524");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FTow%20Truck%20Sliver.png?alt=media&token=0118c931-958b-4797-9dca-ed8f7b5ea88b");
            break;
          default:
          break;
        }
        break;
    
      case "Limousine":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20Black.png?alt=media&token=5615a3bd-e691-4826-9720-3abafe0a8c99");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20White.png?alt=media&token=3b200a66-b198-4010-a247-a1282d38a1ee");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20Yellow.png?alt=media&token=ef0956a7-ea90-4c2b-9873-394fcf58276e");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20Pink.png?alt=media&token=4693bc75-2d5c-4f97-a78b-8222a50f40a6");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20Green.png?alt=media&token=dac82b18-247a-442e-9a88-6794601ac65f");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20Red.png?alt=media&token=7e744c1e-227c-45e6-86ba-1ae39be4d90d");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20Orange.png?alt=media&token=f0d0c898-93bf-42c6-aab1-6227d9f061d7");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20Blue.png?alt=media&token=39b5da0b-23f8-4c4f-b9d0-e23eae2ae3d5");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FLimousine%20Sliver.png?alt=media&token=f4d1cb26-a37c-4dbf-932b-059f36d08159");
            break;
          default:
            setImageUrl("path/to/limousine-default.png");
        }
        break;
    
      case "Van":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Black.png?alt=media&token=f0fa2f12-e992-4988-8a03-100962772d61");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20White.png?alt=media&token=92ad000e-85aa-40f4-ba9e-d7c8a77aaf45");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Yellow.png?alt=media&token=a8127b63-dedf-4123-b108-e46b33bbabec");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Pink.png?alt=media&token=26ce2288-74c0-430d-abef-10d224a3f1a1");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Green.png?alt=media&token=5e8b2d12-388c-4c37-bcb1-bfee008248bd");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Red.png?alt=media&token=54fb04d3-ba28-4db9-9745-3f5f74c9ee42");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Orange.png?alt=media&token=e62de7e0-3e24-42b1-b717-63fa067d9b91");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Blue.png?alt=media&token=64a077bb-e9c7-4832-a69e-c0492abe72be");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Sliver.png?alt=media&token=ff519501-c847-42e9-a98b-898bd45a6d76");
            break;
          default:
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FVan%20Yellow.png?alt=media&token=a8127b63-dedf-4123-b108-e46b33bbabec");
        }
        break;
    
      case "Tuck tuck":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Black.png?alt=media&token=f4ba1d08-ec1e-4e69-a3d3-1a409b030b8b");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20White.png?alt=media&token=8dc9e185-005f-4a9a-b3e9-71eb9bd4259f");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Yellow.png?alt=media&token=062bbc68-ace9-4006-afcf-ec96f00655de");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Pink.png?alt=media&token=0a5fecc3-7c2f-49a6-b1a3-01645f4eb8c7");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Green.png?alt=media&token=a46792a4-c731-4352-bfc9-e8225a2f2830");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Red.png?alt=media&token=f9f5b276-2158-467f-8278-eff7a9f82d4d");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Orange.png?alt=media&token=70feacb9-892e-40a2-a974-78d67e5dec50");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Blue.png?alt=media&token=c177dad8-ec85-45a0-a2d1-db69eb164b6b");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Sliver.png?alt=media&token=7b0d1021-8861-472e-bb9c-7cbd6351c7e8");
            break;
          default:
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2Ftuk-tuk%20Yellow.png?alt=media&token=062bbc68-ace9-4006-afcf-ec96f00655de");
        }
        break;
    
      case "SUV":
        switch (updatedData.color) {
          case "Black":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Black.png?alt=media&token=8c34e72a-fb02-46ed-bd96-a115f1fd2d3d");
            break;
          case "White":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20White.png?alt=media&token=68657b60-a82c-4db0-9661-04d94432af03");
            break;
          case "Yellow":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Yellow.png?alt=media&token=e07743a4-d6b4-40f8-aa89-7a53b8ea3de0");
            break;
          case "Pink":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Pink.png?alt=media&token=f4c3a758-95c7-4168-8dbc-ae72709491be");
            break;
          case "Green":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Green.png?alt=media&token=c092d76f-d42f-434b-8372-fffa639ef04e");
            break;
          case "Red":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Red.png?alt=media&token=73dab794-53e7-45a7-955f-f178385161b4");
            break;
          case "Orange":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Orange.png?alt=media&token=e310bcc6-00bc-4451-b9f0-fe30cb6d8459");
            break;
          case "Blue":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Blue.png?alt=media&token=b3b31daf-440f-4663-ae1d-10e5be5f6fcd");
            break;
          case "Silver":
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Sliver.png?alt=media&token=c84e86bf-81a6-4505-8d61-d134d0dd64ad");
            break;
          default:
            setImageUrl("https://firebasestorage.googleapis.com/v0/b/griphen-taxi.appspot.com/o/vehicle_images%2FSUV%20Yellow.png?alt=media&token=e07743a4-d6b4-40f8-aa89-7a53b8ea3de0");
        }
        break;
    
      default:
        setImageUrl("path/to/default-image.png");
    }
    
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  

  const handleChangeConvenienceFeeType = (e) => {
    setData({ ...data, convenience_fee_type: e.target.value });
  };
  return loding ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: colors.WHITE,
          minHeight: 100,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 25,
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
      >
        <Typography
          variant="h5"
          style={{
            margin: "10px 10px 0 5px",
            textAlign: isRTL === "rtl" ? "right" : "left",
          }}
        >
          {t("update_carType_title")}
        </Typography>
        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
          style={{
            marginBottom: 20,
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/cartypes");
            }}
          >
            <Typography
              style={{
                margin: "10px 10px 0 5px",
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div>
        <Grid
          container
          spacing={2}
          sx={{ gridTemplateColumns: "50%", direction:isRTL === "rtl" ? "rtl" : "ltr", }}
        >
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
  <FormControl fullWidth >
    <InputLabel>{t("name")}</InputLabel>
    <Select
      label={t("name")}
      className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
      id="name"
      name="name"
      value={data?.name||""}
      onChange={handleNameAndColorSelectChange}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
  <FormControl fullWidth >
    <InputLabel>{t("color")}</InputLabel>
    <Select
      label={t("color")}
      className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
      id="color"
      name="color"
      value={data?.color||"Yellow"}
      onChange={handleNameAndColorSelectChange}
    >
      {coloroptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("base_fare")}
              id="base_fare"
              value={data?.base_fare || 0}
              fullWidth
              type="number"
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("extra_info")}
              id="extra_info"
              value={data?.extra_info || ""}
              fullWidth
              onChange={handleExtraInfoChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("convenience_fee")}
              id="convenience_fees"
              value={data?.convenience_fees || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_4 : classes.textField
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("rate_per_hour")}
              id="rate_per_hour"
              value={data?.rate_per_hour || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("min_fare")}
              id="min_fare"
              value={data?.min_fare || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_2 : classes.textField
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("rate_per_unit_distance")}
              id="rate_per_unit_distance"
              value={data?.rate_per_unit_distance || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_1 : classes.textField
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("position")}
              id="pos"
              value={data?.pos || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_4 : classes.textField
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                className={isRTL === "rtl" ? classes.right : ""}
                sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
              >
                {t("convenience_fee_type")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="convenience_fee_type"
                value={data?.convenience_fee_type || ""}
                label={t("convenience_fee_type")}
                onChange={handleChangeConvenienceFeeType}
                className={
                  isRTL === "rtl"
                    ? classes.selectField_rtl
                    : classes.selectField
                }
              >
                <MenuItem
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  value={"flat"}
                >
                  {t("flat")}
                </MenuItem>
                <MenuItem
                  style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  value={"percentage"}
                >
                  {t("percentage")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("fleet_admin_fee")}
              id="fleet_admin_fee"
              value={data?.fleet_admin_fee || 0}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_4 : classes.textField
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{t("passenger_capacity")}</InputLabel>
              <Select
                name="passengerCapacity"
                value={data?.passengerCapacity||0}
                onChange={handleSelectChange}
                label={"Passenger Capacity"}

                className={classes.textField}
                
              >
                {Array.from({ length: 11 }, (_, i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                              id="demo-simple-select-label2"
                              className={isRTL === "rtl" ? classes.right : ""}
                              sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
              >{t("disabled_passenger_capacity")}</InputLabel>
              <Select
                              labelId="demo-simple-select-label2"
                id="convenience_fee_type"
                name="disabledPassengerCapacity"
                value={data?.disabledPassengerCapacity||0}
                label={"Disabled Passenger Capacity"}
                onChange={handleSelectChange}
                className={classes.textField}
              >
                {Array.from({ length: 11 }, (_, i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Bag Capacity */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{t("bag_capacity")}</InputLabel>
              <Select
                name="bagCapacity"
                value={data?.bagCapacity||0}
                onChange={handleSelectChange}
                className={classes.textField}
                label={"Bag Capacity"}

              >
                {Array.from({ length: 11 }, (_, i) => (
                  <MenuItem key={i} value={i }>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Button
              style={{
                borderRadius: "19px",
                backgroundColor: MAIN_COLOR,
                minHeight: 50,
                minWidth: "50%",
                textAlign: "center",
                boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              <Typography
                style={{
                  color: colors.WHITE,
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {t("submit")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default UpdateCarType;
