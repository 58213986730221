import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { useSelector } from "react-redux";
import { api, FirebaseContext } from "common";
import { ref, onValue, push, set } from "firebase/database";
import BgImg from "../../assets/img/logo138x75white.png";
import { t } from "i18next";



export default function ChatView() {
  const firebase = useContext(FirebaseContext);

  const { profile } = useSelector((store) => store.auth);
  const [selectedDriver, setSelectedDriver] = useState({});
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(null); // State to store the chat ID
  const {RequestPushMsg} = api;

  


  const user = profile; // Get the current logged-in user

  
  useEffect(() => {
    if (user) {
      if (Object.keys(selectedDriver).length > 0) {
        const generatedChatId = `chat_${selectedDriver.id}`; // Generate a unique chat ID
        setChatId(generatedChatId); // Set the chat ID state
        const messagesRef = ref(
          firebase.database,
          `driverAdminChat/${generatedChatId}`
        );

        setMessages([]);

        const unsubscribe = onValue(messagesRef, (snapshot) => {
          const data = snapshot.val();
          const messagesList = data
            ? Object.entries(data).map(([id, msg]) => ({ id, ...msg }))
            : [];
            console.log(messagesList);
            
          setMessages(messagesList);
        });
        return () => unsubscribe();
      }
    }
  }, [user, selectedDriver, firebase.database]);

  const handleSendMessage = () => {
    if (message.trim() && chatId) {
      const newMessageRef = push(
        ref(firebase.database, `driverAdminChat/${chatId}`)
      );
      const msgData = {
        text: message,
        createdAt: Date.now(),
        uid: user.uid,
        isJoin:"",
        userName:user?.firstName+" "+user?.lastName,
        isRead: true,
        sentBy: "admin",
        email: user.email,
      };
    
      
      RequestPushMsg(selectedDriver.pushToken, {
        title: t("message_title"),
        msg: message,
        screen: "ChatScreen",
        channelId:"bookings",
      });

      // Ensure previous chat is updated before sending the current message
      leaveChat(() => {
        set(newMessageRef, msgData)
          .then(() => {
            // Store the current message in localStorage
            localStorage.setItem(
              "previousChatMsg",
              JSON.stringify({ ...msgData, key: newMessageRef.key })
            );
            localStorage.setItem("previousDriverId", selectedDriver.id);
            setMessage(""); // Clear the input field
          })
          .catch((error) => {
            console.error("Error sending message:", error);
          });
      });
    }
  };

  const leaveChat = (callback) => {
    const previousChatMsg = JSON.parse(localStorage.getItem("previousChatMsg"));
    const previousDriverId = localStorage.getItem("previousDriverId");

    if (previousChatMsg && previousDriverId) {
      const messagesRef = ref(
        firebase.database,
        `driverAdminChat/chat_${previousDriverId}/${previousChatMsg.key}`
      );

      const filteredMsgData = Object.keys(previousChatMsg)
        .filter((objKey) => objKey !== "key")
        .reduce((newObj, key) => {
          newObj[key] = previousChatMsg[key];
          return newObj;
        }, {});

      const updatedMessage = { ...filteredMsgData, isRead: true, isJoin: "" };

      set(messagesRef, updatedMessage)
        .then(() => {
          // console.log("Chat Leave by admin:", updatedMessage);
          if (callback) callback();
        })
        .catch((error) => {
          console.error("Error updating message:", error);
        });
    } else if (callback) {
      callback();
    }
  };

  return (
    <Box display="flex" flexDirection="row" height={"90%"}>
      <LeftPanel
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
      />
      <Box
        sx={{
          border: ".05px solid #7e96a3",
        }}
      />

      {Object.keys(selectedDriver).length > 0 ? (
        <RightPanel
          messages={messages}
          message={message}
          setMessage={setMessage}
          handleSendMessage={handleSendMessage}
          selectedDriver={selectedDriver}
        />
      ) : (
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          backgroundColor="#f5f5f5"
        >
          <img
            src={BgImg}
            alt="No Chat"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
          <Typography>{t("chatText")}</Typography>
        </Box>
      )}
    </Box>
  );
}
