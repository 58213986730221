import AlertDialog from "components/AlertDialog";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { makeStyles } from "@mui/styles";
import { MAIN_COLOR } from "common/sharedFunctions";
import { SECONDORY_COLOR } from "common/sharedFunctions";
import { generateInvoiceNumber } from "common/sharedFunctions";
import SelectorCombo from "components/SelectorCombo";
import logoimage from "assets/img/logo138x75white.png"

import { generateInvoicePDF } from "common/sharedFunctions";




const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_1: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 8
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_3: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 5
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_2: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField_rtl_2: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20,
    },
  },
  selectField_rtl_1: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
  },

  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 35,
    },
  },

  right: {
    textAlign: "right", right: 0, left: "auto", paddingRight: 40
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
}));

const AddInvoice = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const navigate = useNavigate();
    const companiesdata = useSelector(state => state.companiesdata?.companies || []);
    const [companyCombo, setCompanyCombo] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  


    const [data, setData] = useState({
      invoiceNote: "",
      invoiceNumber: generateInvoiceNumber('INV', 123),
      status: "UnPaid",
      name: "",
      address: "",
      uid: '',
      bookingId: '',
      amount: '',
    });
    const {addINVOICE} = api
    const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  
    useEffect(() => {
      if (companiesdata) {
        const companyOptions = companiesdata.map(company => ({
          name: company.name,
          mobile: company.phone,
          email: company.email,
          uid: company.id,
          address: `${company.address}\n${company.city}`,
          desc: `${company.name} ${company.email}`
        }));
        setCompanies(companyOptions);
      }
    }, [companiesdata]);
  
    useEffect(() => {
      if (companyCombo) {
        setData(prevData => ({
          ...prevData,
          name: companyCombo.name,
          address: companyCombo.address,
          uid: companyCombo.uid
        }));
      }
    }, [companyCombo]);
  
    const handleInputChange = (e) => {
      setData({ ...data, [e.target.id]: e.target.value });
    };
  
    const handleDownloadClick = (data) => {
      const invoiceData = {
        invoiceNumber: data.invoiceNumber,
        invoiceNote: data.invoiceNote,
        billTo: {
          name: data.name,
          address: data.address,
        },
        details: {
          invoiceDate: new Date().toDateString(),
          bookingId: data.bookingId,
          accountId: data.uid,
          invoiceNote: data.invoiceNote
        },
        paymentDetails: {
          accountName: 'Account Name',
          iban: 'ES34 3943 9283 9203 0293 0291',
          bicSwift: 'SWIFT324561',
          bankName: 'Nothing',
          bankAddress: 'Hello',
        },
        total: data.amount,
      };
      generateInvoicePDF(invoiceData, logoimage);
    };
  
    const handleCommonAlertClose = (e) => {
      e.preventDefault();
      setCommonAlert({ open: false, msg: "" });
    };
  
    const handleSubmit = async (data) => {
      setLoading(true)

      if (!data.name) {
        setCommonAlert({ open: true, msg: t("proper_input_name") });
        return;
      }
      if (!data.uid) {
        setCommonAlert({ open: true, msg: t("proper_input_accountId") });
        return;
      }
      if (!data.bookingId) {
        setCommonAlert({ open: true, msg: t("proper_input_bookingId") });
        return;
      }
      if (!data.address) {
        setCommonAlert({ open: true, msg: t("proper_address") });
        return;
      }
      try {
        dispatch(addINVOICE(data));
      
        handleDownloadClick(data)
        navigate('/invoices')
        setLoading(false)
        
      } catch (error) {
        console.log(error);
        setLoading(false)

        
      }


    };
  
    return loading ? (
      <CircularLoading />
    ) : (
      <div>

        <Card style={{ /* styling */ }}>
          <Typography variant="h5" style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}>
            {t("add_invoice")}
          </Typography>
          <Button variant="text" onClick={() => navigate("/invoices")}>
            <Typography style={{ fontWeight: "bold", color: MAIN_COLOR }}>
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
          <Grid container spacing={2} sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}>
            <Grid item xs={12}>
              {companies && (
                <SelectorCombo
                  className={classes.items}
                  placeholder={t('select_company')}
                  data={companies}
                  value={companyCombo}
                  onChange={(event, newValue) => setCompanyCombo(newValue)}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("invoice_number_label")}
                id="invoiceNumber"
                value={data.invoiceNumber}
                fullWidth
                className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
                onChange={handleInputChange}
              />
            </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label="Account Id"
              id="uid"
              value={data.uid}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("name")}
              id="name"
              value={data.name}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              type='number'
              label={t("amount")}
              id="amount"
              value={data.amount}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              
              label={t("booking_id")}
              id="bookingId"
              value={data.bookingId}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("billing_address")}
              id="address"
              value={data.address}
              multiline     
              rows={3}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t("invoice_Note_Lable")}
              id="invoiceNote"
              value={data.invoiceNote}
              multiline     
              rows={3}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
            />
          </Grid>


     
            



          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={5}
          >
            <Button
              style={{
                borderRadius: "19px",
                backgroundColor: MAIN_COLOR,
                minHeight: 50,
                marginBottom: 20,
                textAlign: "center",
                width: "50%"
              }}
              onClick={()=>{handleSubmit(data)}}
              variant="contained"
            >
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 16,
                }}
              >
                {t("submit")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default AddInvoice;
