import {
  FETCH_ESTIMATE,
  FETCH_ESTIMATE_SUCCESS,
  FETCH_ESTIMATE_FAILED,
  CLEAR_ESTIMATE,
  FETCH_TAXIMETERESTIMATE,
  FETCH_TAXIMETERESTIMATE_SUCCESS,
  FETCH_TAXIMETERESTIMATE_FAILED,
  CLEAR_TAXIMETERESTIMATE,
  FETCH_ESTIMATEBASEPRICE,
  FETCH_ESTIMATEBASEPRICE_SUCCESS,
  FETCH_ESTIMATEBASEPRICE_FAILED,
  CLEAR_ESTIMATEBASEPRICE,
} from "../store/types";
import Polyline from "@mapbox/polyline";
import { firebase } from "../config/configureFirebase";
import { onValue } from "firebase/database";
import {
  basePriceCalculate,
  FareCalculator,
  taxiMetercalculateFare,
} from "../other/FareCalculator";

/********************Estimate By Booking Data****************/

export const getEstimate = (bookingData) => async (dispatch) => {
  const { settingsRef } = firebase;

  dispatch({
    type: FETCH_ESTIMATE,
    payload: bookingData,
  });

  let res = bookingData.routeDetails;

  if (res) {
    let points = Polyline.decode(res.polylinePoints);
    let waypoints = points.map((point) => {
      return {
        latitude: point[0],
        longitude: point[1],
      };
    });

    onValue(
      settingsRef,
      (settingdata) => {
        let settings = settingdata.val();
        let distance = settings.convert_to_mile
          ? res.distance_in_km / 1.609344
          : res.distance_in_km;

        let { totalCost, grandTotal, convenience_fees } = FareCalculator(
          distance,
          res.time_in_secs,
          bookingData.carDetails,
          bookingData.instructionData,
          settings.decimal
        );
        dispatch({
          type: FETCH_ESTIMATE_SUCCESS,
          payload: {
            pickup: bookingData.pickup,
            drop: bookingData.drop,
            carDetails: bookingData.carDetails,
            instructionData: bookingData.instructionData,
            estimateDistance: parseFloat(distance).toFixed(settings.decimal),
            fareCost: totalCost
              ? parseFloat(totalCost).toFixed(settings.decimal)
              : 0,
            estimateFare: grandTotal
              ? parseFloat(grandTotal).toFixed(settings.decimal)
              : 0,
            estimateTime: res.time_in_secs,
            convenience_fees: convenience_fees
              ? parseFloat(convenience_fees).toFixed(settings.decimal)
              : 0,
            waypoints: waypoints,
          },
        });
      },
      { onlyOnce: true }
    );
  } else {
    dispatch({
      type: FETCH_ESTIMATE_FAILED,
      payload: "No Route Found",
    });
  }
};
export const clearEstimate = () => (dispatch) => {
  dispatch({
    type: CLEAR_ESTIMATE,
    payload: null,
  });
};

/********************Estimate By Taxi Meter****************/

export const getEstimateByTaxiMeter = (taxiMeterData) => async (dispatch) => {
  const { teaxiMetterRef } = firebase;

  dispatch({
    type: FETCH_TAXIMETERESTIMATE,
    payload: taxiMeterData,
  });
  const { routeDetails } = taxiMeterData;
  if (routeDetails) {
    try {
      onValue(
        teaxiMetterRef,
        (snapshot) => {
          const meterDataList = snapshot.val();
          if (meterDataList && Array.isArray(meterDataList)) {
            const meterData = meterDataList[1];
            const { totalFare, distanceFare, timeFare } =
              taxiMetercalculateFare(routeDetails, meterData);
            dispatch({
              type: FETCH_TAXIMETERESTIMATE_SUCCESS,
              payload: {
                estimateDistance: parseFloat(
                  routeDetails.distance_in_km
                ).toFixed(2),
                fareCost: distanceFare,
                estimateFare: totalFare,
                estimateTime: routeDetails.time_in_secs,
                timeFare: timeFare,
              },
            });
          } else {
            dispatch({
              type: FETCH_TAXIMETERESTIMATE_FAILED,
              payload: "No valid meter data found in Firebase",
            });
          }
        },
        { onlyOnce: true }
      );
    } catch (error) {
      dispatch({
        type: FETCH_TAXIMETERESTIMATE_FAILED,
        payload: error.message,
      });
    }
  } else {
    dispatch({
      type: FETCH_TAXIMETERESTIMATE_FAILED,
      payload: "No Route Found",
    });
  }
};
export const clearEstimateByTaxiMeter = () => (dispatch) => {
  dispatch({
    type: CLEAR_TAXIMETERESTIMATE,
    payload: null,
  });
};

/********************Estimate By Bae Price****************/

export const getEstimateByBasePrice = (basePriceData) => async (dispatch) => {
  const { basePriceRef } = firebase;

  dispatch({
    type: FETCH_ESTIMATEBASEPRICE,
    payload: basePriceData,
  });

  const { routeDetails } = basePriceData;
  if (routeDetails) {
    try {
      onValue(
        basePriceRef,
        (snapshot) => {
          const basePriceList = snapshot.val();
          if (basePriceList && Array.isArray(basePriceList)) {
            try {
              const { zoneName, totalFare, distanceFare, adjustedBasePrice } =basePriceCalculate(basePriceData);

              dispatch({
                type: FETCH_ESTIMATEBASEPRICE_SUCCESS,
                payload: {
                  zone: zoneName,
                  totalFare: totalFare,
                  distance: distanceFare,
                  adjustPrice: adjustedBasePrice,
                  routeDetails,
                },
              });
            } catch (calculationError) {
              dispatch({
                type: FETCH_ESTIMATEBASEPRICE_FAILED,
                payload: calculationError.message,
              });
            }
          } else {
            dispatch({
              type: FETCH_ESTIMATEBASEPRICE_FAILED,
              payload: "No valid meter data found in Firebase",
            });
          }
        },
        { onlyOnce: true }
      );
    } catch (error) {
      dispatch({
        type: FETCH_ESTIMATEBASEPRICE_FAILED,
        payload: error.message,
      });
    }
  } else {
    dispatch({
      type: FETCH_ESTIMATEBASEPRICE_FAILED,
      payload: "No Route Found",
    });
  }
};

export const clearEstimateByBasePrice = () => (dispatch) => {
  dispatch({
    type: CLEAR_ESTIMATEBASEPRICE,
    payload: null,
  });
};
