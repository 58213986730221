import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { api } from "common";
import { MAIN_COLOR } from "common/sharedFunctions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const FlatTariffsAdd = ({ handleClose, editData, isEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addFlatTariffs, updateFlatTariffs } = api;
  const flatTariffData = useSelector(
    (state) => state.flattariffsdata.flattariffslist
  );
  const zoneData = useSelector((state) => state.tariffzone);
  const initialState = {
    id: flatTariffData.length + 1,
    name: "",
    from: "",
    to: "",
    return: false,
  };
  const [flatData, setFlatData] = useState(initialState);
  useEffect(() => {
    if (isEdit) {
      setFlatData({
        id: editData.id || flatData.length + 1,
        name: editData.name || "",
        from: editData.from || "",
        to: editData.to || "",
        return: editData.return || "",
      });
    } else {
      setFlatData(initialState);
    }
  }, [flatTariffData.length, isEdit]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFlatData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFlatData((prevData) => ({
      ...prevData,
      [name]: checked ? "Yes" : null,
    }));
  };

  const availableZones = zoneData?.tariffzones?.features?.filter(
    ({ properties: { name } }) =>
      !flatTariffData.some((item) => item.name.trim() === name.trim())
  );
  const editDataItem = { id: editData?.id, name: editData?.name || "" };
  const fromDropdownData = isEdit
    ? [...availableZones, editDataItem]
    : availableZones;

  const toDropdownData = isEdit
    ? [...availableZones, editDataItem]
    : availableZones?.filter(
        ({ properties: { name } }) => name !== flatData.from
      );

  const onSubmit = (e) => {
    e.preventDefault();
    const formattedData = {
      ...flatData,
      return: flatData.return ? "Yes" : "No",
    };
    if (isEdit && editData?.id) {
      const existingIndex = flatTariffData.findIndex(
        (data) => data.id === editData.id
      );
      if (existingIndex !== -1) {
        dispatch(updateFlatTariffs(editData.id, formattedData));
        toast.success(t("updated_successfully"));
      } else {
        toast.error(t("update_failed"));
      }
    } else {
      dispatch(addFlatTariffs(formattedData));
      toast.success(t("add_successfully"));
    }
    setFlatData(initialState);
    handleClose();
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: "100%", marginTop: "15px" }}
        justifyContent="start"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={t("name")}
            name="name"
            value={flatData.name}
            placeholder={t("name")}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <InputLabel id="zone_select">{t("from")}</InputLabel>
            <Select
              label={t("from")}
              name="from"
              value={flatData.from}
              onChange={handleChange}
            >
              {fromDropdownData?.map((data, index) => (
                <MenuItem key={index} value={data?.properties?.name}>
                  {data?.properties?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="zone_select">{t("to")}</InputLabel>
            <Select
              label={t("to")}
              name="to"
              value={flatData.to}
              onChange={handleChange}
            >
              {toDropdownData?.map((data, i) => (
                <MenuItem key={i} value={data?.properties?.name}>
                  {data?.properties?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={flatData.return === "Yes"}
                name="return"
                onChange={handleCheckboxChange}
              />
            }
            label={t("Return")}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Button
          sx={btnStyled}
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          <Typography
            style={{ color: "white", textAlign: "center", fontSize: 16 }}
          >
            {isEdit ? t("update_button") : t("save")}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default FlatTariffsAdd;
const btnStyled = {
  borderRadius: "100px",
  backgroundColor: MAIN_COLOR,
  minHeight: 25,
  marginBottom: "10px",
  textAlign: "center",
  width: {
    xs: "30%",
    md: "25%",
    lg: "22%",
  },
};
