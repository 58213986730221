import React, { useEffect, useRef, useState } from 'react'
import AddBookingsForOverview from './AddBookingsForOverview'
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api'
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import JobSummery from './JobsSummary';


function Overview() {
  const [mylocation, setMylocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const usersdata = useSelector((state) => state.usersdata);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const settingsdata = useSelector((state) => state.settingsdata);

  const [settings, setSettings] = useState({});

  const auth = useSelector((state) => state.auth);
  const cars = useSelector((state) => state.cartypes.cars);

  useEffect(() => {
    if (mylocation == null) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          setMylocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (error) => console.log(error)
      );
    }
  }, [mylocation]);
  useEffect(() => {
    if (settingsdata.settings) {
      setSettings(settingsdata.settings);
    }
  }, [settingsdata.settings]);


  useEffect(() => {
    if (usersdata.drivers && bookinglistdata.bookings) {
      const liveBookings = bookinglistdata.bookings.filter(
        (bkg) => bkg.status === "STARTED"
      );
      const drivers = usersdata.drivers;
      let locs = [];
      for (let i = 0; i < drivers.length; i++) {
        if (drivers[i].location) {
          let carImage =
            "https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png";
          let bookingRef = null;
          for (let j = 0; j < cars.length; j++) {
            if (cars[j].name === drivers[i].carType) {
              carImage = cars[j].image;
            }
          }
          for (let j = 0; j < liveBookings.length; j++) {
            if (liveBookings[j].driver === drivers[i].uid) {
              bookingRef = liveBookings[j].reference;
            }
          }
          locs.push({
            id: i,
            lat: drivers[i].location.lat,
            lng: drivers[i].location.lng,
            drivername: drivers[i].firstName + " " + drivers[i].lastName,
            carnumber: drivers[i].vehicleNumber,
            cartype: drivers[i].carType,
            carImage: carImage,
            bookingRef: bookingRef,
          });
        }
      }
      setLocations(locs);
    }
  }, [
    usersdata.drivers,
    auth.profile.usertype,
    auth.profile.uid,
    cars,
    bookinglistdata.bookings,
    settings,
  ]);


  const [leftWidth, setLeftWidth] = useState(33.33); // Initial widths
  const [middleWidth, setMiddleWidth] = useState(33.33);
  const [rightWidth, setRightWidth] = useState(33.33);

  const isResizing = useRef(false); // Track resizing state

  const handleMouseDown = (divider) => {
    isResizing.current = divider;

    const handleMouseMove = (e) => {
      if (!isResizing.current) return;

      const container = e.target.closest(".resizable-container");
      const containerWidth = container.offsetWidth;
      const xPos = e.clientX - container.getBoundingClientRect().left;

      let newLeftWidth, newMiddleWidth, newRightWidth;

      if (isResizing.current === "left") {
        newLeftWidth = (xPos / containerWidth) * 100;
        newMiddleWidth = 100 - newLeftWidth - rightWidth;

        if (newLeftWidth > 10 && newMiddleWidth > 10) {
          setLeftWidth(newLeftWidth);
          setMiddleWidth(newMiddleWidth);
        }
      } else if (isResizing.current === "right") {
        newMiddleWidth = (xPos / containerWidth) * 100 - leftWidth;
        newRightWidth = 100 - leftWidth - newMiddleWidth;

        if (newMiddleWidth > 10 && newRightWidth > 10) {
          setMiddleWidth(newMiddleWidth);
          setRightWidth(newRightWidth);
        }
      }
    };

    const handleMouseUp = () => {
      isResizing.current = false;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  return (
    <div
    className="resizable-container"
    style={{
      display: "flex",
      height: "90vh",
      width: "100%",
      userSelect: "none", // Prevent text selection during drag
    }}
  >
    {/* Left Column */}
    <div
      style={{
        flexBasis: `${leftWidth}%`,
        maxWidth: `${leftWidth}%`,
        minWidth: "10%",
        overflowY: "auto",
        borderRight: "1px solid #ccc",
      }}
    >
      <AddBookingsForOverview />
    </div>

    {/* Resizable Divider */}
    <div
      style={{
        width: "5px",
        cursor: "col-resize",
        backgroundColor: "#ddd",
      }}
      onMouseDown={() => handleMouseDown("left")}
    ></div>

    {/* Middle Column */}
    <div
      style={{
        flexBasis: `${middleWidth}%`,
        maxWidth: `${middleWidth}%`,
        minWidth: "10%",
        overflowY: "auto",
      }}
    >
        <GoogleMap
                zoom={10}
                center={mylocation}
                mapContainerStyle={{ height: `100%` }}
              >
                {locations.map((marker) => (
                  <Marker
                    position={{ lat: marker.lat, lng: marker.lng }}
                    key={marker.id}
                    icon={{
                      url: marker.carImage,
                      scaledSize: new window.google.maps.Size(35, 25),
                    }}
                  >
                    <InfoWindow
                      position={{ lat: marker.lat, lng: marker.lng }}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -32),
                      }}
                    >
                      <div>
                        {marker.drivername}
                        <br />
                        {marker.carnumber}
                      </div>
                    </InfoWindow>
                  </Marker>
                ))}
    </GoogleMap>
    </div>

    {/* Resizable Divider */}
    <div
      style={{
        width: "5px",
        cursor: "col-resize",
        backgroundColor: "#ddd",
      }}
      onMouseDown={() => handleMouseDown("right")}
    ></div>

    {/* Right Column */}
    <div
      style={{
        flexBasis: `${rightWidth}%`,
        maxWidth: `${rightWidth}%`,
        minWidth: "10%",
        overflowY: "auto",
        borderLeft: "1px solid #ccc",
      }}
    >
      <JobSummery />
    </div>
  </div>

  //   <Grid container spacing={2} >

  //   <Grid item xs={4} md={4} sx={{maxHeight:"90vh", overflowY:"auto"}}>
  //   <AddBookings />
  //   </Grid>

  //   <Grid item xs={4} md={4}>
    // <GoogleMap
    //             zoom={10}
    //             center={mylocation}
    //             mapContainerStyle={{ height: `100%` }}
    //           >
    //             {locations.map((marker) => (
    //               <Marker
    //                 position={{ lat: marker.lat, lng: marker.lng }}
    //                 key={marker.id}
    //                 icon={{
    //                   url: marker.carImage,
    //                   scaledSize: new window.google.maps.Size(35, 25),
    //                 }}
    //               >
    //                 <InfoWindow
    //                   position={{ lat: marker.lat, lng: marker.lng }}
    //                   options={{
    //                     pixelOffset: new window.google.maps.Size(0, -32),
    //                   }}
    //                 >
    //                   <div>
    //                     {marker.drivername}
    //                     <br />
    //                     {marker.carnumber}
    //                   </div>
    //                 </InfoWindow>
    //               </Marker>
    //             ))}
    // </GoogleMap>
  //   </Grid>

  //   <Grid item xs={4} md={4} sx={{maxHeight:"90vh", overflowY:"auto"}}>
  //   <JobSummery />
  //   </Grid>

  // </Grid>
  )
}

export default Overview

