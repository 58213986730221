import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  Box,
  Paper,
  IconButton,
  Modal,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MAIN_COLOR } from "../../common/sharedFunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TaxiMetterAdd from "./TaxiMetterAdd";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { api } from "common";

const TaxiMetter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { deleteTaxiMetter } = api;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setEditData(false);
  };
  const [editData, setEditData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const taxiMetterData = useSelector(
    (state) => state.taximetterdata.taximetterdatalist
  );
  const handleDelete = (id) => {
    dispatch(deleteTaxiMetter(id));
    toast.success(t("Deleted"));
  };
  const handleEdit = (id) => {
    setIsEdit(true);
    const selectedData = taxiMetterData.find((row) => row.id === id);
    if (selectedData) {
      setEditData(selectedData);
      handleOpen();
    }
  };

  return (
    <>
      <Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ color: MAIN_COLOR, fontSize: 25 }}>
            {t("Taxi_meter")}
          </Typography>
          <Button sx={btnStyled} onClick={handleOpen} variant="contained">
            <Typography
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 16,
              }}
            >
              {t("create")}
            </Typography>
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={tabelStyled}>{t("SR_No")}</TableCell>
                <TableCell sx={tabelStyled}>{t("name")}</TableCell>
                <TableCell sx={tabelStyled}>{t("startPrice")}</TableCell>
                <TableCell sx={tabelStyled}>{t("forFirst")}</TableCell>
                <TableCell sx={tabelStyled}>{t("distanceRate")}</TableCell>
                <TableCell sx={tabelStyled}>{t("distancePer_m")}</TableCell>
                <TableCell sx={tabelStyled}>{t("timeRate")}</TableCell>
                <TableCell sx={tabelStyled}>{t("timePer_s")}</TableCell>
                <TableCell sx={tabelStyled}>{t("tarrifRate")}</TableCell>
                <TableCell sx={tabelStyled}>{t("tarrifRate_s")}</TableCell>
                <TableCell sx={tabelStyled}>{t("freeTrafficRate")}</TableCell>
                <TableCell sx={tabelStyled}>{t("minimumPrice")}</TableCell>
                <TableCell sx={tabelStyled} align="right">
                {t("actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taxiMetterData && taxiMetterData.length > 0 ? (
                taxiMetterData.map((row, index) => (
                  <TableRow style={{ whiteSpace: "nowrap" }} key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.startPrice}
                    </TableCell>
                    <TableCell>{row.forFirst}</TableCell>
                    <TableCell>{row.distanceRate}</TableCell>
                    <TableCell>{row.distancePerM}</TableCell>
                    <TableCell>{row.timeRate}</TableCell>
                    <TableCell>{row.timePerS}</TableCell>
                    <TableCell>{row.tarrifRate}</TableCell>
                    <TableCell>{row.tarrifRateS}</TableCell>
                    <TableCell>{row.freeTrafficRate}</TableCell>
                    <TableCell>{row.minimumPrice}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Box style={{ whiteSpace: "nowrap" }}>
                  {t("No_data_available")}
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Modal
        sx={modelWrapper}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={3} sx={modelCard}>
          <IconButton onClick={handleClose} sx={crossIcon}>
            <CloseIcon />
          </IconButton>
          <Box>
            <TaxiMetterAdd
              handleClose={handleClose}
              editData={editData}
              isEdit={isEdit}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default TaxiMetter;

const btnStyled = {
  borderRadius: "100px",
  backgroundColor: MAIN_COLOR,
  minHeight: 30,
  marginBottom: "10px",
  textAlign: "center",
  width: {
    xs: "30%",
    sm: "28%",
    md: "20%",
    lg: "13%",
  },
};
const modelWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const modelCard = {
  px: 4,
  py: 3,
  maxWidth: "750px",
  maxHeight: "550px",
  height: "100%",
  width: "100%",
  borderRadius: "20px",
  position: "relative",
  overflow: "auto",
};
const crossIcon = {
  position: "absolute",
  top: 8,
  right: 8,
  color: "black",
  zIndex: 1300,
};
const tabelStyled = {
  background: "#DDDEEE",
  fontWeight: "bold",
  whiteSpace: "nowrap",
};
