import {
    FETCH_WAITRATE,
    FETCH_WAITRATE_SUCCESS,
    FETCH_WAITRATE_FAILED,
    EDIT_WAITRATE,
    EDIT_WAITRATE_SUCCESS,
    EDIT_WAITRATE_FAILED,
    ADD_WAITRATE,
    ADD_WAITRATE_SUCCESS,
    ADD_WAITRATE_FAILED,
    DELETE_WAITRATE,
    DELETE_WAITRATE_SUCCESS,
    DELETE_WAITRATE_FAILED,
  } from "../store/types";
  import { onValue, set} from "firebase/database";
  import { firebase } from "../config/configureFirebase";
  
  export const fetchWaitRate = () => (dispatch) => {
    const { waitRateRef } = firebase;
    dispatch({ type: FETCH_WAITRATE });
    try {
      onValue(waitRateRef, (snapshot) => {
        const data = snapshot.val() || {};
        const dataList = Object.values(data);
        dispatch({ type: FETCH_WAITRATE_SUCCESS, payload: dataList });
      });
    } catch (error) {
      dispatch({ type: FETCH_WAITRATE_FAILED, payload: error.message });
    }
  };
  
  export const addWaiteRate = (newData) => (dispatch, getState) => {
    const { waitRateRef } = firebase;
    dispatch({ type: ADD_WAITRATE });
    set(waitRateRef, [
      ...getState().waitratedata.waitratelist,
      newData,
    ])
      .then(() => {
        dispatch({
          type: ADD_WAITRATE_SUCCESS,
          payload: [newData],
        });
      })
      .catch((error) => {
        dispatch({ type: ADD_WAITRATE_FAILED, payload: error.message });
      });
  };
  
  export const updateWaitRate = (id, updatedData) => (dispatch, getState) => {
    const { waitRateRef } = firebase;
    dispatch({ type: EDIT_WAITRATE });
    const currentList = getState().waitratedata.waitratelist;
    const dataIndex = currentList.findIndex((data) => data.id === id);
    if (dataIndex !== -1) {
      const updatedDataList = [
        ...currentList.slice(0, dataIndex),
        updatedData,
        ...currentList.slice(dataIndex + 1),
      ];
      set(waitRateRef, updatedDataList)
        .then(() => {
          dispatch({
            type: EDIT_WAITRATE_SUCCESS,
            payload: updatedData,
          });
        })
        .catch((error) => {
          dispatch({
            type: EDIT_WAITRATE_FAILED,
            payload: error.message,
          });
        });
    } else {
      dispatch({
        type: EDIT_WAITRATE_FAILED,
        payload: `${error.message}`,
      });
    }
  };
  
  export const deleteWaitRate = (id) => (dispatch, getState) => {
    const { waitRateRef } = firebase;
    dispatch({ type: DELETE_WAITRATE });
    const currentData = getState();
    const currentDataList = currentData.waitratedata.waitratelist || [];
    const dataIndex = currentDataList.findIndex((data) => data.id === id);
    if (dataIndex !== -1) {
      const updatedData = [...currentDataList];
      updatedData.splice(dataIndex, 1);
      set(waitRateRef, updatedData)
        .then(() => {
          dispatch({
            type: DELETE_WAITRATE_SUCCESS,
            payload: updatedData,
          });
        })
        .catch((error) => {
          dispatch({
            type: DELETE_WAITRATE_FAILED,
            payload: error.message,
          });
        });
    } else {
      dispatch({
        type: DELETE_WAITRATE_FAILED,
        payload: `deleting failed`,
      });
    }
  };
  