import {
  FETCH_FLATTARIFFS,
  FETCH_FLATTARIFFS_SUCCESS,
  FETCH_FLATTARIFFS_FAILED,
  EDIT_FLATTARIFFS,
  EDIT_FLATTARIFFS_SUCCESS,
  EDIT_FLATTARIFFS_FAILED,
  ADD_FLATTARIFFS,
  ADD_FLATTARIFFS_SUCCESS,
  ADD_FLATTARIFFS_FAILED,
  DELETE_FLATTARIFFS,
  DELETE_FLATTARIFFS_SUCCESS,
  DELETE_FLATTARIFFS_FAILED,
} from "../store/types";

const INITIAL_STATE = {
  flattariffslist: [],
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};
export const flattariffsreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FLATTARIFFS:
      return {
        ...state,
        loading: true,
        error: { flag: false, msg: null },
      };
    case FETCH_FLATTARIFFS_SUCCESS:
      return {
        ...state,
        flattariffslist: action.payload || [],
        loading: false,
        error: { flag: false, msg: null },
      };
    case FETCH_FLATTARIFFS_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case EDIT_FLATTARIFFS:
      return {
        ...state,
        loading: true,
      };
    case EDIT_FLATTARIFFS_SUCCESS:
      return {
        ...state,
        flattariffslist: [...action.payload],
        loading: false,
      };
    case EDIT_FLATTARIFFS_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case ADD_FLATTARIFFS:
      return {
        ...state,
        loading: true,
      };
    case ADD_FLATTARIFFS_SUCCESS:
      return {
        ...state,
        flattariffslist: [...state.flattariffslist],
        loading: false,
      };
    case ADD_FLATTARIFFS_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case DELETE_FLATTARIFFS:
      return { ...state, loading: true };

    case DELETE_FLATTARIFFS_SUCCESS:
      return {
        ...state,
        flattariffslist: action.payload,
        loading: false,
      };

    case DELETE_FLATTARIFFS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
