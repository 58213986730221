// import AlertDialog from "../components/AlertDialog";

// import React, { useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { api } from "common";
// import {  useDispatch } from "react-redux";
// import {
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Card,
//   CardActions,
//   Paper,
//   List,
//   Modal,
//   ListItem,
//   ListItemText,
//   CardContent,
// } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import { makeStyles } from "@mui/styles";
// import AddMaps from "./AddMaps";
// import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";

// const useStyles = makeStyles((theme) => ({
//   textField: {
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//   },
//   selectField: {
//     color: "black",
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: MAIN_COLOR,
//     },
//   },
//   rootRtl: {
//     "& label": {
//       right: 25,
//       left: "auto",
//       paddingRight: 12,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 15,
//     },
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//   },
//   rootRtl_1: {
//     "& label": {
//       right: 25,
//       left: "auto",
//       paddingRight: 12,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 18,
//     },
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//   },
//   selectField_rtl: {
//     color: "black",
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: MAIN_COLOR,
//     },
//     "& label": {
//       right: 50,
//       left: "auto",
//       paddingRight: 12,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 25,
//     },
//   },
//   selectField_rtl_1: {
//     color: "black",
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: MAIN_COLOR,
//     },
//     "& label": {
//       right: 50,
//       left: "auto",
//       paddingRight: 12,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 27,
//     },
//   },

//   right: {
//     textAlign: "right",
//     right: 0,
//     left: "auto",
//     paddingRight: 40,
//   },
// }));

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// const TariffZoneTab = () => {
//   const { id } = useParams;

//   const { addZone } = api;
//   const dispatch = useDispatch();

//   const [taxiRankChecked, settaxiRankChecked] = useState(false);



//   const { t, i18n } = useTranslation();
//   const isRTL = i18n.dir();

//   const [zoneSearchOptions, setzoneSearchOptions] = useState([
//     { value: "unrestricted", label: t("unrestricted") },
//     { value: "zone_chain", label: t("zone_chain") },
//     { value: "500m", label: t("500m") },
//     { value: "1km", label: t("1km") },
//     { value: "1.5km", label: t("1_dot_5km") },
//     { value: "2km", label: t("2km") },
//     { value: "3km", label: t("3km") },
//     { value: "5km", label: t("5km") },
//     { value: "10km", label: t("10km") },
//     { value: "15km", label: t("15km") },
//     { value: "20km", label: t("20km") },
//     { value: "30km", label: t("30km") },
//     { value: "40km", label: t("40km") },
//     { value: "50km", label: t("50km") },
//   ]);

//   const [initialGeoJson, setinitialGeoJson] = useState({
//     // type: "FeatureCollection",
//     // features: [
//     //   {
//     //     type: "Feature",
//     //     geometry: {
//     //       type: "Polygon",
//     //       coordinates: [
//     //         [
//     //           [-0.10680699629024738, 51.51501936537182],
//     //           [-0.10020145735727183, 51.51424486666575],
//     //           [-0.10140246443600011, 51.51087965041714],
//     //           [-0.10582045476129844, 51.50954417824554],
//     //           [-0.1103242313065045, 51.51133370203776],
//     //           [-0.1103242313065045, 51.51309644779345],
//     //         ],
//     //       ],
//     //     },
//     //     properties: {},
//     //   },
//     // ],
//   });

//   const [names, setNames] = useState([
//     "Alice",
//     "Bob",
//     "Charlie",
//     "David",
//     "Eve",
//   ]);

//   const navigate = useNavigate();
//   const [parentType, setParentType] = useState("");
//   const [zoneAssignmentSearchType, setZoneAssignmentSearchType] = useState("");
//   const [zoneId, setZoneId] = useState("");
//   const [zoneCode, setZoneCode] = useState("");
//   const [zoneName, setZoneName] = useState("");
//   const [pickupDropoff, setPickupDropoff] = useState("pickup");
//   const [zoneOrderdOptions, setZoneOrderdOptions] = useState([]);

//   const [mapData, setMapData] = useState(null);

//   const [addMapTariff, setAddMapTariff] = React.useState(false);
//   const handleOpenAddMapTariff = () => setAddMapTariff(true);
//   const handleCloseAddMapTariff = () => setAddMapTariff(false);


//   const handleMapData = (data) => {
//     setMapData(data);
//   };

//   const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
//   const classes = useStyles();

//   const handleCommonAlertClose = (e) => {
//     e.preventDefault();
//     setCommonAlert({ open: false, msg: "" });
//   };

//   const handelChangeParentType = (event) => {
//     setParentType(event.target.value);
//   };

//   const handelChangeZoneSearchType = (event) => {
//     setZoneAssignmentSearchType(event.target.value);
//   };

//   const handleZoneIdChange = (e) => {
//     setZoneId(e.target.value);
//   };
//   const handleZoneCodeChange = (e) => {
//     setZoneCode(e.target.value);
//   };
//   const handleZoneNameChange = (e) => {
//     setZoneName(e.target.value);
//   };

//   const handlePickDropChange = (event) => {
//     setPickupDropoff(event.target.value);
//   };

//   const handleZoneOrderChange = (event, newValue) => {
//     setZoneOrderdOptions(newValue);
//   };

//   const handleDeleteOption = (optionToDelete) => () => {
//     setZoneOrderdOptions((prevOptions) =>
//       prevOptions.filter((option) => option !== optionToDelete)
//     );
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const data = {
//       zoneId,
//       zoneCode,
//       zoneName,
//       parentType,
//       pickupDropoff,
//       zoneAssignmentSearchType,
//       zoneOrderdOptions,
//       taxiRankChecked,
//       mapData: { data: mapData, name: zoneName },
//     };
//     dispatch(addZone(data));
//   };
//   return (
//     <div>
//       <Box sx={{ display: "flex", height: "100vh" }}>
//         <Box
//           sx={{
//             width: "300px",
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             p: 2,
//             borderRight: "1px solid #ccc",
//           }}
//         >
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Names
//           </Typography>
//           <Paper sx={{ width: "100%", mb: 2 }}>
//             <List>
//               {names.map((name, index) => (
//                 <ListItem key={index}>
//                   <ListItemText primary={`${index + 1}. ${name}`} />
//                 </ListItem>
//               ))}
//             </List>
//           </Paper>
//           <Box
//             sx={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "space-between",
//             }}
//           >
//             <Button
//               style={{
//                 borderRadius: "19px",
//                 backgroundColor: MAIN_COLOR,
//                 minHeight: 40,
//                 marginBottom: 20,
//                 textAlign: "center",
//                 width: "40%",
//               }}
//               onClick={handleOpenAddMapTariff}
//               variant="contained"
//             >
//               <Typography
//                 style={{
//                   color: "white",
//                   textAlign: "center",
//                   fontSize: 12,
//                 }}
//               >
//                 {t("add_zones_title")}
//               </Typography>
//             </Button>

//             <Button
//               style={{
//                 borderRadius: "19px",
//                 backgroundColor: MAIN_COLOR,
//                 minHeight: 40,
//                 marginBottom: 20,
//                 textAlign: "center",
//                 width: "48%",
//               }}
//               variant="contained"
//             >
//               <Typography
//                 style={{
//                   color: "white",
//                   textAlign: "center",
//                   fontSize: 12,
//                 }}
//               >
//                 {t("importexport")}
//               </Typography>
//             </Button>
//           </Box>
//         </Box>
//         <Box sx={{ flex: 1, p: 2 }}>
//           <AddMaps
//             onMapDataChange={handleMapData}
//             initialGeoJson={initialGeoJson}
//           />
//         </Box>  
//       </Box>
//       {/* <Card
//         style={{
//           borderRadius: "19px",
//           backgroundColor: "#fff",
//           minHeight: 100,
//           maxWidth: "75vw",
//           marginTop: 20,
//           marginBottom: 20,
//           padding: 25,
//           alignItems: "center",
//           justifyContent: "center",
//           boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
//         }}
//       >
//         <Typography
//           variant="h5"
//           style={{
//             marginTop: -15,
//             textAlign: isRTL === "rtl" ? "right" : "left",
//           }}
//         >
//           {t("add_zones_title")}
//         </Typography>
//         <div dir={isRTL === "rtl" ? "rtl" : "ltr"}>
//           <Button
//             variant="text"
//             onClick={() => {
//               navigate("/zones");
//             }}
//           >
//             <Typography
//               style={{
//                 marginBottom: 10,
//                 textAlign: isRTL === "rtl" ? "right" : "left",
//                 fontWeight: "bold",
//                 color: MAIN_COLOR,
//               }}
//             >
//               {`<<- ${t("go_back")}`}
//             </Typography>
//           </Button>
//         </div>
//         <Grid
//           container
//           spacing={2}
//           sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
//         >
//           <Grid item xs={12} md={4}>
//             <Card>
//               <CardContent>
//                 <form onSubmit={handleSubmit}>
//                 <Grid container spacing={2}>

//                   <Grid item xs={12}>
//                     <TextField
//                       label={t("id")}
//                       id="id"
//                       value={zoneId}
//                       fullWidth
//                       required
//                       onChange={handleZoneIdChange}
//                       className={
//                         isRTL === "rtl" ? classes.rootRtl_1 : classes.textField
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       label={t("code")}
//                       id="code"
//                       value={zoneCode}
//                       fullWidth
//                       required
//                       onChange={handleZoneCodeChange}
//                       className={
//                         isRTL === "rtl" ? classes.rootRtl : classes.textField
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       label={t("name")}
//                       id="name"
//                       value={zoneName}
//                       fullWidth
//                       required
//                       onChange={handleZoneNameChange}
//                       className={
//                         isRTL === "rtl" ? classes.rootRtl : classes.textField
//                       }
//                     />
//                   </Grid>

//                   <Grid item xs={12}>
//                     <FormControl
//                       fullWidth
//                       style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
//                     >
//                       <InputLabel
//                         id="parent_type_select"
//                         sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
//                         className={isRTL === "rtl" ? classes.right : ""}
//                       >
//                         {t("parent_type_select")}
//                       </InputLabel>
//                       <Select
//                         labelId="parent_type_select"
//                         id="parent_type_select"
//                         value={parentType}
//                         label={t("parent_type")}
//                         onChange={handelChangeParentType}
//                         className={
//                           isRTL === "rtl"
//                             ? classes.selectField_rtl
//                             : classes.selectField
//                         }
//                       ></Select>
//                     </FormControl>
//                   </Grid>
//                   <Grid
//                     container
//                     justifyContent="center"
//                     alignItems="center"
//                     marginTop={3}
//                   >
//                     <Grid item xs={11} textAlign="left">
//                       <Typography style={{ color: MAIN_COLOR, fontSize: 25 }}>
//                         {t("advance_label")}
//                       </Typography>
//                       <Divider
//                         style={{
//                           marginTop: 10,
//                           marginBottom: 10,
//                           borderWidth: "1px",
//                           backgroundColor: "grey",
//                         }}
//                       />
//                     </Grid>
//                   </Grid>
//                   <Grid
//                     container
//                     justifyContent="center"
//                     alignItems="center"
//                     marginTop={1}
//                   >
//                     <Grid item xs={11} textAlign="left">
//                       <Typography style={{ color: "grey", fontSize: 15 }}>
//                         {t("advance_zone_text")}
//                       </Typography>
//                     </Grid>
//                   </Grid>

//                   <Grid
//                     container
//                     justifyContent="center"
//                     alignItems="center"
//                     marginTop={1}
//                   >
//                     <FormControl component="fieldset">
//                       <RadioGroup
//                         aria-label="pickup-dropoff"
//                         name="pickup-dropoff"
//                         value={pickupDropoff}
//                         onChange={handlePickDropChange}
//                         row
//                       >
//                         <FormControlLabel
//                           value="pickup"
//                           control={<Radio />}
//                           label={t("pickup_label")}
//                         />
//                         <FormControlLabel
//                           value="dropoff"
//                           control={<Radio />}
//                           label={t("dropoff_label")}
//                           marginLeft={2}
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   </Grid>

//                   <Grid
//                     container
//                     justifyContent="center"
//                     alignItems="center"
//                     marginTop={1}
//                   >
//                     <Grid item xs={11} textAlign="left">
//                       <Typography style={{ color: "grey", fontSize: 15 }}>
//                         {t("zone_assignment_type_label")}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <FormControl
//                       fullWidth
//                       style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
//                     >
//                       <InputLabel
//                         id="zone_assignment_search_select"
//                         sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
//                         className={isRTL === "rtl" ? classes.right : ""}
//                       >
//                         {t("zone_assignment_type")}
//                       </InputLabel>
//                       <Select
//                         labelId="zone_assignment_search_type_select"
//                         id="zone_assignment_search_type_select"
//                         value={zoneAssignmentSearchType}
//                         label={t("zone_assignment_type")}
//                         onChange={handelChangeZoneSearchType}
//                         className={
//                           isRTL === "rtl"
//                             ? classes.selectField_rtl
//                             : classes.selectField
//                         }
//                       >
//                         {zoneSearchOptions.map((option) => (
//                           <MenuItem
//                             key={option.value}
//                             value={option.value}
//                             style={{
//                               direction: isRTL === "rtl" ? "rtl" : "ltr",
//                             }}
//                           >
//                             {option.label}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>

//                   <Grid
//                     container
//                     justifyContent="center"
//                     alignItems="center"
//                     marginTop={1}
//                   >
//                     <Grid item xs={11} textAlign="left">
//                       <Typography style={{ color: "grey", fontSize: 15 }}>
//                         {t("zone_order_type_label")}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Grid item xs={12}>
//                   <FormControl fullWidth>
//                     <Autocomplete
//                       multiple
//                       id="multi-select"
//                       options={['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']}
//                       value={zoneOrderdOptions}
//                       onChange={handleZoneOrderChange}
//                       renderInput={(params) => (
//                         <TextField {...params} variant="outlined" label="Select options" />
//                       )}
//                     />
//                     <Box sx={{ mt: 2 }}>
//                       {zoneOrderdOptions.map((option, index) => (
//                         <Box key={option} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
//                           <Chip
//                             label={option}
//                             onDelete={handleDeleteOption(option)}
//                             deleteIcon={<ClearIcon />}
//                             variant="outlined"
//                           />
//                         </Box>
//                       ))}
//                     </Box>
//                   </FormControl>
//                   </Grid>

//                   <Grid
//                     item
//                     xs={12}
//                     display="flex"
//                     justifyContent="center"
//                     alignItems="center"
//                     marginTop={3}
//                   >
//                     <FormControlLabel
//                       control={
//                         <Switch
//                           checked={taxiRankChecked}
//                           onChange={handleTaxiRankChange}
//                         />
//                       }
//                     />
//                     <Typography variant="body1">
//                       {t("taxi_rank_label")}{" "}
//                       <strong>
//                         {taxiRankChecked
//                           ? t("taxi_ranked_label")
//                           : t("taxi_not_ranked_label")}
//                       </strong>
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     xs={12}
//                     display="flex"
//                     justifyContent="center"
//                     alignItems="center"
//                     marginTop={3}
//                   >
//                     <Button
//                       style={{
//                         borderRadius: "19px",
//                         backgroundColor: MAIN_COLOR,
//                         minHeight: 50,
//                         marginBottom: 20,
//                         textAlign: "center",
//                         width: "50%",
//                       }}
//                       type="submit"
//                       variant="contained"
//                     >
//                       <Typography
//                         style={{
//                           color: "white",
//                           textAlign: "center",
//                           fontSize: 16,
//                         }}
//                       >
//                         {t("submit")}
//                       </Typography>
//                     </Button>
//                   </Grid>
//                 </Grid>
//                   </form>
//               </CardContent>
//             </Card>
//           </Grid>
//           <Grid item xs={12} md={8}>
//             <Card>
//               <CardContent>
//                 <AddMaps onMapDataChange={handleMapData} initialGeoJson={initialGeoJson} />
//               </CardContent>
//             </Card>
//           </Grid>
//         </Grid>
//       </Card> */}
//       <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
//         {commonAlert.msg}
//       </AlertDialog>
//       <Modal
//         open={addMapTariff}
//         onClose={handleCloseAddMapTariff}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>

//           <Card>
//             <CardContent>
//               <TextField
//                 autoFocus
//                 margin="dense"
//                 label="Name"
//                 type="text"
//                 fullWidth
//                 // value={input1}
//                 // onChange={(e) => setInput1(e.target.value)}
//               />
//               <TextField
//                 margin="dense"
//                 label="Code"
//                 type="text"
//                 fullWidth
//                 // value={input2}
//                 // onChange={(e) => setInput2(e.target.value)}
//               />
//               <TextField
//                 margin="dense"
//                 label="ID"
//                 type="text"
//                 fullWidth
//                 // value={input3}
//                 // onChange={(e) => setInput3(e.target.value)}
//               />
//             </CardContent>
//             <CardActions>
//               <Button  color="primary">
//                 Save
//               </Button>
//               <Button onClick={handleCloseAddMapTariff} color="secondary">
//                 Cancel
//               </Button>
//             </CardActions>
//           </Card>
          
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default TariffZoneTab;
import React from 'react'
import TariffMapWithDrawing from './TariffZones'

function TariffZoneTab() {
  return (
    <TariffMapWithDrawing />
    
  )
}

export default TariffZoneTab