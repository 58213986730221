import React, { useState, useEffect } from 'react';

import { useTranslation } from "react-i18next";
import MaterialTable from 'material-table';
import { useDispatch, useSelector } from "react-redux";
import CircularLoading from 'components/CircularLoading';
import {colors} from '../components/Theme/WebTheme';
import { downloadCsv } from '../common/sharedFunctions';
import {SECONDORY_COLOR } from "../common/sharedFunctions";
import { useNavigate } from 'react-router-dom';
import { deleteCompany } from 'common/src/actions/companiesactions';

export default function Companies(props) {
    const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const isRTL = i18n.dir();
 
  
  const [data, setData] = useState();
  const companiesdata = useSelector(state => state.companiesdata);
  const settings = useSelector(state => state.settingsdata.settings);



  const columns = [
    { title: t('name'), field: 'name',  
  },
    {
      title: t('phone'),
      field: 'phone',
      
    },
    {
      title: t('email'),
      field: 'email',
     
    }
  ];

  useEffect(() => {

    if (companiesdata.companies) {
      setData(companiesdata.companies);
    } else {
      setData([]);
    }
  }, [companiesdata.companies]);
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    companiesdata.loading?
      <CircularLoading /> :
      <div>
        <MaterialTable
          title={t('companies_title')}
          columns={columns}
          style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`}}
          data={data}
          onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 15, 20],
            exportCsv: (columns, data) => {
              let hArray = [];
              const headerRow = columns.map(col => {
                if (typeof col.title === 'object') {
                  return col.title.props.text;
                }
                hArray.push(col.field);
                return col.title;
              });
              const dataRows = data.map(({ tableData, ...row }) => {
                row.createdAt = new Date(row.createdAt).toLocaleDateString() + ' '+ new Date(row.createdAt).toLocaleTimeString()
                let dArr = [];
                for(let i=0;i< hArray.length; i++) {
                  dArr.push(row[hArray[i]]);
                }
                return Object.values(dArr);
              })
              const { exportDelimiter } = ",";
              const delimiter = exportDelimiter ? exportDelimiter : ",";
              const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
              const csvFileName = 'download.csv';
              downloadCsv(csvContent, csvFileName);
            },
            exportButton: {
              csv: settings.AllowCriticalEditsAdmin,
              pdf: false,
            },
            maxColumnSort: "all_columns",
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
                 border: "1px solid rgba(224, 224, 224, 1)",
            }),
            editable:{
              backgroundColor: colors.Header_Text,
              fontSize: "0.8em",
              fontWeight: 'bold ',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            },
            headerStyle: {
              position: "sticky",
              top: "0px",
              fontSize: "0.8em",
              fontWeight: 'bold ',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              color: colors.BLACK,
              backgroundColor: SECONDORY_COLOR,
              textAlign: "center",
              border: "1px solid rgba(224, 224, 224, 1)",
            },
            cellStyle: {
              border: "1px solid rgba(224, 224, 224, 1)",
              textAlign: "center",
            },
            actionsColumnIndex: -1,
            
          }}
          editable={{
            onRowDelete: (oldData) =>
              settings.AllowCriticalEditsAdmin
                ? new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      dispatch(deleteCompany(oldData.id))
                     
                    }, 600);
                  })
                : new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      alert(t("demo_mode"));
                    }, 600);
                  }),
          }}
          actions={[
            {
                icon: "add",
                tooltip: t("add_customer"),
                isFreeAction: true,
                onClick: (event) => navigate("/companies/addcompany"),
            },
            {
              icon: "edit",
              tooltip: t("profile_page_subtitle"),
              onClick: (event, rowData) => {
                navigate(`/companies/editcompany/${rowData.id}`);
                
              },
            },
            {
              icon: "info",
              tooltip: t("profile_page_subtitle"),
              onClick: (event, rowData) => {
                navigate(`/companies/companydetails/${rowData.id}`);
                
              },
            },
            
          
          ]}
       
        />
      
      </div>
  );
}
