import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "common";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete, Box, Chip,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  Divider,
  CardContent,
  Switch,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import AddMaps from "./AddMaps";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
  rootRtl: {
    "& label": {
      right: 25,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_1: {
    "& label": {
      right: 25,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
  },
  selectField_rtl_1: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 27,
    },
  },

  right: {
    textAlign: "right",
    right: 0,
    left: "auto",
    paddingRight: 40,
  },
}));

const AddZones = () => {
  const { id } = useParams;

  const { addZone } = api;
  const dispatch = useDispatch();


  const [taxiRankChecked, settaxiRankChecked] = useState(false);

  const handleTaxiRankChange = (event) => {
    settaxiRankChecked(event.target.checked);
  };

  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();

  const [zoneSearchOptions, setzoneSearchOptions] = useState([
    { value: "unrestricted", label: t("unrestricted") },
    { value: "zone_chain", label: t("zone_chain") },
    { value: "500m", label: t("500m") },
    { value: "1km", label: t("1km") },
    { value: "1.5km", label: t("1_dot_5km") },
    { value: "2km", label: t("2km") },
    { value: "3km", label: t("3km") },
    { value: "5km", label: t("5km") },
    { value: "10km", label: t("10km") },
    { value: "15km", label: t("15km") },
    { value: "20km", label: t("20km") },
    { value: "30km", label: t("30km") },
    { value: "40km", label: t("40km") },
    { value: "50km", label: t("50km") },
  ]);

  const [initialGeoJson, setinitialGeoJson] = useState({
    // type: "FeatureCollection",
    // features: [
    //   {
    //     type: "Feature",
    //     geometry: {
    //       type: "Polygon",
    //       coordinates: [
    //         [
    //           [-0.10680699629024738, 51.51501936537182],
    //           [-0.10020145735727183, 51.51424486666575],
    //           [-0.10140246443600011, 51.51087965041714],
    //           [-0.10582045476129844, 51.50954417824554],
    //           [-0.1103242313065045, 51.51133370203776],
    //           [-0.1103242313065045, 51.51309644779345],
    //         ],
    //       ],
    //     },
    //     properties: {},
    //   },
    // ],
  });


  const navigate = useNavigate();
  const [parentType, setParentType] = useState("");
  const [zoneAssignmentSearchType, setZoneAssignmentSearchType] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [zoneCode, setZoneCode] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [pickupDropoff, setPickupDropoff] = useState("pickup");
  const [zoneOrderdOptions, setZoneOrderdOptions] = useState([]);

  const [mapData, setMapData] = useState(null);

  const handleMapData = (data) => {
    setMapData(data);
  };

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const classes = useStyles();

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handelChangeParentType = (event) => {
    setParentType(event.target.value);
  };

  const handelChangeZoneSearchType = (event) => {
    setZoneAssignmentSearchType(event.target.value);
  };

  const handleZoneIdChange = (e) => {
    setZoneId(e.target.value);
  };
  const handleZoneCodeChange = (e) => {
    setZoneCode(e.target.value);
  };
  const handleZoneNameChange = (e) => {
    setZoneName(e.target.value);
    
  };

  const handlePickDropChange = (event) => {
    setPickupDropoff(event.target.value);
  };

  
    const handleZoneOrderChange = (event, newValue) => {
      setZoneOrderdOptions(newValue);
    };
  
    const handleDeleteOption = (optionToDelete) => () => {
      setZoneOrderdOptions((prevOptions) =>
        prevOptions.filter((option) => option !== optionToDelete)
      );
    };
  

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log("Zone ID: ",zoneId)
    // console.log("Zone Code: ",zoneCode)
    // console.log("Zone name: ",zoneName)
    // console.log("Parent type: ",parentType)
    // console.log("Pick drop type: ",pickupDropoff)
    // console.log("search type: ",zoneAssignmentSearchType)
    // console.log("Zone orders: ",zoneOrderdOptions)
    // console.log("Taxi rank: ",taxiRankChecked)

    // console.log(mapData)

    const data = {
      zoneId,
      zoneCode,
      zoneName,
      parentType,
      pickupDropoff,
      zoneAssignmentSearchType,
      zoneOrderdOptions,
      taxiRankChecked,
      mapData:{data:mapData,name:zoneName}
    }
    dispatch(addZone(data))

  };
  return (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: "#fff",
          minHeight: 100,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 25,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
      >
        <Typography
          variant="h5"
          style={{
            marginTop: -15,
            textAlign: isRTL === "rtl" ? "right" : "left",
          }}
        >
          {t("add_zones_title")}
        </Typography>
        <div dir={isRTL === "rtl" ? "rtl" : "ltr"}>
          <Button
            variant="text"
            onClick={() => {
              navigate("/zones");
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div>
        <Grid
          container
          spacing={2}
          sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >

          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                
                <AddMaps onMapDataChange={handleMapData} initialGeoJson={[]} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default AddZones;
