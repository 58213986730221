import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, DrawingManager } from "@react-google-maps/api";
import {
  Button,
  TextField,
  List,
  ListItem,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { api } from "common";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const center = {
  lat: 56.6874162,
  lng: 16.2453895,
};

const MapWithDrawing = () => {
  const [map, setMap] = useState(null);
  const dispatch = useDispatch();
  const [drawingManager, setDrawingManager] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [polygonCounter, setPolygonCounter] = useState(1);
  const [markers, setMarkers] = useState([]);
  const [polygonList, setPolygonList] = useState([]);
  const [selectedPolygonId, setSelectedPolygonId] = useState(null);
  const [editingPolygon, setEditingPolygon] = useState(null);
  const { addSheduleZone, updateSheduleZoneCoordinates } = api;
  const geoJson = useSelector((state) => state.zonedata);

  const [showForm, setShowForm] = useState(false);
  const [formValues, setFormValues] = useState({
    id: null,
    code: "",
    name: "",
  });
  const [currentPolygonInstance, setCurrentPolygonInstance] = useState(null);

  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  // const onPolygonComplete = (polygonInstance) => {
  //   console.log(polygonInstance);

  //   const newPolygonId = polygonCounter;
  //   setShowForm(true); // Show form when a new polygon is drawn
  //   setCurrentPolygonInstance(polygonInstance); // Store the current polygon instance

  //   setFormValues({
  //     id: newPolygonId,
  //     code: "",
  //     name: "",
  //   });

  //   polygonInstance.setEditable(true);
  //   polygonInstance.setOptions({
  //     fillColor: "lightblue",
  //     strokeColor: "blue",
  //     strokeWeight: 2,
  //   });

  //   setPolygonCounter((prevCounter) => prevCounter + 1);

  //   if (drawingManager) {
  //     drawingManager.setDrawingMode(null);
  //   }
  // };
  const onPolygonComplete = (polygonInstance) => {
    const newPolygonId = polygonCounter;
    setShowForm(true); // Show form when a new polygon is drawn
    setCurrentPolygonInstance(polygonInstance); // Store the current polygon instance

    setFormValues({
      id: newPolygonId,
      code: "",
      name: "",
    });

    polygonInstance.setEditable(true);
    polygonInstance.setOptions({
      fillColor: "lightblue",
      strokeColor: "blue",
      strokeWeight: 2,
    });

    setPolygonCounter((prevCounter) => prevCounter + 1);

    if (drawingManager) {
      drawingManager.setDrawingMode(null);
    }
  };

  const handlePolygonShapeChange = (polygonInstance, polygonId) => {
    // Extract the new coordinates from the polygon instance
    const paths = polygonInstance
      .getPath()
      .getArray()
      .map((point) => [point.lng(), point.lat()]); // Ensure to format [lng, lat] for GeoJSON

    console.log("Polygon shape changed. New path:", paths);

    // Dispatch the action to update Firebase and Redux
    dispatch(updateSheduleZoneCoordinates(polygonId, paths));
  };

  const handlePolygonSelect = (polygonId) => {
    setSelectedPolygonId(polygonId);

    // Find the selected polygon
    const selectedPolygon = polygons.find(
      (polygon) => polygon.id === polygonId
    );
    if (selectedPolygon) {
      // Get the path of the polygon and create bounds
      const path = selectedPolygon.instance.getPath();
      const bounds = new window.google.maps.LatLngBounds();

      // Extend the bounds to include all the points of the polygon
      path.forEach((latLng) => bounds.extend(latLng));

      // Center the map to the polygon
      map.fitBounds(bounds); // Adjust the map to fit the bounds of the polygon

      // Update the polygon styles for selection
      polygons.forEach((polygon) => {
        const color = polygon.id === polygonId ? "orange" : "lightblue";
        polygon.instance.setOptions({
          fillColor: color,
          strokeColor: polygon.id === polygonId ? "red" : "blue",
        });
      });
    }
  };

  const addLabelToPolygon = (polygonInstance, number) => {
    const bounds = new window.google.maps.LatLngBounds();
    polygonInstance.getPath().forEach((latLng) => bounds.extend(latLng));
    const labelPosition = bounds.getCenter();
    const marker = new window.google.maps.Marker({
      position: labelPosition,
      map: map,
      label: {
        text: number.toString(),
        color: "black",
        fontWeight: "bold",
        fontSize: "20px",
      },
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "transparent",
        fillOpacity: 0,
        scale: 0,
        strokeColor: "transparent",
        strokeWeight: 0,
      },
    });
    return marker;
  };

  useEffect(() => {
    const geoJsonData = geoJson.zone;

    let lastItemId = 1;
    polygons.forEach((polygon) => polygon.instance.setMap(null));
    markers.forEach((marker) => marker.setMap(null));

    if (geoJsonData && map && geoJsonData.features) {
      const geoJsonPolygons = geoJsonData?.features.map((feature, index) => {
        const polygonCoords = feature?.geometry?.coordinates[0].map(
          (coord) => ({
            lat: coord[1], // GeoJSON uses [lng, lat] format
            lng: coord[0],
          })
        );

        const polygon = new window.google.maps.Polygon({
          paths: polygonCoords,
          map: map,
          fillColor: "lightblue",
          strokeColor: "blue",
          strokeWeight: 2,
          editable: true, // Make polygons non-editable if they come from geoJson
        });

        // Add label markers for each polygon
        const marker = addLabelToPolygon(polygon, feature.properties.id);

        const path = polygon.getPath();

        path.addListener("set_at", (index) => {
          handlePolygonShapeChange(polygon, feature.properties.id);
        });

        path.addListener("insert_at", (index) => {
          handlePolygonShapeChange(polygon, feature.properties.id);
        });

        path.addListener("remove_at", (index) => {
          handlePolygonShapeChange(polygon, feature.properties.id);
        });
        setMarkers((prevMarkers) => [...prevMarkers, marker]);
        lastItemId = feature.properties.id;
        return {
          id: feature.properties.id,
          instance: polygon,
          code: feature.properties.code,
          name: feature.properties.name,
        };
      });

      setPolygons(geoJsonPolygons);
      setPolygonList(
        geoJsonPolygons.map((p) => ({ id: p.id, name: p.name, code: p.code }))
      );

      setPolygonCounter(lastItemId + 1);
    }
  }, [geoJson, map]); // Run when geoJson or map changes

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newPolygonId = formValues.id;
    const polygonInstance = currentPolygonInstance;

    if (editingPolygon) {
      // Update existing polygon
      const updatedPlygons = polygons.map((polygon) =>
        polygon.id === newPolygonId
          ? { ...polygon, code: formValues.code, name: formValues.name }
          : polygon
      );
      const geoJson = {
        type: "FeatureCollection",
        features: updatedPlygons.map((polygon) => {
          const paths = polygon.instance
            .getPath()
            .getArray()
            .map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));
          return {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [paths?.map((path) => [path.lng, path.lat])],
            },
            properties: {
              id: polygon.id,
              code: polygon.code,
              name: polygon.name,
            },
          };
        }),
      };

      // setPolygons((prevPolygons) => {
      //   const updatedPolygons = prevPolygons.map((polygon) =>
      //     polygon.id === newPolygonId
      //       ? { ...polygon, code: formValues.code, name: formValues.name }
      //       : polygon
      //   );

      //   // Create GeoJSON from the updated polygons

      //   // Update polygon list accordingly
      //   setPolygonList((prevPolygonList) =>
      //     prevPolygonList.map((polygon) =>
      //       polygon.id === newPolygonId
      //         ? { ...polygon, code: formValues.code, name: formValues.name }
      //         : polygon
      //     )
      //   );

      //   // Return updated polygons for the state update
      //   return updatedPolygons;
      // });
    } else {
      // Create new polygon

      const newPolygon = {
        id: newPolygonId,
        instance: polygonInstance,
        code: formValues.code,
        name: formValues.name,
      };
      const updatedPolygons = [...polygons, newPolygon];

      // Create GeoJSON from the updated polygons
      const geoJson = {
        type: "FeatureCollection",
        features: updatedPolygons.map((polygon) => {
          const paths = polygon.instance
            .getPath()
            .getArray()
            .map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));
          return {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [paths?.map((path) => [path.lng, path.lat])],
            },
            properties: {
              id: polygon.id,
              code: polygon.code,
              name: polygon.name,
            },
          };
        }),
      };
      dispatch(addSheduleZone(geoJson));
      polygonInstance.setMap(null);
    }

    // Hide the form and reset the state after submission
    setShowForm(false);
    setCurrentPolygonInstance(null);
    setSelectedPolygonId(null);
    setEditingPolygon(null);
  };

  const handleCancel = () => {
    // Remove the polygon if the form is canceled
    if (currentPolygonInstance) {
      currentPolygonInstance.setMap(null);
    }
    setShowForm(false);
    setCurrentPolygonInstance(null);
    setPolygonCounter((prevCounter) => prevCounter - 1);
  };
  const handleEdit = (polygon) => {
    setEditingPolygon(polygon.id);
    setFormValues({
      id: polygon.id,
      code: polygon.code,
      name: polygon.name,
    });
    setCurrentPolygonInstance(polygon.instance); // Store the polygon instance for updating
    setShowForm(true); // Show the form for editing
  };

  const handleRemovePolygon = (polygonId) => {
    // Create GeoJSON from the updated polygons
    const polygonsdata = polygons.filter((p) => p.id !== polygonId);
    const geoJson = {
      type: "FeatureCollection",
      features: polygonsdata.map((polygon) => {
        const paths = polygon.instance
          .getPath()
          .getArray()
          .map((point) => ({
            lat: point.lat(),
            lng: point.lng(),
          }));
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [paths?.map((path) => [path.lng, path.lat])],
          },
          properties: {
            id: polygon.id,
            code: polygon.code,
            name: polygon.name,
          },
        };
      }),
    };
    dispatch(addSheduleZone(geoJson));
  };

  // const clearPolygons = () => {
  //   polygons.forEach((polygon) => polygon.instance.setMap(null));
  //   markers.forEach((marker) => marker.setMap(null));
  //   setPolygons([]);
  //   setMarkers([]);
  //   setPolygonList([]);
  //   setPolygonCounter(1);
  // };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "300px", padding: "0 20px", overflowY: "auto" }}>
        {showForm ? (
          <form onSubmit={handleFormSubmit}>
            <TextField
              margin="dense"
              label="ID"
              type="number"
              value={formValues.id}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Code"
              type="text"
              value={formValues.code}
              onChange={(e) =>
                setFormValues({ ...formValues, code: e.target.value })
              }
              required
              fullWidth
            />
            <TextField
              margin="dense"
              label="Name"
              type="text"
              value={formValues.name}
              onChange={(e) =>
                setFormValues({ ...formValues, name: e.target.value })
              }
              fullWidth
            />
            <Button type="submit" color="primary" style={{ marginTop: "10px" }}>
              {editingPolygon ? "Update" : "Save"}
            </Button>
            <Button
              onClick={handleCancel}
              color="secondary"
              style={{ marginTop: "10px", marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </form>
        ) : (
          <>
            <List>
              {polygonList.map((polygon) => (
                <ListItem
                  key={polygon.id}
                  onClick={() => handlePolygonSelect(polygon.id)}
                  style={{
                    backgroundColor:
                      selectedPolygonId === polygon.id
                        ? "#f0f0f0"
                        : "transparent",
                    cursor: "pointer",
                  }}
                  secondaryAction={
                    <>
                      {" "}
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleEdit(polygon)}
                      >
                        <EditIcon />
                      </IconButton>{" "}
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemovePolygon(polygon.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                >
                  {polygon.id}. {polygon.name || polygon.code}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </div>

      <div style={{ position: "relative", flex: 1 }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={8}
          onLoad={onMapLoad}
          onClick={() =>
            drawingManager?.setDrawingMode(
              window.google.maps.drawing.OverlayType.POLYGON
            )
          }
          options={{ minZoom: 4, fullscreenControl: false }}
        >
          {map && (
            <DrawingManager
              onLoad={(drawingManager) => setDrawingManager(drawingManager)}
              onPolygonComplete={onPolygonComplete}
              options={{
                drawingControl: false,
                polygonOptions: {
                  editable: true,
                  draggable: false,
                },
              }}
            />
          )}

          {/* Full-Screen Overlay Message */}
          {showForm && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark semi-transparent background
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999, // Ensure it is above other components
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "20px",
                  borderRadius: "5px",
                  boxShadow: 3,
                  textAlign: "center",
                }}
              >
                <Typography variant="h6" component="div">
                  Please complete the form to add this zone.
                </Typography>
              </Box>
            </Box>
          )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default MapWithDrawing;
