import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import {

  Accessible,
  BusinessCenter,

} from "@mui/icons-material";

const TransportCard = ({
  bookingDate,
  status,
  customer_name,
  customer_mobile,
  pickUpAddress,
  dropAddress,
  passangerCount,
  bagCount,
  wheelChairCount,
  routeDistance,
  routeTime,
  driver_name,
  paymentMethod,
  vehicleMake,
  vehicleModel,
}) => {
  return (
    <Card sx={{ marginY: 2, cursor: "pointer" }}>
      <CardContent>
        <Grid container spacing={1}>
          {/* When Section */}
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              When
            </Typography>
            <Typography variant="body1">
              {" "}
              {new Date(bookingDate).toLocaleDateString() +
                " " +
                new Date(bookingDate).toLocaleTimeString()}{" "}
            </Typography>
          </Grid>
          {/* Client Section */}
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Client
            </Typography>

            <Typography variant="body1">
              {customer_name + " (" + customer_mobile + ")"}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 1 }} />

        {/* status Section */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Status
            </Typography>
            <Typography variant="body1">{status}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 1 }} />

        {/* From Section */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              from
            </Typography>
            <Typography variant="body1">{pickUpAddress}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 1 }} />

        {/* To Section */}

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              To
            </Typography>
            <Typography variant="body1">{dropAddress}</Typography>
          </Grid>

          <Divider sx={{ my: 1 }} />

          {/* Info Section */}
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              info
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <PersonIcon fontSize="small" />
              <Typography variant="body1">{passangerCount}x</Typography>
              {bagCount > 0 && (
                <>
                  <BusinessCenter fontSize="small" />
                  <Typography variant="body1">{bagCount}x</Typography>
                </>
              )}
              {wheelChairCount > 0 && (
                <>
                  <Accessible fontSize="small" />
                  <Typography variant="body1">{wheelChairCount}x</Typography>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{ my: 1 }} />

        {/* Route Section */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              route
            </Typography>
            <Typography variant="body1">
              {parseFloat(routeTime / 60).toFixed(0)}minutes, {routeDistance} km
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 1 }} />

        {/* Vehicle Section */}
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Typography variant="body2" color="textSecondary">
              vehicle
            </Typography>
            <Typography variant="body1"> {vehicleMake? vehicleMake+" "+ vehicleModel:"-"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Driver
            </Typography>
            <Typography variant="body1">{driver_name?driver_name:"-"}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TransportCard;
