import {
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_ZONE_SUCCESS,
  EDIT_ZONE,
  EDIT_ZONE_SUCCESS,
  EDIT_ZONE_FAILED,
} from "../store/types";

import { onValue, set } from "firebase/database";
import { firebase } from "../config/configureFirebase";

export const fetchSheduleZone = () => (dispatch) => {
  const { shedulezoneRef } = firebase;

  dispatch({
    type: FETCH_ZONE,
    payload: null,
  });

  try {
    onValue(shedulezoneRef, async (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val();
        
        dispatch({
          type: FETCH_ZONE_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: FETCH_ZONE_FAILED,
          payload: "No zone available.",
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateSheduleZoneCoordinates = (polygonId, newCoordinates) => (dispatch, getState) => {
  const { shedulezoneRef } = firebase;

  // Get the current geoJSON data from the Redux store
  const geoJson = getState().zonedata.zone; // Assuming 'zones' is the key in your Redux store

  
  // Dispatch edit start action
  dispatch({
    type: EDIT_ZONE,
    payload: { polygonId, newCoordinates },
  });

  // Find the feature by polygonId and update its coordinates
  const updatedGeoJson = { ...geoJson };
  const featureIndex = updatedGeoJson.features.findIndex(
    (feature) => feature.properties.id === polygonId
  );

  if (featureIndex !== -1) {
    updatedGeoJson.features[featureIndex].geometry.coordinates = [newCoordinates];

    // Update the Firebase Realtime Database with the modified geoJSON
    set(shedulezoneRef, updatedGeoJson)
      .then(() => {
        dispatch({
          type: EDIT_ZONE_SUCCESS,
          payload: updatedGeoJson,
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_ZONE_FAILED,
          payload: error,
        });
      });
  } else {
    dispatch({
      type: EDIT_ZONE_FAILED,
      payload: "Zone not found",
    });
  }
};
export const addSheduleZone = (zonedata) => (dispatch) => {
  const { shedulezoneRef } = firebase;

  dispatch({
    type: EDIT_ZONE,
    payload: zonedata,
  });

  set(shedulezoneRef, zonedata)
    .then(() => {
      dispatch({
        type: EDIT_ZONE_SUCCESS,
        payload: null,
      });
    })
    .catch((error) => {
      dispatch({
        type: EDIT_ZONE_FAILED,
        payload: error,
      });
    });
};