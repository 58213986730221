import {
  FETCH_INVOICES,
  FETCH_INVOICES_FAILED,
  FETCH_INVOICES_SUCCESS,
  EDIT_INVOICE,
  EDIT_INVOICE_SUCCESS,
  EDIT_INVOICE_FAILED,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILED,
} from "../store/types";
import {
  onValue,
  set,
  push,
  update,
  off,
  get,
  remove,
} from "firebase/database";

import { firebase } from "../config/configureFirebase";



export const fetchInvoices = () => (dispatch) => {
  
  const { invoiceRef } = firebase;
 
  dispatch({
    type: FETCH_INVOICES,
    payload: null,
  });

try {
  

  onValue(invoiceRef, async snapshot => {
      if (snapshot.val()) {
      
        const data = snapshot.val(); 
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
              
        dispatch({
          type: FETCH_INVOICES_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_INVOICES_FAILED,
          payload: "No Invoice available."
        });
      }
    });
    
} catch (error) {
  console.log(error);
}
};
export const addINVOICE = (invoicedata) => (dispatch) => {
  console.log(invoicedata);
  const { invoiceRef } = firebase;

  dispatch({
    type: EDIT_INVOICE,
    payload: invoicedata
  });

  push(invoiceRef, invoicedata).then(() => {
    dispatch({
      type: EDIT_INVOICE_SUCCESS,
      payload: null
    });
  }).catch((error) => {
    dispatch({
      type: EDIT_INVOICE_FAILED,
      payload: error
    });
  });
}

export const editInvoices = (id, invoicedata) => (dispatch) => {

  const {
    editInvoiceRef
  } = firebase;

  dispatch({
    type: EDIT_INVOICE,
    payload: invoicedata
  });
  let editedInvoice = invoicedata;
  delete editedInvoice.id;
  delete editedInvoice.tableData;
  set(editInvoiceRef(id), editedUser);
}
export const deleteInvoice= (id) => (dispatch) => {

  const {
    editInvoiceRef
  } = firebase;

  dispatch({
    type: DELETE_INVOICE,
    payload: id
  });
 

  remove(editInvoiceRef(id)).then(()=>{
    dispatch({
      type: DELETE_INVOICE_SUCCESS,
      payload: id
    });
  }).catch(()=>{
    dispatch({
      type: DELETE_INVOICE_FAILED,
      payload: id
    });
  })
}