export const colors ={
    green1: "#005c4b",
    white1: "#e8ecef",
    black1: "#DDDEEE",
    grey1: "#7e96a3",
    pink1: "#ad45ce",
    blue1: "#0263cb",
    blue2: "#0494dd",
    red1: "#d3396c",
    purple1: "#5157ae",
}