import {
  FETCH_TAXIMETTER,
  FETCH_TAXIMETTER_SUCCESS,
  FETCH_TAXIMETTER_FAILED,
  EDIT_TAXIMETTER,
  EDIT_TAXIMETTER_SUCCESS,
  EDIT_TAXIMETTER_FAILED,
  ADD_TAXIMETTER,
  ADD_TAXIMETTER_SUCCESS,
  ADD_TAXIMETTER_FAILED,
  DELETE_TAXIMETTER,
  DELETE_TAXIMETTER_SUCCESS,
  DELETE_TAXIMETTER_FAILED,
} from "../store/types";

const INITIAL_STATE = {
  taximetterdatalist: [],
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const taximetterreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TAXIMETTER:
      return {
        ...state,
        loading: true,
        error: { flag: false, msg: null },
      };
    case FETCH_TAXIMETTER_SUCCESS:
      return {
        ...state,
        taximetterdatalist: action.payload || [],
        loading: false,
        error: { flag: false, msg: null },
      };
    case FETCH_TAXIMETTER_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case EDIT_TAXIMETTER:
      return {
        ...state,
        loading: true,
      };
    case EDIT_TAXIMETTER_SUCCESS:
      return {
        ...state,
        taximetterdatalist: [...action.payload],
        loading: false,
      };
    case EDIT_TAXIMETTER_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case ADD_TAXIMETTER:
      return {
        ...state,
        loading: true,
      };
    case ADD_TAXIMETTER_SUCCESS:
      return {
        ...state,
        taximetterdatalist: [...state.taximetterdatalist],
        loading: false,
      };
    case ADD_TAXIMETTER_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case DELETE_TAXIMETTER:
      return { ...state, loading: true };

    case DELETE_TAXIMETTER_SUCCESS:
      return {
        ...state,
        taximetterdatalist: action.payload,
        loading: false,
      };

    case DELETE_TAXIMETTER_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
