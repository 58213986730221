import {
  FETCH_SCHEDULE,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILED,
  EDIT_SCHEDULE,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAILED,
  ADD_SCHEDULE,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_FAILED,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILED,
} from "../store/types";

const INITIAL_STATE = {
  scheduledatalist: [],
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

export const schedulereducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SCHEDULE:
      return {
        ...state,
        loading: true,
        error: { flag: false, msg: null },
      };
    case FETCH_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduledatalist: action.payload || [],
        loading: false,
        error: { flag: false, msg: null },
      };
    case FETCH_SCHEDULE_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case EDIT_SCHEDULE:
      return {
        ...state,
        loading: true,
      };
    case EDIT_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduledatalist: [ ...action.payload],
        loading: false,
      };
    case EDIT_SCHEDULE_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case ADD_SCHEDULE:
      return {
        ...state,
        loading: true,
      };
    case ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduledatalist: [...state.scheduledatalist],
        loading: false,
      };
    case ADD_SCHEDULE_FAILED:
      return {
        ...state,
        loading: false,
        error: { flag: true, msg: action.payload },
      };
    case DELETE_SCHEDULE:
      return { ...state, loading: true };

    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduladatalist: action.payload,
        loading: false,
      };

    case DELETE_SCHEDULE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
