import {
  FETCH_TARIFF_ZONE,
  FETCH_TARIFF_ZONE_FAILED,
  FETCH_TARIFF_ZONE_SUCCESS,
  EDIT_TARIFF_ZONE,
  EDIT_TARIFF_ZONE_SUCCESS,
  EDIT_TARIFF_ZONE_FAILED,
} from "../store/types";

import { onValue, set } from "firebase/database";
import { firebase } from "../config/configureFirebase";

export const fetchTariffZone = () => (dispatch) => {
  const { tariffZoneRef } = firebase;

  dispatch({
    type: FETCH_TARIFF_ZONE,
    payload: null,
  });

  try {
    onValue(tariffZoneRef, async (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val();

        dispatch({
          type: FETCH_TARIFF_ZONE_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: FETCH_TARIFF_ZONE_FAILED,
          payload: "No zone available.",
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateTariffZoneCoordinates =
  (polygonId, newCoordinates) => (dispatch, getState) => {
    const { tariffZoneRef } = firebase;

    // Get the current geoJSON data from the Redux store
    const geoJson = getState().tariffzone.tariffzones;

    // Dispatch edit start action
    dispatch({
      type: EDIT_TARIFF_ZONE,
      payload: { polygonId, newCoordinates },
    });

    // Find the feature by polygonId and update its coordinates
    const updatedGeoJson = { ...geoJson };
    const featureIndex = updatedGeoJson.features.findIndex(
      (feature) => feature.properties.id === polygonId
    );

    if (featureIndex !== -1) {
      updatedGeoJson.features[featureIndex].geometry.coordinates = [
        newCoordinates,
      ];

      // Update the Firebase Realtime Database with the modified geoJSON
      set(tariffZoneRef, updatedGeoJson)
        .then(() => {
          dispatch({
            type: EDIT_TARIFF_ZONE_SUCCESS,
            payload: updatedGeoJson,
          });
        })
        .catch((error) => {
          dispatch({
            type: EDIT_TARIFF_ZONE_FAILED,
            payload: error,
          });
        });
    } else {
      dispatch({
        type: EDIT_TARIFF_ZONE_FAILED,
        payload: "Zone not found",
      });
    }
  };
export const addTariffZone = (zonedata) => (dispatch) => {
  const { tariffZoneRef } = firebase;

  dispatch({
    type: EDIT_TARIFF_ZONE,
    payload: zonedata,
  });

  set(tariffZoneRef, zonedata)
    .then(() => {
      dispatch({
        type: EDIT_TARIFF_ZONE_SUCCESS,
        payload: null,
      });
    })
    .catch((error) => {
      dispatch({
        type: EDIT_TARIFF_ZONE_FAILED,
        payload: error,
      });
    });
};
