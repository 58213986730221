import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  Box,
  Paper,
  IconButton,
  Modal,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MAIN_COLOR } from "../../common/sharedFunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import WaitRateAdd from "./WautRateAdd";
import { api } from "common";

const WaitRate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { deleteWaitRate } = api;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setEditData(false);
  };
  const [editData, setEditData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const rateData = useSelector((state) => state.waitratedata.waitratelist);

  const handleDelete = (id) => {
    dispatch(deleteWaitRate(id));
    toast.success("Deleted");
  };
  const handleEdit = (id) => {
    setIsEdit(true);
    const selectedData = rateData.find((row) => row.id === id);
    if (selectedData) {
      setEditData(selectedData);
      handleOpen();
    }
  };

  return (
    <>
      <Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ color: MAIN_COLOR, fontSize: 25 }}>
            {t("wait_rate")}
          </Typography>
          <Button sx={btnStyled} onClick={handleOpen} variant="contained">
            <Typography
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 16,
              }}
            >
              {t("create")}
            </Typography>
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={tabelStyled}>{t("SR_No")}</TableCell>
                <TableCell sx={tabelStyled}>{t("name")}</TableCell>
                <TableCell sx={tabelStyled}>{t("free")}</TableCell>
                <TableCell sx={tabelStyled}>{t("forFirst")}</TableCell>
                <TableCell sx={tabelStyled}>{t("then")}</TableCell>
                <TableCell sx={tabelStyled}>{t("per_s")}</TableCell>
                <TableCell sx={tabelStyled} align="right">
                {t("actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rateData && rateData.length > 0 ? (
                rateData.map((row, index) => (
                  <TableRow style={{ whiteSpace: "nowrap" }} key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.free}
                    </TableCell>
                    <TableCell>{row.forFirst}</TableCell>
                    <TableCell>{row.then}</TableCell>
                    <TableCell>{row.perS}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleEdit(row.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Box style={{ whiteSpace: "nowrap" }}>
                  {t("No_data_available")}
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Modal
        sx={modelWrapper}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={3} sx={modelCard}>
          <IconButton onClick={handleClose} sx={crossIcon}>
            <CloseIcon />
          </IconButton>
          <Box>
            <WaitRateAdd
              handleClose={handleClose}
              editData={editData}
              isEdit={isEdit}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default WaitRate;

const btnStyled = {
  borderRadius: "100px",
  backgroundColor: MAIN_COLOR,
  minHeight: 30,
  marginBottom: "10px",
  textAlign: "center",
  width: {
    xs: "30%",
    sm: "28%",
    md: "20%",
    lg: "13%",
  },
};
const modelWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const modelCard = {
  px: 4,
  py: 3,
  maxWidth: "750px",
  maxHeight: "550px",
  height: "100%",
  width: "100%",
  borderRadius: "20px",
  position: "relative",
  overflow: "auto",
};
const crossIcon = {
  position: "absolute",
  top: 8,
  right: 8,
  color: "black",
  zIndex: 1300,
};
const tabelStyled = {
  background: "#DDDEEE",
  fontWeight: "bold",
  whiteSpace: "nowrap",
};
