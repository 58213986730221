import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { MAIN_COLOR } from "common/sharedFunctions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { api } from "common";

const BasePrice = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addBasePriceData, addBasePriceValue, updateBasePriceData } = api;
  const zoneData = useSelector((state) => state.tariffzone);
  const basePriceData = useSelector((state) => state.baseprice.basepricedata);
  const basePricevalue = useSelector((state) => state.baseprice.basepricevalue);
  const [basePrice, setBasePrice] = useState(basePricevalue || "");
  const [isBasePriceSet, setIsBasePriceSet] = useState(basePricevalue !== null);
  const handleBasePriceChange = (e) => {
    setBasePrice(e.target.value);
  };
  const addBasePriceFunc = () => {
    if (basePrice && !isNaN(basePrice)) {
      dispatch(addBasePriceValue(parseFloat(basePrice)));
      setIsBasePriceSet(true);
    } else {
      toast.error("Please enter a valid base price.");
    }
  };
  const resetBasePrice = () => {
    setBasePrice("");
    setIsBasePriceSet(false);
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    selectedZone: "",
    actionType: "",
    percentage: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { selectedZone, actionType, percentage, id } = formData;
    if (!selectedZone || !actionType || !percentage) {
      toast.error(t("set_field"));
      return;
    }
    const zone = zoneData?.tariffzones?.features?.find(
      (z) => z.properties.id === selectedZone
    );
    if (!zone) {
      toast.error(t("set_zone"));
      return;
    }
    const newBasePrice = {
      zoneId: zone.properties.id,
      zoneName: zone.properties.name.trim(),
      zonePercentage: `${percentage}%`,
      percentageSelect: `${percentage}% ${
        actionType === "increase" ? "Increase" : "Decrease"
      }`,
    };
    setLoading(true);
    if (id) {
      dispatch(updateBasePriceData(id, newBasePrice));
    } else {
      dispatch(addBasePriceData(newBasePrice));
    }
    setFormData({
      selectedZone: "",
      actionType: "",
      percentage: "",
    });

    setLoading(false);
    setEditingZoneId(null);
  };

  const [editingZoneId, setEditingZoneId] = useState(null);
  const handleEdit = (id) => {
    const selectedData = basePriceData[id];
    setFormData({
      basePrice: selectedData.basePrice,
      selectedZone: selectedData.zoneId,
      actionType: selectedData.percentageSelect.includes("Increase")
        ? "increase"
        : "decrease",
      percentage: selectedData.zonePercentage.replace("%", ""),
      id,
    });
    setEditingZoneId(selectedData.zoneId);
  };

  return (
    <>
      <Box component="form">
        <Typography sx={{ mb: "8px" }}>{t("Base_Price")}</Typography>
        <Grid container spacing={2} sx={{ mb: "20px" }}>
          <Grid item xs={8}>
            <TextField
              label={t("Base_Price")}
              name="basePrice"
              fullWidth
              value={basePrice}
              onChange={handleBasePriceChange}
              disabled={isBasePriceSet}
            />
          </Grid>
          {!isBasePriceSet && (
            <Box sx={btnBox}>
              <Button
                sx={btnStyled}
                type="button"
                variant="contained"
                disabled={loading}
                onClick={addBasePriceFunc}
              >
                <Typography
                  style={{ color: "white", textAlign: "center", fontSize: 16 }}
                >
                  {t("Add Price")}
                </Typography>
              </Button>
            </Box>
          )}
          {isBasePriceSet && (
            <Box sx={btnBox}>
              <Button
                sx={btnStyled}
                type="button"
                variant="contained"
                disabled={loading}
                onClick={resetBasePrice}
              >
                <Typography
                  style={{ color: "white", textAlign: "center", fontSize: 16 }}
                >
                  {t("Update")}
                </Typography>
              </Button>
            </Box>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="zone_select">{t("add_zones_title")}</InputLabel>
              <Select
                labelId={t("zone_select")}
                name="selectedZone"
                value={formData.selectedZone}
                onChange={handleChange}
              >
                {zoneData?.tariffzones?.features
                  ?.filter(
                    (zone) =>
                      !Object.values(basePriceData).some(
                        (item) => item.zoneId === zone.properties.id
                      ) || zone.properties.id === editingZoneId
                  )
                  .map((zone) => (
                    <MenuItem
                      key={zone.properties.id}
                      value={zone.properties.id}
                    >
                      {zone.properties.name.trim()}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="percentage_type">{t("actions")}</InputLabel>
              <Select
                labelId="percentage_type"
                name="actionType"
                value={formData.actionType}
                onChange={handleChange}
              >
                <MenuItem value="increase">{t("Increase")}</MenuItem>
                <MenuItem value="decrease">{t("Decrease")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label={t("percentage")}
              name="percentage"
              fullWidth
              type="number"
              value={formData.percentage}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Box sx={btnBox}>
          <Button
            sx={btnStyled}
            type="submit"
            variant="contained"
            disabled={loading}
            onClick={handleSubmit}
          >
            <Typography
              style={{ color: "white", textAlign: "center", fontSize: 16 }}
            >
              {loading ? t("loading") : t("save")}
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box>
        <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={tableStyled}>{t("SR_No")}</TableCell>
                <TableCell sx={tableStyled}>{t("Zone_Id")}</TableCell>
                <TableCell sx={tableStyled}>{t("Zone_Name")}</TableCell>
                <TableCell sx={tableStyled}>{t("percentage")}</TableCell>
                <TableCell sx={tableStyled} align="right">
                  {t("actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {basePriceData && Object.keys(basePriceData).length > 0 ? (
                Object.keys(basePriceData).map((id, index) => {
                  const list = basePriceData[id];
                  // Check to exclude rows that are only meant for `basepricevalue`
                  if (id === "basepricevalue") return null;
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{list.zoneId}</TableCell>
                      <TableCell>{list.zoneName}</TableCell>
                      <TableCell>{list.percentageSelect}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleEdit(id)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    {t("No_data_available")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default BasePrice;
const btnBox = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: "20px",
  marginBottom: "20px",
};
const btnStyled = {
  borderRadius: "19px",
  backgroundColor: MAIN_COLOR,
  minHeight: "30px",
  marginLeft: "10px",
};
const tableStyled = {
  fontSize: "16px",
  fontWeight: 600,
};
