import { 
    FETCH_ZONE,
    FETCH_ZONE_SUCCESS,
    FETCH_ZONE_FAILED,
    EDIT_ZONE
  } from "../store/types";
  
  export const INITIAL_STATE = {
    zone:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const zonereducer =  (state = INITIAL_STATE, action) => {

    switch (action.type) {
      case  FETCH_ZONE:
        return {
          ...state,
        };
      case  FETCH_ZONE_SUCCESS:
        return {
          ...state,
          zone:action.payload,
        };
      case FETCH_ZONE_FAILED:
        return {
          ...state,
          zone:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_ZONE:
        return state;
      default:
        return state;
    }
  };