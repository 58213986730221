// import * as React from 'react';

// import { Button, Modal, Box, Typography, Grid, Paper , Slider} from "@mui/material";

// const minDistance = 2;

// function valuetext(value) {
//   const hour = value % 12 || 12;
//   const period = value < 12 ? "AM" : "PM";
//   return `${hour} ${period}`;
// }

// export default function MinimumTimeSlider() {
//   const [value1, setValue1] = React.useState([8, 18]);
//   const [value2, setValue2] = React.useState([20, 30]);

//   const handleChange1 = (event, newValue, activeThumb) => {
//     if (!Array.isArray(newValue)) {
//       return;
//     }

//     if (activeThumb === 0) {
//       setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
//     } else {
//       setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
//     }
//   };

//   const handleChange2 = (event, newValue, activeThumb) => {
//     if (!Array.isArray(newValue)) {
//       return;
//     }

//     if (newValue[1] - newValue[0] < minDistance) {
//       if (activeThumb === 0) {
//         const clamped = Math.min(newValue[0], 24 - minDistance);
//         setValue2([clamped, clamped + minDistance]);
//       } else {
//         const clamped = Math.max(newValue[1], minDistance);
//         setValue2([clamped - minDistance, clamped]);
//       }
//     } else {
//       setValue2(newValue);
//     }
//   };

//   return (

//         <Box sx={{ width: 600 }}>
//           <Slider
//             sx={{
//               width: 600,
//               color: 'darkgrey',
//               '& .MuiSlider-thumb': {
//                 borderRadius: '1px',
//               },
//             }}
//             getAriaLabel={() => "AM Time"}
//             value={value1}
//             onChange={handleChange1}
//             valueLabelDisplay="auto"
//             getAriaValueText={valuetext}
//             marks={[
//               { value: 0, label: "12 AM" },
//               { value: 6, label: "6 AM" },
//               { value: 12, label: "12 PM" },
//               { value: 18, label: "6 PM" },
//               { value: 24, label: "12 AM" },
//             ]}
//             step={1}
//             min={0}
//             max={24}
//             disableSwap
//           />
//         </Box>

//   );
// }

import * as React from "react";
import { Box, Slider } from "@mui/material";

const minDistance = 2;
const MAIN_COLOR = "#3f51b5";

function valuetext(value) {
  const hour = value % 12 || 12;
  const period = value < 12 ? "AM" : "PM";
  return `${hour} ${period}`;
}

export default function MinimumTimeSlider({ startTime, endTime, onTimeChange }) {
  const [value, setValue] = React.useState([startTime, endTime]);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return;
    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
    onTimeChange(value[0], value[1]);
  };

  return (
    <Box sx={{ width: 600 }}>
      <Slider
        sx={{
          width: 600,
          color: "darkgrey",
          "& .MuiSlider-thumb": { borderRadius: "1px" },
        }}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        marks={[
          { value: 0, label: "12 AM" },
          { value: 6, label: "6 AM" },
          { value: 12, label: "12 PM" },
          { value: 18, label: "6 PM" },
          { value: 24, label: "12 AM" },
        ]}
        step={1}
        min={0}
        max={24}
        disableSwap
      />
    </Box>
  );
}
