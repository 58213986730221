import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  
  Button,
  Grid,
  Card,
  
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";

import { colors } from "../components/Theme/WebTheme";
import AlertDialog from "../components/AlertDialog";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
// const useStyles = makeStyles((theme) => ({
//   textField: {
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//     "& .MuiFilledInput-root": {
//       background: SECONDORY_COLOR,
//     },
//   },
//   selectField: {
//     color: "black",
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: MAIN_COLOR,
//     },
//   },
//   rootRtl_1: {
//     "& label": {
//       right: 10,
//       left: "auto",
//       paddingRight: 20,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 15,
//     },
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//   },
//   rootRtl_3: {
//     "& label": {
//       right: 0,
//       left: "auto",
//       paddingRight: 20,
//     },
//     "& legend": {
//       textAlign: "right",
//       marginRight: 20,
//     },
//     "& label.Mui-focused": {
//       color: MAIN_COLOR,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiFilledInput-underline:after": {
//       borderBottomColor: MAIN_COLOR,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: MAIN_COLOR,
//       },
//     },
//   },
// }));

function CompanyInfo() {
  const { id } = useParams();
  const companiesdata = useSelector((state) => state.companiesdata);
  console.log(companiesdata);
  
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const [data, setData] = useState([]);


  const loaded = useRef(false);

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const settings = useSelector((state) => state.settingsdata.settings);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (companiesdata.companies) {
      const company = companiesdata?.companies?.filter(
        (company) => company.id === id.toString()
      )[0];

      if (!company) {
        navigate("/404");
      }
      setData(company);
      setLoading(false)
    } else {
      setData([]);
      setLoading(false)
    }
    loaded.current = true;
  }, [companiesdata, navigate, id]);


  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  return loading ? (
    <CircularLoading />
  ) : (
    <Card
      style={{
        borderRadius: "19px",
        backgroundColor: "#fff",
        minHeight: 100,
        marginBottom: 20,
        padding: 20,
        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
      }}
    >
      <div dir={isRTL === "rtl" ? "rtl" : "ltr"}>
        <Button
          variant="text"
          onClick={() => {
            navigate("/companies");
          }}
        >
          <Typography
            style={{
              textAlign: isRTL === "rtl" ? "right" : "left",
              fontWeight: "bold",
              color: MAIN_COLOR,
            }}
          >
            {`<<- ${t("go_back")}`}
          </Typography>
        </Button>
      </div>
      <Grid
        container
        spacing={1}
        sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 5,
          }}
          gap={2}
        >
          <Grid
            container
            spacing={2}
            sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                >
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Card
                      style={{
                        borderRadius:
                          isRTL === "rtl" ? "0 15px 15px 0" : "15px 0 0 15px",
                        minHeight: 80,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: 10,
                        backgroundColor: MAIN_COLOR,
                        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontSize: 16,
                        }}
                      >
                        {t("name")}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Card
                      style={{
                        borderRadius:
                          isRTL === "rtl" ? "15px 0 0 15px " : "0 15px 15px 0",
                        backgroundColor: colors.WHITE,
                        minHeight: 80,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: 10,
                        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                      }}
                    >
                      <Typography
                        style={{
                          color: "black",
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {data?.name}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "0 15px 15px 0" : "15px 0 0 15px",
                  backgroundColor: MAIN_COLOR,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  {t("phone")}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "15px 0 0 15px " : "0 15px 15px 0",
                  backgroundColor: colors.WHITE,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "Black",
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {settings.AllowCriticalEditsAdmin
                    ? data?.phone
                    : t("hidden_demo")}
                </Typography>
              </Card>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "0 15px 15px 0" : "15px 0 0 15px",
                  backgroundColor: MAIN_COLOR,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  {t("email")}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "15px 0 0 15px " : "0 15px 15px 0",
                  backgroundColor: colors.WHITE,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "Black",
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {settings.AllowCriticalEditsAdmin
                    ? data?.email
                    : t("hidden_demo")}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "0 15px 15px 0" : "15px 0 0 15px",
                  backgroundColor: MAIN_COLOR,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  {t("city")}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "15px 0 0 15px " : "0 15px 15px 0",
                  backgroundColor: colors.WHITE,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "Black",
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {settings.AllowCriticalEditsAdmin
                    ? data?.city
                    : t("hidden_demo")}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "0 15px 15px 0" : "15px 0 0 15px",
                  backgroundColor: MAIN_COLOR,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  {t("address")}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "15px 0 0 15px " : "0 15px 15px 0",
                  backgroundColor: colors.WHITE,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                paragraph={true}
                component="p"                 
                  style={{
                    color: "Black",
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  whiteSpace: 'pre-line'

                  }}
                >
                  {settings.AllowCriticalEditsAdmin
                    ? data?.address
                    : t("hidden_demo")}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "0 15px 15px 0" : "15px 0 0 15px",
                  backgroundColor: MAIN_COLOR,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  {t("organization_number")}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "15px 0 0 15px " : "0 15px 15px 0",
                  backgroundColor: colors.WHITE,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "Black",
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {settings.AllowCriticalEditsAdmin
                    ? data?.organisationNumber
                    : t("hidden_demo")}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "0 15px 15px 0" : "15px 0 0 15px",
                  backgroundColor: MAIN_COLOR,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  {t("message_text")}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card
                style={{
                  borderRadius:
                    isRTL === "rtl" ? "15px 0 0 15px " : "0 15px 15px 0",
                  backgroundColor: colors.WHITE,
                  minHeight: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 10,
                  boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                }}
              >
                <Typography
                  style={{
                    color: "Black",
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {settings.AllowCriticalEditsAdmin
                    ? data?.message
                    : t("hidden_demo")}
                </Typography>
              </Card>
            </Grid>
          </Grid>

        
        </Grid>
      </Grid>

      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </Card>
  );
}

export default CompanyInfo;
