import {
    FETCH_WAITRATE,
    FETCH_WAITRATE_SUCCESS,
    FETCH_WAITRATE_FAILED,
    EDIT_WAITRATE,
    EDIT_WAITRATE_SUCCESS,
    EDIT_WAITRATE_FAILED,
    ADD_WAITRATE,
    ADD_WAITRATE_SUCCESS,
    ADD_WAITRATE_FAILED,
    DELETE_WAITRATE,
    DELETE_WAITRATE_SUCCESS,
    DELETE_WAITRATE_FAILED,
  } from "../store/types";
  
  const INITIAL_STATE = {
    waitratelist: [],
    loading: false,
    error: {
      flag: false,
      msg: null,
    },
  };
  
  export const waitratereducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_WAITRATE:
        return {
          ...state,
          loading: true,
          error: { flag: false, msg: null },
        };
      case FETCH_WAITRATE_SUCCESS:
        return {
          ...state,
          waitratelist: action.payload || [],
          loading: false,
          error: { flag: false, msg: null },
        };
      case FETCH_WAITRATE_FAILED:
        return {
          ...state,
          loading: false,
          error: { flag: true, msg: action.payload },
        };
      case EDIT_WAITRATE:
        return {
          ...state,
          loading: true,
        };
      case EDIT_WAITRATE_SUCCESS:
        return {
          ...state,
          waitratelist: [...action.payload],
          loading: false,
        };
      case EDIT_WAITRATE_FAILED:
        return {
          ...state,
          loading: false,
          error: { flag: true, msg: action.payload },
        };
      case ADD_WAITRATE:
        return {
          ...state,
          loading: true,
        };
      case ADD_WAITRATE_SUCCESS:
        return {
          ...state,
          waitratelist: [...state.waitratelist],
          loading: false,
        };
      case ADD_WAITRATE_FAILED:
        return {
          ...state,
          loading: false,
          error: { flag: true, msg: action.payload },
        };
      case DELETE_WAITRATE:
        return { ...state, loading: true };
  
      case DELETE_WAITRATE_SUCCESS:
        return {
          ...state,
          waitratelist: action.payload,
          loading: false,
        };
  
      case DELETE_WAITRATE_FAILED:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  