import {
  FETCH_COMPANIES,
  FETCH_COMPANIES_FAILED,
  FETCH_COMPANIES_SUCCESS,
  EDIT_COMPANY,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILED,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILED,

} from "../store/types";
import {
  onValue,
  set,
  push,
  update,
  off,
  get,
  remove,
} from "firebase/database";

import { firebase } from "../config/configureFirebase";



export const fetchCompanies = () => (dispatch) => {
  
  const { companyRef } = firebase;
 
  dispatch({
    type: FETCH_COMPANIES,
    payload: null,
  });

try {
  

  onValue(companyRef, async snapshot => {
      if (snapshot.val()) {
      
        const data = snapshot.val(); 
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
              
        dispatch({
          type: FETCH_COMPANIES_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_COMPANIES_FAILED,
          payload: "No company available."
        });
      }
    });
    
} catch (error) {
  console.log(error);
}
};
export const addCompany = (companydata) => (dispatch) => {
  console.log(companydata);
  const { companyRef } = firebase;

  dispatch({
    type: EDIT_COMPANY,
    payload: companydata
  });

  push(companyRef, companydata).then(() => {
    dispatch({
      type: EDIT_COMPANY_SUCCESS,
      payload: null
    });
  }).catch((error) => {
    dispatch({
      type: EDIT_COMPANY_FAILED,
      payload: error
    });
  });
}

export const editCompany = (id, companydata) => (dispatch) => {

  const {
    editCompanyRef
  } = firebase;

  dispatch({
    type: EDIT_COMPANY,
    payload: companydata
  });
  let editedUser = companydata;
  delete editedUser.id;
  delete editedUser.tableData;
  set(editCompanyRef(id), editedUser);
}
export const deleteCompany = (id) => (dispatch) => {

  const {
    editCompanyRef
  } = firebase;

  dispatch({
    type: DELETE_COMPANY,
    payload: id
  });
 

  remove(editCompanyRef(id)).then(()=>{
    dispatch({
      type: DELETE_COMPANY_SUCCESS,
      payload: id
    });
  }).catch(()=>{
    dispatch({
      type: DELETE_COMPANY_FAILED,
      payload: id
    });
  })
}