import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FirebaseContext } from "common";
import {
  query,
  ref,
  onValue,
  orderByChild,
  limitToLast,
  set,
} from "firebase/database";
import moment from "moment";

import Modal from "@mui/material/Modal";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "4px",
};

export default function ChatCard(props) {
  const firebase = useContext(FirebaseContext);
  const { profile } = useSelector((store) => store.auth);
  const { id, firstName, lastName, profile_image } = props.item;
  const { selectedDriver, setSelectedDriver, index } = props;
  const [lastMsgData, setLastMsgData] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchLastMsg();

    // if (index == 0) {
    //   handleOpenDriverChat(props.item);
    // }
  }, [firebase.database]);

  const fetchLastMsg = () => {
    if (profile) {
      const generatedChatId = `chat_${id}`;
      const messagesRef = ref(
        firebase.database,
        `driverAdminChat/${generatedChatId}`
      );
      const lastMessageQuery = query(
        messagesRef,
        orderByChild("createdAt"),
        limitToLast(1)
      );
      const unsubscribe = onValue(lastMessageQuery, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const key = Object.keys(data)[0];
          const lastMessage = Object.values(data)[0];
          setLastMsgData({ ...lastMessage, key });
        } else {
          setLastMsgData(null);
          console.log("No data available");
        }
      });
      return () => unsubscribe();
    }
  };

  const handleOpenDriverChat = (driver) => {


    // if (
    //   lastMsgData &&
    //   lastMsgData.isJoin !== "" &&
    //   lastMsgData.isJoin === profile.uid
    // ) {
    //   const previousChatMsg = JSON.parse(
    //     localStorage.getItem("previousChatMsg")
    //   );
    //   const previousDriverId = localStorage.getItem("previousDriverId");

    //   const messagesRef = ref(
    //     firebase.database,
    //     `driverAdminChat/chat_${previousDriverId}/${previousChatMsg.key}`
    //   );

    //   const msgData = Object.keys(previousChatMsg)
    //     .filter((objKey) => objKey !== "key")
    //     .reduce((newObj, key) => {
    //       newObj[key] = previousChatMsg[key];
    //       return newObj;
    //     }, {});

    //   console.log("msgData: ", msgData);
    //   return false;

    //   const updatedMessage = { ...msgData, isRead: true, isJoin: "" };

    //   set(messagesRef, updatedMessage)
    //     .then(() => {
    //       localStorage.setItem("previousChatMsg", JSON.stringify(lastMsgData));
    //       localStorage.setItem("previousDriverId", id);
    //       console.log("Message updated successfully:", updatedMessage);
    //     })
    //     .catch((error) => {
    //       console.error("Error updating message:", error);
    //     });

    //   console.log("leave chat");

    //   return false;
    // }



    setSelectedDriver(driver);

    if (lastMsgData) {
      updatedIsLastMsgRead(driver.id);
    }
  };

  const updatedIsLastMsgRead = (currentSelectedDriverId) => {
    if (lastMsgData && profile) {
      const messagesRef = ref(
        firebase.database,
        `driverAdminChat/chat_${id}/${lastMsgData.key}` // Use the key here
      );

      const msgData = Object.keys(lastMsgData)
        .filter((objKey) => objKey !== "key")
        .reduce((newObj, key) => {
          newObj[key] = lastMsgData[key];
          return newObj;
        }, {});

      const updatedMessage = { ...msgData, isRead: true, isJoin: "" };

      set(messagesRef, updatedMessage)
        .then(() => {
          setLastMsgData(updatedMessage);
          if (
            currentSelectedDriverId !==
              localStorage.getItem("previousDriverId") &&
            localStorage.getItem("previousDriverId")
          ) {
            leaveChat();
          }
          localStorage.setItem("previousChatMsg", JSON.stringify(lastMsgData));
          localStorage.setItem("previousDriverId", currentSelectedDriverId);
          console.log("Message updated successfully:", updatedMessage);
        })
        .catch((error) => {
          console.error("Error updating message:", error);
        });
    } else {
      console.error("No last message data or profile available");
    }
  };

  const leaveChat = () => {
    const previousChatMsg = JSON.parse(localStorage.getItem("previousChatMsg"));
    const previousDriverId = localStorage.getItem("previousDriverId");

    const messagesRef = ref(
      firebase.database,
      `driverAdminChat/chat_${previousDriverId}/${previousChatMsg.key}`
    );

    const msgData = Object.keys(previousChatMsg)
      .filter((objKey) => objKey !== "key")
      .reduce((newObj, key) => {
        newObj[key] = previousChatMsg[key];
        return newObj;
      }, {});

    const updatedMessage = { ...msgData, isRead: true, isJoin: "" };

    set(messagesRef, updatedMessage)
      .then(() => {
        // console.log("Message updated successfully:", updatedMessage);
      })
      .catch((error) => {
        console.error("Error updating message:", error);
      });
  };

  

  return (
    <Box
      display="flex"
      sx={{
        background: id === selectedDriver?.id ? "#DDDEEE" : "white",
        padding: "8px 12px",
        cursor:"pointer"
      }}
      onClick={() => {
        handleOpenDriverChat(props.item);
      }}
    >
      {profile_image? 
        <Avatar alt={firstName} src={profile_image} />
      
      : 
      
      <Avatar>{firstName ? firstName[0].toUpperCase():null}</Avatar> }
      
    

      <Box
        display="flex"
        flexDirection="column"
        pl="15px"
        width="100%"
        alignItems="flex-start"
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="body1" color="Black">
            {firstName + " " + lastName}
          </Typography>
          {lastMsgData ? (
            lastMsgData.isRead === false ? (
              <Box
                sx={{
                  height: "7px",
                  width: "7px",
                  borderRadius: "50%",
                  background: "green",
                }}
              ></Box>
            ) : (
              <Typography variant="caption" color="Black">
                {moment(lastMsgData?.createdAt).format("h:mm A")}
              </Typography>
            )
          ) : null}
        </Box>
        {lastMsgData ? (
                <Typography
                variant="subtitle2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "50%",
                  maxWidth:"200px",  // Or any specific width you need
                }}
              >
                           {lastMsgData?.text}

              </Typography>
        
                  
        ) : null}
        <Box
          width="100%"
          mt="13px"
          sx={{
            border: ".04px solid #7e96a3",
          }}
        />
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
            backgroundColor: "rgba(0,0,0,0.1)",
          },
        }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Warning !
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Another Admin has already joined the chat for this Driver.
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            <Button
              type="button"
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
