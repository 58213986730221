import {
  FETCH_ESTIMATE,
  FETCH_ESTIMATE_SUCCESS,
  FETCH_ESTIMATE_FAILED,
  CLEAR_ESTIMATE,
  FETCH_TAXIMETERESTIMATE,
  FETCH_TAXIMETERESTIMATE_SUCCESS,
  FETCH_TAXIMETERESTIMATE_FAILED,
  CLEAR_TAXIMETERESTIMATE,

  FETCH_ESTIMATEBASEPRICE,
  FETCH_ESTIMATEBASEPRICE_SUCCESS,
  FETCH_ESTIMATEBASEPRICE_FAILED,
  CLEAR_ESTIMATEBASEPRICE,
} from "../store/types";

const INITIAL_STATE = {
  estimate: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
};

/********************Estimate By Booking Data****************/

export const estimatereducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ESTIMATE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ESTIMATE_SUCCESS:
      return {
        ...state,
        estimate: action.payload,
        loading: false,
      };
    case FETCH_ESTIMATE_FAILED:
      return {
        ...state,
        estimate: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case CLEAR_ESTIMATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

/********************Estimate By Taxi Meter****************/
export const estimattaximeterreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TAXIMETERESTIMATE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TAXIMETERESTIMATE_SUCCESS:
      return {
        ...state,
        estimate: action.payload,
        loading: false,
      };
    case FETCH_TAXIMETERESTIMATE_FAILED:
      return {
        ...state,
        estimate: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case CLEAR_TAXIMETERESTIMATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

/********************Estimate By Base Price****************/

export const estimatbasepricereducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ESTIMATEBASEPRICE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ESTIMATEBASEPRICE_SUCCESS:
      return {
        ...state,
        estimate: action.payload,
        loading: false,
      };
    case FETCH_ESTIMATEBASEPRICE_FAILED:
      return {
        ...state,
        estimate: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case CLEAR_ESTIMATEBASEPRICE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
