import { Avatar, Box, IconButton, Input, Typography } from "@mui/material";
import CustomAppBar from "../foundation/CustomAppBar/CustomAppBar";

import SendIcon from "@mui/icons-material/Send";

// import AttachmentPopover from "./AttachmentPopover";
import ChatContainer from "./ChatContainer";

export default function RightPanel({
  messages,
  message,
  setMessage,
  handleSendMessage,
  selectedDriver,
}) {
  const globalIconStyle = {
    color: "#8696a1",
    height: "28px",
    width: "28px",
  };

    // Function to handle "Enter" key press
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && message.trim()) {
        handleSendMessage();
      }
    };

  return (
    <Box height="100%" width="70%" display="flex" flexDirection="column">
      <CustomAppBar>
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Box
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                background: "#bdbdbd",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "900",
                color: "#fff",
                fontSize: "22px",
              }}
            >
              {selectedDriver
                ? selectedDriver?.firstName[0]?.toUpperCase()
                : ""}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              pl="10px"
            >
              <Typography variant="body1" color="black">
                {selectedDriver
                  ? selectedDriver?.firstName + " " + selectedDriver?.lastName
                  : ""}
              </Typography>
              {/* <Typography variant="caption" color="#8496a0">
                online
              </Typography> */}
            </Box>
          </Box>
        </Box>
      </CustomAppBar>
      <Box flex={1} minHeight={0} position="relative">
        <ChatContainer messages={messages} />
      </Box>
      <Box
        height="62px"
        alignItems="center"
        display="flex"
        zIndex="1000"
        sx={{
          background: "#DDDEEE",
          padding: "0px 15px",
        }}
      >
        {/* not required in current build */}
        {/* <AttachmentPopover /> */}
        <Box flex={1} pl="5px" pr="5px">
          <Input
            fullWidth
            disableUnderline
            placeholder="Type a message"
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            onKeyDown={handleKeyDown}
            sx={{
              background: "white",
              height: "42px",
              borderRadius: "6px",
              color: "black",
              padding: "0px 10px",
            }}
          />
        </Box>
        <IconButton
          onClick={() => {
           if(message) handleSendMessage();
          }}
        >
          <SendIcon sx={globalIconStyle} />
        </IconButton>
      </Box>
    </Box>
  );
}
