import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { authreducer } from "../reducers/authreducer";
import { cartypesreducer } from "../reducers/cartypesreducer";
import { bookingslistreducer } from "../reducers/bookingslistreducer";
import { estimatereducer, estimattaximeterreducer, estimatbasepricereducer } from "../reducers/estimatereducer";
import { bookingreducer } from "../reducers/bookingreducer";
import { cancelreasonreducer } from "../reducers/cancelreasonreducer";
import { promoreducer } from "../reducers/promoreducer";
import { usersreducer } from "../reducers/usersreducer";
import { notificationreducer } from "../reducers/notificationreducer";
import { driverearningreducer } from "../reducers/driverearningreducer";
import { earningreportsreducer } from "../reducers/earningreportsreducer";
import { settingsreducer } from "../reducers/settingsreducer";
import { paymentreducer } from "../reducers/paymentreducer";
import { tripreducer } from "../reducers/tripreducer";
import { tasklistreducer } from "../reducers/tasklistreducer";
import { locationreducer } from "../reducers/locationreducer";
import { chatreducer } from "../reducers/chatreducer";
import { withdrawreducer } from "../reducers/withdrawreducer";
import { gpsreducer } from "../reducers/gpsreducer";
import { addresslistreducer } from "../reducers/addressreducer";
import { languagereducer } from "../reducers/languagereducer";
import { carlistreducer } from "../reducers/carlistreducer";
import { smtpreducer } from "../reducers/smtpreducer";
import { smsreducer } from "../reducers/smsreducer";
import { sosreducer } from "../reducers/sosreducer";
import { complainreducer } from "../reducers/complainreducer";
import { companiesreducer } from "../reducers/companiesreducer";
import { invoicesreducer } from "../reducers/invoicesreducer";
import { driverAdminChatReducer } from "../reducers/driverAdminChatReducer";
import { tariffreducer } from "../reducers/tariffzonereducer";
import { zonereducer } from "../reducers/zonereducer";
import { basepricereducer } from "../reducers/basepricereducer";
import { schedulereducer } from "../reducers/schedulereducer";
import { taximetterreducer } from "../reducers/taximetterreducer";
import { waitratereducer } from "../reducers/waitratereducer";
import { flattariffsreducer } from "../reducers/flattariffsreducer";
import { tariffrulereducer } from "../reducers/tariffrulereducer";
const rootReducer = {
  auth: authreducer,
  cartypes: cartypesreducer,
  bookinglistdata: bookingslistreducer,
  estimatedata: estimatereducer,
  bookingdata: bookingreducer,
  cancelreasondata: cancelreasonreducer,
  promodata: promoreducer,
  usersdata: usersreducer,
  notificationdata: notificationreducer,
  driverearningdata: driverearningreducer,
  earningreportsdata: earningreportsreducer,
  settingsdata: settingsreducer,
  paymentmethods: paymentreducer,
  tripdata: tripreducer,
  taskdata: tasklistreducer,
  locationdata: locationreducer,
  chatdata: chatreducer,
  withdrawdata: withdrawreducer,
  addressdata: addresslistreducer,
  gpsdata: gpsreducer,
  languagedata: languagereducer,
  carlistdata: carlistreducer,
  smtpdata: smtpreducer,
  smsconfigdata: smsreducer,
  sosdata: sosreducer,
  complaindata: complainreducer,
  companiesdata: companiesreducer,
  driveradminchatdata: driverAdminChatReducer,
  invoicedata: invoicesreducer,
  zonedata: zonereducer,
  tariffzone: tariffreducer,
  baseprice: basepricereducer,
  scheduledata: schedulereducer,
  taximetterdata: taximetterreducer,
  waitratedata: waitratereducer,
  flattariffsdata: flattariffsreducer,
  tariffruledata: tariffrulereducer,
  estimatetaximeter:estimattaximeterreducer,
  estimatebaseprice:estimatbasepricereducer,
};

const combinedReducers = combineReducers(rootReducer);

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
};

// Wrap your rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, combinedReducers);

const store = configureStore({
  middleware: [thunk],
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };

export default store;
