import {
  FETCH_BASE_PRICE,
  FETCH_BASE_PRICE_SUCCESS,
  FETCH_BASE_PRICE_FAILED,
  EDIT_BASE_PRICE,
  EDIT_BASE_PRICE_SUCCESS,
  EDIT_BASE_PRICE_FAILED,
  ADD_BASE_PRICE,
  ADD_BASE_PRICE_SUCCESS,
  ADD_BASE_PRICE_FAILED,
  ADD_BASE_PRICE_VALUE,
  ADD_BASE_PRICE_VALUE_SUCCESS,
  ADD_BASE_PRICE_VALUE_FAILED,
} from "../store/types";
import { onValue, set, update } from "firebase/database";
import { firebase } from "../config/configureFirebase";

export const fetchBasePrice = () => (dispatch) => {
  const { basePriceRef } = firebase;
  dispatch({ type: FETCH_BASE_PRICE });
  try {
    onValue(basePriceRef, (snapshot) => {
      const data = snapshot.val() || {};
      dispatch({ type: FETCH_BASE_PRICE_SUCCESS, payload: data });
    });
  } catch (error) {
    dispatch({ type: FETCH_BASE_PRICE_FAILED, payload: error.message });
  }
};

export const addBasePriceData = (newBasePriceData) => (dispatch, getState) => {
  const { basePriceRef } = firebase;
  dispatch({ type: ADD_BASE_PRICE });
  const currentBasePriceData = getState().baseprice?.basepricedata || {};
  const newIndex = Object.keys(currentBasePriceData).length;
  const updatedData = { ...currentBasePriceData, [newIndex]: newBasePriceData };
  set(basePriceRef, updatedData)
    .then(() => {
      dispatch({
        type: ADD_BASE_PRICE_SUCCESS,
        payload: updatedData,
      });
    })
    .catch((error) => {
      dispatch({ type: ADD_BASE_PRICE_FAILED, payload: error.message });
    });
};

export const updateBasePriceData =
  (id, updatedBasePriceData) => (dispatch, getState) => {
    const { basePriceRef } = firebase;
    dispatch({ type: EDIT_BASE_PRICE });
    const currentBasePriceData = getState().baseprice?.basepricedata || {};
    if (currentBasePriceData[id]) {
      const updatedData = {
        ...currentBasePriceData,
        [id]: updatedBasePriceData,
      };

      update(basePriceRef, updatedData)
        .then(() => {
          dispatch({
            type: EDIT_BASE_PRICE_SUCCESS,
            payload: updatedData,
          });
        })
        .catch((error) => {
          dispatch({ type: EDIT_BASE_PRICE_FAILED, payload: error.message });
        });
    } else {
      dispatch({
        type: EDIT_BASE_PRICE_FAILED,
        payload: "Base price ID not found.",
      });
    }
  };

export const addBasePriceValue = (basePrice) => (dispatch, getState) => {
  const { basePriceRef } = firebase; // Reference to the Firebase database

  try {
    dispatch({
      type: ADD_BASE_PRICE_VALUE,
    });

    // Update Firebase with basepricevalue
    const updatedData = {
      ...getState().baseprice.basepricedata,
      basepricevalue: basePrice,
    };
    set(basePriceRef, updatedData)
      .then(() => {
        dispatch({
          type: ADD_BASE_PRICE_VALUE_SUCCESS,
          payload: { basepricevalue: basePrice },
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_BASE_PRICE_VALUE_FAILED,
          payload:
            error.message || "Failed to add base price value to Firebase",
        });
      });
  } catch (error) {
    dispatch({
      type: ADD_BASE_PRICE_VALUE_FAILED,
      payload: error.message || "Failed to add base price value",
    });
  }
};
