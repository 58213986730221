import React from "react";
import moment from "moment/min/moment-with-locales";
import { colors } from "../components/Theme/WebTheme";
import {
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
export const calcEst = false;
export const showEst = true;
export const optionsRequired = false;

export const MAIN_COLOR = colors.TAXIPRIMARY;
export const SECONDORY_COLOR = colors.TAXISECONDORY;

export const bookingHistoryColumns = (role, settings, t, isRTL) => [
  { title: t("booking_ref"), field: "reference" },

  {
    title: t('booking_type'),
    field: "bookLater",

 
    render: rowData => {
      if (rowData.bookLater) {
        return t('book_later');
      } else{
        return t('book_now');
      }
  } },
  {
    title: t('booking_by'),
    field: "booking_type_admin",

 
    render: rowData => {
      if (rowData.booking_type_admin) {
        return 'Admin';
      } else if (rowData.fleetadmin) {
        return 'Dispatcher';
      } else {
        return 'Customer';
      }
    }
  }, 

  {
    title: t("booking_date"),
    field: "bookingDate",
    render: (rowData) =>
      rowData.bookingDate ? moment(rowData.bookingDate).format("lll") : null,
  },
  { title: t("car_type"), field: "carType" },
  { title: t("assign_driver"), field: "driver_name" },
  {
    title: t("booking_status_web"),
    field: "status",
    render: (rowData) => (
      <div
        style={{
          backgroundColor:
            rowData.status === "CANCELLED"
              ? colors.RED
              : rowData.status === "COMPLETE"
              ? colors.GREEN
              : colors.YELLOW,
          color: "white",
          padding: 7,
          borderRadius: "15px",
          fontWeight: "bold",
          width: "150px",
          margin: "auto",
        }}
      >
        {t(rowData.status)}
      </div>
    ),
  },
  { title: t("otp"), field: "otp" },
  {
    title: t("trip_cost"),
    field: "trip_cost",
    render: (rowData) =>
      rowData.trip_cost
        ? settings.swipe_symbol
          ? rowData.trip_cost + " " + settings.symbol
          : settings.symbol + " " + rowData.trip_cost
        : settings.swipe_symbol
        ? "0 " + settings.symbol
        : settings.symbol + " 0",
  },
];

export const BookingModalBody = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { classes, handleChange, auth, profileData } = props;
  return (
    <span>
      {auth.profile.usertype === "customer" && !auth.profile.firstName ? (
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required={auth.profile.firstName ? false : true}
            fullWidth
            id="firstName"
            label={t("firstname")}
            name="firstName"
            autoComplete="firstName"
            onChange={handleChange}
            value={profileData.firstName}
            autoFocus
            className={isRTL === "rtl" ? classes.inputRtl : classes.textField}
            style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          />
        </Grid>
      ) : null}
      {auth.profile.usertype === "customer" && !auth.profile.lastName ? (
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required={auth.profile.lastName ? false : true}
            fullWidth
            id="lastName"
            label={t("lastname")}
            name="lastName"
            autoComplete="lastName"
            onChange={handleChange}
            value={profileData.lastName}
            className={isRTL === "rtl" ? classes.inputRtl : classes.textField}
            style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          />
        </Grid>
      ) : null}
      {auth.profile.usertype === "customer" && !auth.profile.email ? (
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required={auth.profile.email ? false : true}
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            onChange={handleChange}
            value={profileData.email}
            className={isRTL === "rtl" ? classes.inputRtl : classes.textField}
            style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
          />
        </Grid>
      ) : null}
      <Typography
        component="h2"
        variant="h5"
        style={{ marginTop: 15, color: "#000" }}
      >
        {t("estimate_fare_text")}
      </Typography>
    </span>
  );
};

export const validateBookingObj = (t, bookingObject, instructionData) => {
  delete bookingObject.driverEstimates;
  return { bookingObject };
};

export const PanicSettings = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { classes, data, handleTextChange } = props;
  return (
    <span>
      <Typography
        component="h1"
        variant="h5"
        style={{
          marginTop: "15px",
          textAlign: isRTL === "rtl" ? "right" : "left",
        }}
      >
        {t("panic_num")}
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="panic"
        label={t("panic_num")}
        className={
          isRTL === "rtl"
            ? [classes.rootRtl_1, classes.right]
            : classes.textField
        }
        name="panic"
        autoComplete="panic"
        onChange={handleTextChange}
        value={data.panic}
      />
    </span>
  );
};

export const DispatchSettings = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { autoDispatch, onChange } = props;
  return (
    <FormControlLabel
      style={{ flexDirection: isRTL === "rtl" ? "row-reverse" : "row" }}
      control={
        <Switch
          checked={autoDispatch}
          onChange={onChange}
          name="autoDispatch"
          color="primary"
        />
      }
      label={t("auto_dispatch")}
    />
  );
};

export const BookingImageSettings = (props) => {
  return null;
};

export const carTypeColumns = (t, isRTL, onClick) => [
  { title: t("name"), field: "name" },
  {
    title: t("image"),
    field: "image",
    initialEditValue:
      "https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png",
    render: (rowData) =>
      rowData.image ? (
        <button
          onClick={() => {
            onClick(rowData);
          }}
        >
          <img alt="CarImage" src={rowData.image} style={{ width: 50 }} />
        </button>
      ) : null,
  },
  {
    title: t("base_fare"),
    field: "base_fare",
    type: "numeric",
    initialEditValue: 0,
  },
  {
    title: t("rate_per_unit_distance"),
    field: "rate_per_unit_distance",
    type: "numeric",
    initialEditValue: 0,
  },
  {
    title: t("rate_per_hour"),
    field: "rate_per_hour",
    type: "numeric",
    initialEditValue: 0,
  },
  {
    title: t("min_fare"),
    field: "min_fare",
    type: "numeric",
    initialEditValue: 0,
  },
  {
    title: t("convenience_fee"),
    field: "convenience_fees",
    type: "numeric",
    initialEditValue: 0,
  },
  {
    title: t("convenience_fee_type"),
    field: "convenience_fee_type",
    lookup: { flat: t("flat"), percentage: t("percentage") },
  },
  {
    title: t("fleet_admin_comission"),
    field: "fleet_admin_fee",
    type: "numeric",
    initialEditValue: 0,
  },
  { title: t("extra_info"), field: "extra_info" },
  { title: t("position"), field: "pos", type: "numeric", defaultSort: "asc" },
];

export const acceptBid = (selectedBooking, selectedBidder) => {
  return null;
};

export const BidModal = (props) => {
  return null;
};

export const generateInvoiceNumber = (prefix = "INV", sequenceNumber) => {
  const date = new Date();

  // Get the last 3 digits of the current milliseconds
  const millisecondsPart = date.getMilliseconds().toString().slice(-3);

  // Generate a random number between 100 and 999 (3 digits)
  const randomPart = Math.floor(100 + Math.random() * 900).toString();

  // Combine to form a 6-digit invoice number
  const invoiceNumber = millisecondsPart + randomPart;

  return invoiceNumber;
};

export const downloadCsv = (data, fileName) => {
  const finalFileName = fileName.endsWith(".csv")
    ? fileName
    : `${fileName}.csv`;
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  a.setAttribute("download", finalFileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateInvoicePDF = (invoiceData, logoPath) => {
  console.log(invoiceData);

  const doc = new jsPDF();
  // Add logo
  const img = new Image();
  img.onload = () => {
    doc.addImage(img, "PNG", 10, 10, 30, 30); // Position and size of the logo

    // Add Seller Information
    doc.setFontSize(10);
    const sellerInfo = [
      "Griphen Taxi AB",
      "Tjuvbackevägen 5",
      "Kalmar",
      "392 39",
      "Sweden",
      "VAT number: XXXXXXXXXX",
    ];
    sellerInfo.forEach((line, index) => {
      doc.text(line, 190, 15 + index * 5, { align: "right" });
    });

    //  // Add Invoice title and details
    //  doc.setFontSize(20);
    //  doc.text('Invoice', 10, 50);
    //  doc.setFontSize(12);
    //  doc.text(`Invoice Number: ${invoiceData.invoiceNumber}`, 10, 60);
    // //  doc.text(`Invoice Date: ${invoiceData.details.invoiceDate}`, 10, 65);
    //  doc.text(`Billing ID: ${invoiceData.details.bookingId}`, 10, 70);
    //  doc.text(`Account ID: ${invoiceData.details.accountId}`, 10, 75);

    // Add Buyer Information
    //  doc.setFontSize(12);
    //  doc.text('Bill To:', 10, 85);
    //  const buyerInfo = [
    //    invoiceData.billTo.name,

    //    invoiceData.billTo.address,

    //  ];
    //  buyerInfo.forEach((line, index) => {
    //    doc.text(line, 10, 90 + (index * 5));
    //  });

    // Add Summary
    doc.setFontSize(14);
    doc.text("Summary for Jul 1, 2024 - Jul 31, 2024", 10, 115);
    doc.setFontSize(12);
    doc.text("Ride", 10, 125);
    doc.text(`Total in SEK`, 190, 125, { align: "right" });
    doc.text(`SEK ${invoiceData.total}`, 190, 130, { align: "right" });

    // Add Footer
    doc.setFontSize(10);
    const footerInfo = [
      "Subtotal in SEK:",
      `SEK ${invoiceData.total}`,
      "VAT (0%):",
      "SEK 0.00",
      "Total in SEK:",
      `SEK ${invoiceData.total}`,
    ];
    footerInfo.forEach((line, index) => {
      doc.text(line, 190, 150 + index * 5, { align: "right" });
    });

    doc.setFontSize(14);
    doc.text("How to make payment:", 10, 190);

    doc.setFontSize(10);
    const paymentInstructions = [
      "To complete the transaction, payment must be made using bank-to-bank electronic wire transfer. Wire transfer or credit",
      "transfer is a method of transferring money from one person or institution (entity) to another. A wire transfer can be made",
      "from one bank account to another bank account or through a transfer of cash at a cash office.",
      "",
      "Send the payment using the following details:",
      `ACCOUNT NAME: ${invoiceData.paymentDetails.accountName}`,
      `IBAN: ${invoiceData.paymentDetails.iban}`,
      `BIC/SWIFT: ${invoiceData.paymentDetails.bicSwift}`,
      `BANK NAME: ${invoiceData.paymentDetails.bankName}`,
      `BANK ADDRESS: ${invoiceData.paymentDetails.bankAddress}`,
      "Please use the details exactly as written above. Do not modify account name or account details otherwise your payment will be rejected by our system. Account names are generated to be unique for each transaction so we can identify your payments faster.",
    ];

    const wrapText = (text, x, y, maxWidth, lineHeight = 5) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line, index) => {
        doc.text(line, x, y + index * lineHeight);
      });
      return y + lines.length * lineHeight;
    };

    let currentY = 195; // Starting Y position for payment instructions
    paymentInstructions.forEach((paragraph) => {
      currentY = wrapText(paragraph, 10, currentY, 190, 5);
    });

    // Add Invoice note
    doc.setFontSize(14);
    doc.text("Note", 10, currentY);
    currentY = currentY + 10;
    // Add Payment Instructions Section
    doc.setFontSize(10);
    doc.text(invoiceData.details.invoiceNote, 10, currentY);

    // Save the PDF
    doc.save("invoice.pdf");
  };

  img.onerror = () => {
    console.error("Failed to load logo image.");
  };
  img.src = logoPath;
};
